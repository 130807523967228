import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
  Divider,
  Button,
  CircularProgress,
  makeStyles,
  Grid,
  Typography
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import { appColor, windowWidth } from '../../common';
import { Context as StudentsContext } from '../../context/StudentsContext';

const data = [{
  subject: "التربية الإسلامية",
  lesson: "الدرس الثالث: الاجتهاد في الشريعة الإسلامية (1) - 25 سؤال ",
  date: "23/5/2024",
  score: "98%"
},
{
  subject: "التربية الإسلامية",
  lesson: "الدرس الثالث: الاجتهاد في الشريعة الإسلامية (1) - 25 سؤال ",
  date: "23/5/2024",
  score: "98%"
}, {
  subject: "التربية الإسلامية",
  lesson: "الدرس الثالث: الاجتهاد في الشريعة الإسلامية (1) - 25 سؤال ",
  date: "23/5/2024",
  score: "98%"
}]

const AdminStudentsResults = ({
  title,
  content,
  onConfirm,
  visible,
  onCancel,
  showConfirmButton,
  loading,
  arabicConfirmText,
  arabicCloseText,
  onCloseBackDropClick,
  iconTitle,
  onKeyPress,
  studentName
}) => {

  const { state: { adminStudentsScores } } = useContext(StudentsContext);

  const classes = useStyles();
  return (
    <Dialog
      open={visible}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      // PaperProps={{ style: { width: isMobileOnly ? '100%' : 800 } }}
      //fullScreen
      fullWidth
      onClose={onCloseBackDropClick}
      maxWidth="lg"
      disableScrollLock
    >
      <DialogTitle style={{ textAlign: 'right', direction: 'rtl' }}>
        نتائج الطالب {studentName}
      </DialogTitle>
      <Container>
        <hr style={{ margin: 0, padding: 0 }} />
      </Container>
      <DialogContent>
        <DialogContentText
          className={classes.content}
          id="alert-dialog-slide-description"
          style={{ textAlign: 'right', color: '#000000', direction: 'rtl' }}
        >
          {loading && <CircularProgress className={classes.loadingStyle} />}
          <Grid container>
            <Grid item md={3}>
              <Typography className={classes.content}>المادة</Typography>
            </Grid>
            <Grid style={{ paddingInline: '1%' }} item md={3}>
              <Typography className={classes.content}>اسم الدرس</Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }} item md={3}>
              <Typography className={classes.content}>التاريخ</Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }} item md={3}>
              <Typography className={classes.content}>النتيجة</Typography>
            </Grid>
          </Grid>
          {adminStudentsScores.map((item) => (
            <>
              <Grid container>
                <Grid item md={3}>
                  <Typography className={classes.content}>{item._lesson._unit._term._subject.subjectName}</Typography>
                </Grid>
                <Grid style={{ paddingInline: '1%' }} item md={3}>
                  <Typography className={classes.content}>{item._lesson.lessonName}</Typography>
                </Grid>
                <Grid style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }} item md={3}>
                  <Typography className={classes.content}>{item.date}</Typography>
                </Grid>
                <Grid style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }} item md={3}>
                  <Typography className={classes.content}>%{item.score}</Typography>
                </Grid>
              </Grid>
              <hr />
            </>))}
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <>
          {showConfirmButton && (
            <Button style={{ fontWeight: 'bold', color: appColor }} onClick={onConfirm} onKeyPress={onKeyPress}>
              {arabicConfirmText}
            </Button>
          )}
          <Button style={{ color: 'red', fontWeight: 'bold' }} onClick={onCancel}>
            {arabicCloseText}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  content: {
    marginBottom: '5%'
  },
  loadingStyle: {
    position: 'absolute',
    // top: '50%',
    // left: '50%',
    color: appColor,
  }
});

export default AdminStudentsResults;