import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Container,
  Fade,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  InputAdornment,
  Button
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { TextField, inputLabelClasses } from '@mui/material'
import { AccessibilityNew, Search, SpeakerNotes } from '@material-ui/icons';
import { Context as StudentsContext } from '../context/StudentsContext';
import { Context as AdminContext } from '../context/AdminContext';
import { isMobile } from 'react-device-detect';
import AdminStudentsResults from './components/AdminStudentsResults';
import { appColor, windowWidth } from '../common';

const StudentsTable = () => {
  const [students, setStudents] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const { fetchStudents } = useContext(StudentsContext);
  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);
  const { fetchAllScores, resetAdminStudentsScores } = useContext(StudentsContext);

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const { error, students } = await fetchStudents();
      if (!error) {
        setStudents(students)
      } else {
        console.log('Somthing went wrong while fetching students')
      }
    }
    tryAdminLocalSignin(navigate, '/students')
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  if (!adminToken) {
    return null;
  }

  return (
    <Container style={{ direction: 'rtl' }}>
      <Fade timeout={1000} in={true}>
        <div>
          <Box className={classes.titleContainer}>
            <Box style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
              <AccessibilityNew style={{ color: appColor }} />
              <Typography className={classes.title}>بيانات الطلاب</Typography>
            </Box>
            <TextField
              value={searchValue}
              onChange={(e) => { setSearchValue(e.target.value); setPage(0); }}
              style={{ width: isMobile ? '55%' : '40%', backgroundColor: '#FFFFFF' }}
              variant='outlined'
              placeholder="اسم الطالب ، رقم الهاتف..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: 'gray' }} />
                  </InputAdornment>
                ),
                style: { fontFamily: 'tajawal' },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'tajawal',
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: appColor
                  }
                },
              }}
            />
          </Box>
          <hr />
          <TableContainer style={{ marginBottom: '5%' }} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="right" className={classes.tableHeaderText}>الإسم</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>رقم الهاتف</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>المواد المسجلة</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>الجيل</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>المصدر</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students
                  .filter(student => {
                    return (
                      `${student.firstName.toLowerCase()} ${student.lastName.toLowerCase()}`.includes(searchValue.toLowerCase()) ||
                      student.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
                      student.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
                      student.phoneNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
                      (student.deletedPhoneNumber && student.deletedPhoneNumber.toLowerCase().includes(searchValue.toLowerCase())) ||
                      (student.foundBy && student.foundBy.toLowerCase().includes(searchValue.toLowerCase())) ||
                      (student.generation && student.generation.toLowerCase().includes(searchValue.toLowerCase()))
                    )
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((student) => {
                    let studentTerms = [];
                    for (let code of student._codes) {
                      studentTerms = [...studentTerms, ...code._terms];
                    }
                    return (
                      <TableRow key={student._id}>
                        <TableCell align="right" className={classes.content} component="th" scope="row">
                          {student.firstName} {student.lastName}
                        </TableCell>
                        <TableCell style={{ color: student.deletedPhoneNumber ? 'red' : '#000000' }} align="right" className={classes.content}>
                          {student.deletedPhoneNumber ? student.deletedPhoneNumber : student.phoneNumber}
                        </TableCell>
                        <TableCell align="right" className={classes.content}>{studentTerms.map((term) => ` | ${term._subject.subjectName} - ${term.name}`)}</TableCell>
                        <TableCell align="right" className={classes.content}>
                          {student.generation}
                        </TableCell>
                        <TableCell align="right" className={classes.content}>
                          {student.foundBy}
                        </TableCell>
                        <TableCell align="right" className={classes.content}>
                          <Button
                            variant="contained"
                            className={classes.resultsButton}
                            onClick={() => {
                              setModalVisible(true);
                              // setLoading(true);
                              fetchAllScores(student._id);
                              // setLoading(false);
                            }}
                          >
                            نتائج الطالب
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={students
                .filter(student => {
                  return (
                    `${student.firstName.toLowerCase()} ${student.lastName.toLowerCase()}`.includes(searchValue.toLowerCase()) ||
                    student.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    student.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    student.phoneNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
                    (student.deletedPhoneNumber && student.deletedPhoneNumber.toLowerCase().includes(searchValue.toLowerCase())) ||
                    (student.foundBy && student.foundBy.toLowerCase().includes(searchValue.toLowerCase())) ||
                    (student.generation && student.generation.toLowerCase().includes(searchValue.toLowerCase()))
                  )
                }).length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            //labelDisplayedRows={() => `${page + 1} - ${rowsPerPage} من ${students.length}`}
            />
          </TableContainer>
          <AdminStudentsResults
            visible={modalVisible}
            arabicCloseText="إغلاق"
            onCancel={() => {
              setModalVisible(false);
              resetAdminStudentsScores();
            }}
            onCloseBackDropClick={() => {
              setModalVisible(false);
              resetAdminStudentsScores();
            }}
          //loading={loading}
          />
        </div>
      </Fade>
    </Container>
  );
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2%',
    alignItems: 'center',
    marginBottom: '1%'
  },
  title: {
    fontSize: '1.6em',
    marginLeft: '2%'
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: appColor,
  },
  tableHeaderText: {
    color: '#FFFFFF',
    fontSize: '1em',
    fontWeight: 'bold',
    borderBottom: 'none'
  },
  content: {
    fontSize: '1em',
    borderBottom: '1px solid gray'
  },
  notchedOutline: {
    border: '1px solid',
    borderColor: '#E77813 !important',
    borderRadius: 5,
  },
  resultsButton: {
    backgroundColor: appColor,
    fontSize: '0.7em',
    width: windowWidth / 10,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
      color: '#FFFFFF',
    }
  },
});

export default StudentsTable;