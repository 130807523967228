import React from 'react';

const height = window.innerHeight;
const width = window.innerWidth;

const PrivacyPolicyScreen = () => (
  <section id="privacy">
    <div className="container">
      <nav
        className="navbar navbar-expand-lg navbar-light" //rgba(0,0,0,0.8)
      >
        <div className="container">
          <a className="navbar-brand" href="/privacy">
            <img
              src={require('../images/logo.png')}
              alt="logo"
              className="logo"
              style={{
                objectFit: 'contain',
                width: width / 10
              }}
            />
          </a>
        </div>
      </nav>
      <hr></hr>
      <h2>Privacy Policy</h2>
      <p>
        <strong>Seen Gem</strong> is an app that will help you to solve questions for 12th grade
      </p>
      <p>
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of personal information if anyone
        decided to use our service.
      </p>
      <p>
        If you choose to use our service, then you agree to the collection and
        use of information in relation with this policy. The Personal
        Information that we collect are used for providing and improving the
        service. We will not use or share your information with anyone except as
        described in this privacy policy.
      </p>
      <p>
        <h4>Information Collection and Use</h4>
      </p>
      <p>
        Our service requires you to provide us with the following personally identifiable information:
        users name, phone number and the email. We use this data to sign the user in, and provide the users
        the ability to change their passwords and contact us through the email connected with their account.
      </p>

      <p>
        <h4>Delete Account</h4>
        <p>User can delete his account by the following steps:</p>
        <ol>
          <li>Go to menu drawer in the mobile application</li>
          <li>Go to My Account</li>
          <li>Press on Delete account button</li>
          <li>Type 'delete account' in the text field</li>
          <li>Press on Delete account button</li>
        </ol>
        <h4>Children’s Privacy</h4>
      </p>
      <p>
        This services do not address anyone under the age of 13. We do not
        knowingly collect personal identifiable information from children under
        13. If you are a parent or guardian and you are aware that your child
        has provided us with personal information, please contact us so that we
        will be able to do necessary actions.
      </p>
      <p>
        <h4>Changes to This Privacy Policy</h4>
      </p>
      <p>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately, after they are posted on this page.
      </p>
      <p>
        <h4>Contact Us</h4>
      </p>
      <p style={{ marginBottom: '3%' }}>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us via email: info@seengem.com
      </p>
    </div>
  </section>
);

export default PrivacyPolicyScreen;
