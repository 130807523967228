import axios from 'axios';
import createDataContext from './createDataContext';

const teacherReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return { ...state, errorMessage: action.payload };
      case 'teacher_signin':
      return { ...state, errorMessage: '', teacherToken: action.payload }
    case 'teacher_signup':
      return { ...state, errorMessage: '', teacherToken: action.payload }
    case 'update_teacher_info':
      return {
        ...state,
        ...action.payload
      }
    case 'teacher_signout':
      return { 
        ...state,
        errorMessage: '',
        teacherToken: null,
        _id: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        _terms: [],
        subjects: []
      };
    default:
      return state;
  };
};

async function saveItem(key, value) {
  await window.localStorage.setItem(key, JSON.stringify(value));
}

async function getItem(key) {
  let result = await window.localStorage.getItem(key);
  return JSON.parse(result);
}

async function deleteItem(key) {
  await window.localStorage.removeItem(key);
}

const tryTeacherLocalSignin = dispatch => async (navigate, successRoute, failRoute = '/', navigateOnSuccess=true) => {
  const teacherToken = await getItem('teacher_token');
  const teacherUser = await getItem('teacher_user');
  if (teacherToken && teacherUser) {
    dispatch({ type: 'teacher_signin', payload: teacherToken });
    dispatch({ type: 'update_teacher_info', payload: teacherUser });
    if (navigateOnSuccess)
      navigate(successRoute);
  } else {
    navigate(failRoute);
  }
};

const teacherSignin = dispatch => async (phoneNumber, password) => {
  try {
    const response = await axios.post('/api/teacher/signin', { phoneNumber, password });
    const subjects = await axios.get('/api/teacher/subjects', { params: { teacherId: response.data.user._id } });
    await saveItem('teacher_token', response.data.token);
    await saveItem('teacher_user', { ...response.data.user, subjects: subjects.data });
    dispatch({ type: 'teacher_signin', payload: response.data.token });
    dispatch({ type: 'update_teacher_info', payload: response.data.user });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong with sign in' });
    return { error: 'Something went wrong with sign in' };
  }
};

const teacherSignout = dispatch => async navigate => {
  await deleteItem('teacher_token');
  await deleteItem('teacher_user');
  dispatch({ type: 'teacher_signout' });
  navigate('/teacherarea');
};

const fetchTeachers = dispatch => async () => {
  try {
    const res = await axios.get('/api/data/teachers');
    return { error: '', teachers: res.data.teachers };
  } catch (err) {
    console.log(err);
    return { error: 'Error in fetching teachers' };
  }
};

export const { Context, Provider } = createDataContext(
  teacherReducer,
  {
    teacherSignin,
    tryTeacherLocalSignin,
    teacherSignout,
    fetchTeachers
  },
  {
    teacherToken: window.localStorage.getItem('teacher_token')
      ? window.localStorage.getItem('teacher_token')
      : null,
    errorMessage: '',
    _id: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    _terms: [],
    subjects: []
  }
);