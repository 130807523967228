import React, { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
  Divider,
  Button,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import { appColor } from '../common';

const CustomModal = ({
  title,
  content,
  onConfirm,
  visible,
  onCancel,
  showConfirmButton,
  loading,
  arabicConfirmText,
  arabicCloseText,
  onCloseBackDropClick,
  iconTitle,
  onKeyPress
}) => {

  const classes = useStyles();
  return (
    <Dialog
      open={visible}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ style: { width: isMobileOnly ? '100%' : '70%' } }}
      onClose={onCloseBackDropClick}
      disableScrollLock
    >
      <DialogTitle style={{ textAlign: 'right', direction: 'rtl' }}>
        {iconTitle} {title}
      </DialogTitle>
      <Container>
        <hr style={{ margin: 0, padding: 0 }} />
      </Container>
      <DialogContent>
        <DialogContentText
          className={classes.content}
          id="alert-dialog-slide-description"
          style={{ textAlign: 'right', color: '#000000', direction: 'rtl' }}
        >
          {content}
        </DialogContentText>
      </DialogContent>

      {loading && (
        <CircularProgress
          style={{
            position: 'absolute',
            alignSelf: 'center',
            marginTop: '16%'
          }}
        />
      )}

      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <>
          {showConfirmButton && (
            <Button style={{ fontWeight: 'bold', color: appColor }} onClick={onConfirm} onKeyPress={onKeyPress}>
              {arabicConfirmText}
            </Button>
          )}
          <Button style={{ color: 'red', fontWeight: 'bold' }} onClick={onCancel}>
            {arabicCloseText}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  content: {
    whiteSpace: 'pre-line',
    color: '#000000'
  },
});

export default CustomModal;