import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Slide,
  Typography,
  makeStyles
} from '@material-ui/core';
import { appColor, windowHeight, windowWidth } from '../common';
import { Context as LessonContext } from '../context/LessonContext';
import { Context as StudentsContext } from '../context/StudentsContext';
import Lottie from 'react-lottie';
import animationData from '../images/waiting.json';
import CustomModal from '../components/CustomModal';
import { isMobile } from 'react-device-detect';

const LessonsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [lessonsFetched, setLessonsFetched] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { state: { lessons }, fetchLessons, resetLessons } = useContext(LessonContext);
  const { state: { token, _terms: registeredTerms }, tryLocalSignin } = useContext(StudentsContext);

  const location = useLocation();

  const classes = useStyles();
  const navigate = useNavigate();

  let subjectId, termId, unitId, subjectName, termName, unitName

  if (!location.state)
    navigate('/');
  else {
    subjectId = location.state.subjectId;
    termId = location.state.termId;
    unitId = location.state.unitId;
    subjectName = location.state.subjectName;
    termName = location.state.termName;
    unitName = location.state.unitName;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    async function fetchData() {
      await fetchLessons(unitId, true);
      setLoading(false);
      setLessonsFetched(true);
    }

    fetchData();
    tryLocalSignin(navigate, '/lessons', { termId, unitId, unitName, termName, subjectName });
    return () => {
      resetLessons();
    }
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };

  return (
    <Box>
      <Container style={{ marginTop: '3%', direction: subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'ltr' : 'rtl' }}>
        {loading && <CircularProgress className={classes.loadingStyle} />}
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography className={classes.title}>{subjectName} - {termName} - {unitName}</Typography>
          {!registeredTerms.includes(termId) &&
            <Button
              variant="contained"
              className={classes.codeButton}
              onClick={() => navigate('/code-request')}
            >
              اطلب رمز المادة
            </Button>
          }
        </Box>
        <hr />
        {lessons.map((lesson, index) => {
          const isLast = lessons.length - 1 === index;
          return (
            <Slide
              direction={subjectId === '660d1aa65b1a4fa77f6dd7ab' ? "right" : "left"}
              in
              timeout={300}
            >
              <Box style={{ marginBottom: '1%' }}>
                <Button
                  onClick={() => {
                    if (index === 0 || registeredTerms.includes(termId)) {
                      navigate('/questions', {
                        state: { subjectId, lessonId: lesson._id, lessonTitle: lesson.lessonName }
                      })
                    } else {
                      setModalVisible(true);
                    }
                  }}
                  style={{ textTransform: 'none' }}
                >
                  <Typography
                    className={classes.lessonTitle}
                    style={{
                      textAlign: subjectId === '660d1aa65b1a4fa77f6dd7ab' ? "left" : "right",
                      color: index === 0 || registeredTerms.includes(termId) ? appColor : 'gray'
                    }}
                  >
                    {lesson.lessonName}
                  </Typography>
                </Button>
                {!isLast && <hr />}
              </Box>
            </Slide>
          )
        })}
        {!lessons.length && lessonsFetched ?
          <>
            <Lottie
              options={defaultOptions}
              height={300}
              width={300}
              isClickToPauseDisabled
            />
            <Typography className={classes.waitingText}>{unitName === 'اختبارات نهائية' ? 'الإختبارات' : 'الدروس'} قيد الإنشاء ، ستكون بين أيديكم في أقرب وقت...</Typography>
          </>
          : null}
        <CustomModal
          title='تنبيه!'
          content="لتتمكن من الحصول على باقي الأسئلة يرجى تفعيل المادة أولاً..."
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          showConfirmButton
          //loading={loading}
          onConfirm={() => navigate('/code-request')}
          arabicConfirmText="اطلب رمز المادة"
          arabicCloseText="إغلاق"
        />
      </Container>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  loadingStyle: {
    alignSelf: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: appColor,
    zIndex: 333
  },
  title: {
    fontSize: '1.5em',
    textAlign: 'right'
  },
  lessonTitle: {
    fontSize: '1.3em',
    fontWeight: 'bold'
  },
  waitingText: {
    fontSize: '1.5em',
    textAlign: 'center',
    marginBottom: '2%'
  },
  codeButton: {
    fontSize: isMobile ? '0.7em' : '1em',
    width: 160,
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor
    }
  },
}));

export default LessonsScreen;