import axios from 'axios';

export const uploadImage = async (image, path) => {
  /*Image Config*/
  let imageConfig
  try {
    imageConfig = await axios.get('/api/upload/image', {
      params: { path }
    });
  } catch (err) {
    return { error: 'Error in fetching image config', imageUrl: null };
  }

  /*Upload Image to AWS*/
  try {
    await axios.put(imageConfig.data.url, image, {
      headers: {
        'Content-Type': image.type
      }
    });
    return { error: '', imageUrl: imageConfig.data.key }
  } catch (err) {
    return { error: 'Error in Uploading the image to AWS', imageUrl: null };
  }
};

export const uploadJson = async (image, path) => {
  /*Image Config*/
  let imageConfig
  try {
    imageConfig = await axios.get('/api/upload/json', {
      params: { path }
    });
  } catch (err) {
    return { error: 'Error in fetching image config', imageUrl: null };
  }

  /*Upload Image to AWS*/
  try {
    await axios.put(imageConfig.data.url, image, {
      headers: {
        'Content-Type': 'application/json	'
      }
    });
    return { error: '', imageUrl: imageConfig.data.key }
  } catch (err) {
    return { error: 'Error in Uploading the image to AWS', imageUrl: null };
  }
};

export const uploadPdf = async (image, path) => {
  /*Image Config*/
  let imageConfig
  try {
    imageConfig = await axios.get('/api/upload/pdf', {
      params: { path }
    });
  } catch (err) {
    return { errorFile: 'Error in fetching pdf config', fileUrl: null };
  }

  /*Upload Image to AWS*/
  try {
    await axios.put(imageConfig.data.url, image, {
      headers: {
        'Content-Type': 'application/pdf	'
      }
    });
    return { errorFile: '', fileUrl: imageConfig.data.key }
  } catch (err) {
    return { errorFile: 'Error in Uploading the pdf file to AWS', fileUrl: null };
  }
};


export const delete_images = async images_urls => {
  try {
    await axios.post('/api/delete/image', {
      images_urls
    });
    return { error: '' };
  } catch (err) {
    return { error: 'Error in deleting image from AWS' };
  }
};

export const delete_pdf = async pdf_urls => {
  try {
    await axios.post('/api/delete/pdf', {
      pdf_urls
    });
    return { error: '' };
  } catch (err) {
    return { error: 'Error in deleting the pdf from AWS' };
  }
};