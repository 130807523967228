import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import { PaginationItem } from '@mui/material'
import Pagination from '@material-ui/lab/Pagination';
import { appColor, windowHeight, windowWidth } from '../common';
import Lottie from 'react-lottie';
import animationData from '../images/congrats.json';
import sadData from '../images/sad.json';
import { Context as QuestionContext } from '../context/QuestionContext';
import { Context as StudentsContext } from '../context/StudentsContext';
import Answer from '../components/Answer';
import { isMobile } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import ImageViewer from 'react-simple-image-viewer';
import { ArrowBack, ArrowBackIos, ArrowForward, ArrowForwardIos, SpeakerNotes } from '@material-ui/icons';

const AWS_URL = 'https://qsbank-bucket.s3.eu-central-1.amazonaws.com/';

const QuestionsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalIconTitle, setModalIconTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(true);
  const [examFinished, setExamFinished] = useState(false);

  const [resultsModalVisible, setResultsModalVisible] = useState(false);

  const [questionImageViewer, setQuestionImageViewer] = useState(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 5);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };
  const sadOptions = {
    loop: true,
    autoplay: true,
    animationData: sadData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };

  const { state: { questions }, fetchQuestions, resetQuestions } = useContext(QuestionContext);
  const { state: { _id: studentId, scores }, tryLocalSignin, fetchScores, saveScore, resetScores } = useContext(StudentsContext);

  const [studentAnswers, setStudentAnswers] = useState(Array(questions.length).fill(null));
  const [disabledAnswers, setDisabledAnswers] = useState(Array(questions.length).fill(false));

  const location = useLocation();

  const classes = useStyles();
  const navigate = useNavigate();

  let subjectId, lessonId, lessonTitle

  if (!location.state)
    navigate('/');
  else {
    subjectId = location.state.subjectId;
    lessonId = location.state.lessonId;
    lessonTitle = location.state.lessonTitle;
  }

  const questionsPerPage = 1;
  const pageCount = Math.ceil(questions.length / questionsPerPage);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      setLoading(true);
      await fetchQuestions(lessonId, true);
      setLoading(false);
    }

    fetchData();
    tryLocalSignin(navigate, '/questions');
    return () => {
      resetQuestions();
    }
  }, []);

  useEffect(() => {
    setStudentAnswers(Array(questions.length).fill(null));
  }, [questions.length]);

  useEffect(() => {
    if (studentId && lessonId)
      fetchScores(studentId, lessonId);

    return () => {
      resetScores();
    }
  }, [studentId, lessonId]);

  const start = (currentPage - 1) * questionsPerPage;
  const end = start + questionsPerPage;
  let questionNumber = start + 1;

  const handleFinishButton = () => {
    const unanswered = studentAnswers.map((answer, index) => {
      if (answer === null) {
        return index + 1
      }
      return null;
    }).filter((item) => item !== null)
    if (!unanswered.length) {
      let result = 0;
      for (const [index, answer] of studentAnswers.entries()) {
        const trueAnswer = questions[index].answers.map(answer => answer.isTrue).indexOf(true);
        if (answer === trueAnswer)
          result += 1;
      };
      setModalVisible(false);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setModalVisible(true);
        if (result < (questions.length / 2)) {
          setModalIconTitle(<Lottie
            options={sadOptions}
            height={300}
            width={500}
            isClickToPauseDisabled
          />)
        } else {
          setModalIconTitle(<Lottie
            options={defaultOptions}
            height={250}
            width={250}
            isClickToPauseDisabled
          />)
        }
        setModalTitle(`النتيجة النهائية: ${result}/${questions.length}`);
        setModalContent('يمكنك الإطلاع على الإجابات والملاحظات عليهم');
        setShowConfirmButton(false);
        setExamFinished(true);

        const date = new Date();
        saveScore(studentId, lessonId, Math.ceil(result / questions.length * 100), date.toLocaleDateString());
      }, 2000)
    } else {
      setModalVisible(false);
      setModalVisible(true);
      setModalTitle('تنبيه!');
      setModalContent(`هناك اسئلة لم تجب عليها ، يجب أن تجيب على الأسئلة المتبقية: ${unanswered.join(' ، ')}`);
      setShowConfirmButton(false);
    }
  }

  return (
    <Box>
      <Container style={{ marginTop: '3%' }}>
        {loading && <CircularProgress className={classes.loadingStyle} />}
        <Box style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          direction: subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'ltr' : 'rtl'
        }}>
          <Typography className={classes.title}>
            {subjectId === '660d1aa65b1a4fa77f6dd7ab' ? lessonTitle : `أسئلة على ${lessonTitle}`}
          </Typography>
          <Button
            variant="contained"
            className={classes.resultsButton}
            startIcon={<SpeakerNotes style={{ marginLeft: 10 }} />}
            onClick={() => setResultsModalVisible(true)}
          >
            {subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'Show your results' : 'اعرض نتائجك'}
          </Button>
        </Box>
        <CustomModal
          visible={resultsModalVisible}
          title="نتائجك في هذا الدرس"
          content={!scores.length ? 'لا يوجد لديك نتائج حالية' : `${scores.join('\n')}`}
          arabicCloseText="إغلاق"
          onCloseBackDropClick={() => setResultsModalVisible(false)}
          onCancel={() => setResultsModalVisible(false)}
        />
        <hr />
        {questions.slice(start, end).map(({ questionText, image, answers }, questionIndex) => {
          const isLast = questions.length - 1 === questionIndex;
          return (
            <>
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <Typography className={classes.questionText}>{questionNumber++}. {questionText}</Typography> */}
                <Box style={{ display: 'flex', flexDirection: subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'row' : 'row-reverse' }}>
                  <Typography style={{ userSelect: 'none', textAlign: 'right' }} className={classes.questionText}>
                    {subjectId === '660d1aa65b1a4fa77f6dd7ab' ? `${questionNumber++}.` : `.${questionNumber++}`}
                  </Typography>
                  {/* {subjectId === '660d1aa65b1a4fa77f6dd7ab' ? `${questionNumber++}.` : `.${questionNumber++}`} */}

                  <div
                    style={{ userSelect: 'none', textAlign: 'right' }}
                    dangerouslySetInnerHTML={{ __html: questionText }}
                    className={classes.questionText}
                  />
                </Box>
                {image ?
                  <img
                    src={AWS_URL + image}
                    className={classes.imageQuestionStyle}
                    alt="question-img"
                  //onClick={() => setQuestionImageViewer(true)}
                  />
                  : null}
                {/* {questionImageViewer && (
                <ImageViewer
                  src={[AWS_URL + image]}
                  currentIndex={0}
                  disableScroll={true}
                  closeOnClickOutside={true}
                  onClose={() => setQuestionImageViewer(false)}
                  backgroundStyle={{ position: 'fixed', zIndex: 3333 }}
                />
              )} */}
              </Box>
              {answers.map((answer, answerIndex) => {
                const answerChosen = studentAnswers[questionIndex + (currentPage - 1) * questionsPerPage] === answerIndex
                const questionAnswered = studentAnswers.length && studentAnswers[questionIndex + (currentPage - 1) * questionsPerPage] !== null;
                let answerBackgroundColor = '#FFFFFF';
                let answerTextColor = '#000000';
                let showNoteBox = false;
                if (questionAnswered && answerChosen) {
                  answerBackgroundColor = 'red';
                  answerTextColor = '#FFFFFF';
                  showNoteBox = true;
                }

                if (questionAnswered && answer.isTrue) {
                  answerBackgroundColor = 'green';
                  answerTextColor = '#FFFFFF';
                  showNoteBox = true;
                }

                return (
                  <Answer
                    key={answerIndex}
                    onClick={() => {
                      studentAnswers[questionIndex + (currentPage - 1) * questionsPerPage] = answerIndex;
                      setStudentAnswers([...studentAnswers]);
                      disabledAnswers[questionIndex + (currentPage - 1) * questionsPerPage] = true;
                      setDisabledAnswers([...disabledAnswers]);
                    }}
                    style={{
                      backgroundColor: answerBackgroundColor,
                      color: answerTextColor,
                      marginBottom: isMobile ? '5%' : '2%',
                      justifyContent: 'flex-start',
                      width: '100%',
                      padding: isMobile ? '2%' : '1%',
                      textTransform: 'none'
                    }}
                    answerTextStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      direction: subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'ltr' : 'rtl'
                    }}
                    // answerTextDirection={subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'row' : 'row-reverse'}
                    noteDirection={{ direction: subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'ltr' : 'rtl' }}
                    showNoteBox={showNoteBox}
                    answerText={answer.text}
                    answerNumber={
                      answerIndex === 0 ? subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'A' : 'أ' :
                        answerIndex === 1 ? subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'B' : 'ب' :
                          answerIndex === 2 ? subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'C' : 'ج' :
                            answerIndex === 3 ? subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'D' : 'د' :
                              null
                    }
                    textNote={answer.textNote}
                    answerImage={answer.image}
                    noteTitleText="ملاحظات"
                    imageNote={answer.imageNote}
                    linkNote={answer.linkNote}
                    disabled={disabledAnswers[questionIndex + (currentPage - 1) * questionsPerPage]}
                  />
                )
              })}
              {!isLast && <hr />}
              <Typography className={classes.questionNumber}>السؤال رقم: {questionNumber - 1} / {questions.length}</Typography>
            </>
          )
        })}

        <Box style={{ direction: subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'rtl' : 'ltr' }} className={classes.paginationContainer}>
          <Box className={classes.buttonsContainer}>
            {currentPage < pageCount &&
              <Button
                variant="contained"
                className={classes.finishButton}
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              >
                {subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'Next' : 'التالي'}
              </Button>
            }
            {currentPage === pageCount &&
              <Button
                variant="contained"
                disabled={examFinished ? true : false}
                className={classes.finishButton}
                onClick={() => {
                  setModalVisible(true);
                  setModalTitle('رسالة تأكيد');
                  setModalContent('هل أنت متأكد من الإنتهاء من الإجابة على كافة الأسئلة؟');
                  setShowConfirmButton(true);
                }}
              >
                {subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'Finish' : 'إنهاء'}
              </Button>
            }
            {currentPage > 1 &&
              <Button
                variant="contained"
                className={classes.finishButton}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                {subjectId === '660d1aa65b1a4fa77f6dd7ab' ? 'Previous' : 'السابق'}
              </Button>
            }
          </Box>
        </Box>
        <CustomModal
          visible={modalVisible}
          title={modalTitle}
          iconTitle={modalIconTitle}
          content={modalContent}
          showConfirmButton={showConfirmButton}
          englishConfirmText="Yes"
          arabicConfirmText="نعم"
          englishCloseText="Close"
          arabicCloseText="إغلاق"
          onCloseBackDropClick={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          onConfirm={handleFinishButton}
          loading={loading}
        />
      </Container>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold'
  },
  loadingStyle: {
    alignSelf: 'center',
    position: 'fixed',
    top: '50%',
    left: '50%',
    color: appColor,
    zIndex: 333
  },
  questionText: {
    color: appColor,
    fontSize: '1.3em',
    marginBottom: '2%',
  },
  imageQuestionStyle: {
    width: 'auto',
    height: 'auto',
    maxWidth: '40%',
    maxHeight: '40%',
    objectFit: 'contain',
    alignSelf: 'center',
    marginBottom: '5%',
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  paginationStyle: {
    marginBottom: isMobile ? '5%' : '2%',
    alignSelf: 'center',
    direction: 'rtl'
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: appColor,
        color: 'white',
        '&:hover': {
          background: appColor,
        }
      },
    },
  },
  buttonsContainer: {
    width: isMobile ? '80%' : '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: isMobile ? '4%' : '2%'
  },
  finishButton: {
    width: isMobile ? '40%' : '40%',
    backgroundColor: appColor,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
      color: '#FFFFFF',
    }
  },
  resultsButton: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
      color: '#FFFFFF',
    }
  },
  questionNumber: {
    textAlign: 'center',
    marginBottom: '1%',
    fontSize: '1.2em',
    fontWeight: 'bold'
  }
}));

export default QuestionsScreen;