import axios from 'axios';
import createDataContext from './createDataContext';

const studentReducer = (state, action) => {
  switch (action.type) {
    case 'signup':
      return { ...state, errorMessage: '', token: action.payload };
    case 'signin':
      return { ...state, errorMessage: '', token: action.payload };
    case 'signout':
      return { ...state, errorMessage: '', token: null };
    case 'update_user_info':
      return {
        ...state,
        ...action.payload
      }
    case 'update_admin_students_scores':
      return { ...state, errorMessage: '', adminStudentsScores: action.payload };
    case 'reset_admin_students_scores':
      return { ...state, errorMessage: '', adminStudentsScores: [] };
    case 'update_scores':
      return { ...state, errorMessage: '', scores: action.payload };
    case 'save_score':
      return { ...state, errorMessage: '', scores: [...state.scores, action.payload] };
    case 'reset_scores':
      return { ...state, errorMessage: '', scores: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

async function saveItem(key, value) {
  await window.localStorage.setItem(key, JSON.stringify(value));
}

async function getItem(key) {
  let result = await window.localStorage.getItem(key);
  return JSON.parse(result);
}

async function deleteItem(key) {
  await window.localStorage.removeItem(key);
}

const signup = dispatch => async (
  firstName,
  lastName,
  phoneNumber,
  password,
  generation,
  foundBy
) => {
  try {
    const response = await axios.post('/api/student/signup', {
      firstName,
      lastName,
      phoneNumber,
      password,
      generation,
      foundBy
    });
    await saveItem('student_token', response.data.token);
    await saveItem('student_user', { ...response.data.user });
    dispatch({ type: 'signup', payload: response.data.token });
    dispatch({ type: 'update_user_info', payload: { ...response.data.user } });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong with sign up' });
    return { error: 'Something went wrong with sign up' };
  }
}

const signin = dispatch => async (phoneNumber, password) => {
  try {
    const response = await axios.post('/api/student/signin', { phoneNumber, password })
    await saveItem('student_token', response.data.token);
    await saveItem('student_user', { ...response.data.user });
    dispatch({ type: 'signin', payload: response.data.token });
    dispatch({ type: 'update_user_info', payload: { ...response.data.user } });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong with sign in' });
    return { error: 'Something went wrong with sign in' };
  }
}

const tryLocalSignin = dispatch => async (navigate, successRoute, params = {}, failRoute = '/', navigateOnSuccess = true, navigateOnFailed = true) => {
  const token = await getItem('student_token');
  const user = await getItem('student_user');
  if (token && user) {
    dispatch({ type: 'update_user_info', payload: user });
    dispatch({ type: 'signin', payload: token });
    //navigate(successRoute, { state: params });
  } else {
    if (navigateOnFailed)
      navigate(failRoute);
  }
};

const signout = dispatch => async navigate => {
  await deleteItem('student_token');
  await deleteItem('student_user');
  dispatch({ type: 'signout' });
  navigate('/');
};

const updateStudentRegisteredTerms = dispatch => async () => {
  try {
    const user = await getItem('student_user');
    const res = await axios.get('/api/student/terms', { params: { studentId: user._id } });
    const updatedUser = { ...user, _terms: res.data };
    await saveItem('student_user', updatedUser);
    dispatch({ type: 'update_user_info', payload: updatedUser });
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while updating user terms' });
    return { error: 'Something went wrong while updating user terms' };
  }
}

const checkStudentUserExistence = dispatch => async phoneNumber => {
  const res = await axios.get('/api/student/existence', {
    params: { phoneNumber }
  });
  return res.data.student;
};

const checkStudentAccountExistence = dispatch => async studentId => {
  const res = await axios.get('/api/student/account_existence', {
    params: { studentId }
  });
  return res.data.exists;
};

const checkUserCredentials = dispatch => async (phoneNumber, password) => {
  try {
    await axios.post('/api/student/signin', { phoneNumber, password })
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong with sign in' });
    return { error: 'Something went wrong with sign in' };
  }
}

const fetchStudents = dispatch => async () => {
  try {
    const res = await axios.get('/api/data/students');
    return { error: '', students: res.data.students };
  } catch (err) {
    console.log(err);
    return { error: 'Error in fetching students' };
  }
};

const registerInTerm = dispatch => async (studentId, termsIds, codeId) => {
  try {
    await axios.post('/api/student/term_register', {
      studentId, termsIds, codeId
    });
    return { error: '' };
  } catch (err) {
    console.log(err);
    return { error: 'Error in registering the term' };
  }
};

const changePassword = dispatch => async (phoneNumber, newPassword) => {
  try {
    await axios.post('/api/student/change_password', {
      phoneNumber, newPassword
    });
    return { error: '' };
  } catch (err) {
    console.log(err);
    return { error: 'Error in changing the password' };
  }
};

const fetchScores = dispatch => async (studentId, lessonId) => {
  try {
    const res = await axios.get('/api/student/score', {
      params: { studentId, lessonId }
    });

    const studentScores = res.data.studentScores.map(score => `${score.date}: ${score.score}%`)
    dispatch({ type: 'update_scores', payload: studentScores });
    return { error: '' };
  } catch (err) {
    console.log(err);
    return { error: 'Error in fetching scores' };
  }
};

const fetchAllScores = dispatch => async (studentId) => {
  try {
    const res = await axios.get('/api/student/all_scores', {
      params: { studentId }
    });

    dispatch({ type: 'update_admin_students_scores', payload: res.data.studentScores });
    return { error: '' };
  } catch (err) {
    console.log(err);
    return { error: 'Error in fetching scores' };
  }
};

const saveScore = dispatch => async (studentId, lessonId, score, date) => {
  try {
    await axios.post('/api/student/score', {
      studentId, lessonId, score, date
    });
    dispatch({ type: 'save_score', payload: `${date}: ${score}%` });
    return { error: '' };
  } catch (err) {
    console.log(err);
    return { error: 'Error in saving score' };
  }
};

const resetScores = dispatch => async () => {
  dispatch({ type: 'reset_scores' });
};

const resetAdminStudentsScores = dispatch => async () => {
  dispatch({ type: 'reset_admin_students_scores' });
};

export const { Context, Provider } = createDataContext(
  studentReducer,
  {
    signup,
    signin,
    signout,
    tryLocalSignin,
    updateStudentRegisteredTerms,
    checkStudentUserExistence,
    checkStudentAccountExistence,
    checkUserCredentials,
    fetchStudents,
    registerInTerm,
    changePassword,
    fetchScores,
    fetchAllScores,
    saveScore,
    resetScores,
    resetAdminStudentsScores
  },
  {
    token: window.localStorage.getItem('student_token')
      ? window.localStorage.getItem('student_token')
      : null,
    scores: [],
    adminStudentsScores: [],
    _terms: []
  }
)