import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Typography,
  Container,
  Fade,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  InputAdornment,
  Tooltip
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { TextField, inputLabelClasses } from '@mui/material'
import { Check, Search, Subtitles } from '@material-ui/icons';
import { Context as CodeContext } from '../context/CodeContext';
import { Context as AdminContext } from '../context/AdminContext';
import { isMobile } from 'react-device-detect';
import { appColor } from '../common';

const CodesDetails = () => {
  const [codes, setCodes] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { fetchCodes } = useContext(CodeContext);
  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const { error, codes } = await fetchCodes();
      if (!error) {
        setCodes(codes)
      } else {
        console.log('Somthing went wrong while fetching codes')
      }
    }
    tryAdminLocalSignin(navigate, '/code-details')
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  if (!adminToken) {
    return null;
  }

  return (
    <Container style={{ direction: 'rtl' }}>
      <Fade timeout={1000} in={true}>
        <div>
          <Box className={classes.titleContainer}>
            <Box style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
              <Subtitles style={{ color: appColor }} />
              <Typography className={classes.title}>تفاصيل الكودات</Typography>
            </Box>
            <TextField
              value={searchValue}
              onChange={(e) => { setSearchValue(e.target.value); setPage(0); }}
              style={{ width: isMobile ? '55%' : '40%', backgroundColor: '#FFFFFF' }}
              variant='outlined'
              placeholder="اسم المادة ، الكود ، اسم الطالب..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: 'gray' }} />
                  </InputAdornment>
                ),
                style: { fontFamily: 'tajawal' },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'tajawal',
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: appColor
                  }
                },
              }}
            />
          </Box>
          <hr />
          <TableContainer style={{ marginBottom: '5%' }} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="right" className={classes.tableHeaderText}>اسم المادة</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>الجيل</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>اسم المعلم</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>الفصل</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>الكود</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>النوع</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>الإستعمال</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>اسم الطالب</TableCell>
                  <TableCell align="right" className={classes.tableHeaderText}>ملاحظات</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {codes
                  .filter(code => {
                    return (
                      (code._student && `${code._student.firstName.toLowerCase()} ${code._student.lastName.toLowerCase()}`.includes(searchValue.toLowerCase())) ||
                      code.code.toLowerCase().includes(searchValue.toLowerCase())
                    )
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((code) => {
                    return (
                      <TableRow key={code._id}>
                        <TableCell align="right" className={classes.content} component="th" scope="row">
                          {code._terms[0]._subject.subjectName}
                        </TableCell>
                        <TableCell align="right" className={classes.content} component="th" scope="row">
                          {code._terms[0]._subject.grade}
                        </TableCell>
                        <TableCell align="right" className={classes.content}>
                          {code._terms[0]._teacher.firstName} {code._terms[0]._teacher.lastName}
                        </TableCell>
                        <TableCell align="right" className={classes.content}>
                          {code._terms.length > 1 ? 'الفصلان معاً' : code._terms[0].name}
                        </TableCell>
                        <TableCell align="right" className={classes.content}>{code.code}</TableCell>
                        <TableCell align="right" className={classes.content}>{code.type}</TableCell>
                        <TableCell align="right" className={classes.content}>
                          {code.used === true ? <Check style={{ color: 'green' }} /> : 'متاح'}
                        </TableCell>
                        {code._student ?
                          <Tooltip title={<h5>{code._student.phoneNumber}</h5>}>
                            <TableCell align="right" className={classes.content}>
                              {code._student.firstName} {code._student.lastName}
                            </TableCell>
                          </Tooltip>
                          :
                          <TableCell align="right" className={classes.content}>
                            -
                          </TableCell>
                        }
                        <TableCell align="right" className={classes.content}>
                          {code.note ? code.note : '-'}
                        </TableCell>

                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={codes
                .filter(code => {
                  return (
                    (code._student && `${code._student.firstName.toLowerCase()} ${code._student.lastName.toLowerCase()}`.includes(searchValue.toLowerCase())) ||
                    code.code.toLowerCase().includes(searchValue.toLowerCase())
                  )
                }).length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            //labelDisplayedRows={() => `${page + 1} - ${rowsPerPage} من ${students.length}`}
            />
          </TableContainer>
        </div>
      </Fade>
    </Container>
  );
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2%',
    alignItems: 'center',
    marginBottom: '1%'
  },
  title: {
    fontSize: '1.6em',
    marginLeft: '2%'
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    backgroundColor: appColor,
  },
  tableHeaderText: {
    color: '#FFFFFF',
    fontSize: '1em',
    fontWeight: 'bold',
    borderBottom: 'none'
  },
  content: {
    fontSize: '1em',
    borderBottom: '1px solid gray'
  },
  notchedOutline: {
    border: '1px solid',
    borderColor: '#E77813 !important',
    borderRadius: 5,
  },
});

export default CodesDetails;