import axios from 'axios';
import createDataContext from './createDataContext';

const codeReducer = (state, action) => {
  switch (action.type) {
    // case 'fetch_codes':
    //   return { ...state, codes: action.payload }
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const checkCodeAvailability = dispatch => async (code) => {
  try {
    const res = await axios.get('/api/code/available', { params: { code } })
    return { codeId: res.data.codeId, codeTerms: res.data.codeTerms, codeSubject: res.data.codeSubject };
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while checking the code' });
    return { codeId: null, codeTerms: [] };
  }
}

const checkDuplicateCode = dispatch => async (code) => {
  try {
    const res = await axios.get('/api/code/existence', { params: { code } })
    return res.data.exist
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while checking the code' });
    return false;
  }
}

const saveCodeInDb = dispatch => async (code, terms, note, type) => {
  try {
    await axios.post('/api/code', { code, terms, note, type });
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while saving the code' });
    return { error: 'Something went wrong while saving the code' };
  }
};

const fetchCodes = dispatch => async () => {
  try {
    const res = await axios.post('/api/data/code');
    return { error: '', codes: res.data.codes };
  } catch (err) {
    console.log(err);
    return { error: 'Error in fetching codes' };
  }
};

export const { Context, Provider } = createDataContext(
  codeReducer,
  {
    saveCodeInDb,
    checkCodeAvailability,
    checkDuplicateCode,
    fetchCodes
  }
)