import React from 'react';
import {
  makeStyles,
  Typography,
  Box,
  Grid,
  Container,
  IconButton
} from '@material-ui/core';
import { Call, Facebook, Instagram } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import { appColor, windowHeight } from '../common';

const Footer = () => {

  const classes = useStyles();
  return (
    <Box style={{ direction: 'rtl' }} className={classes.footerContainer}>
      <Container>
        <Grid container>
          <Grid item md={4} xs={12}>
            <Typography className={classes.contactTitle}>
              تواصل معنا
            </Typography>
            <Box style={{display: 'flex', alignItems: 'center', marginTop: '2%'}}>
              <Call style={{color: '#FFFFFF', marginLeft: '1%', fontSize: '1em'}} />
              <Typography className={classes.contactDetails}>
                موبايل : 0777912639
              </Typography>
            </Box>
            {/* <Box style={{display: 'flex', alignItems: 'center', marginTop: '2%'}}>
              <Call style={{color: '#FFFFFF', marginLeft: '1%', fontSize: '1em'}} />
              <Typography className={classes.contactDetails}>
                موبايل : 079094207
              </Typography>
            </Box> */}
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className={classes.contactTitle}>
              تابعنا على منصات التواصل
            </Typography>
            <a
              target="_blank"
              href="https://www.facebook.com/seenjem?mibextid=ZbWKwL"
              rel="noopener noreferrer"
            >
              <IconButton aria-label="copy">
                <Facebook style={{ color: '#FFFFFF', fontSize: '1.5em' }} />
              </IconButton>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/seen.gem?igsh=N3diZmZvNHloZTJm"
              rel="noopener noreferrer"
            >
              <IconButton aria-label="copy">
                <Instagram style={{ color: '#FFFFFF', fontSize: '1.5em' }} />
              </IconButton>
            </a>
          </Grid>
          <Grid style={{ marginTop: '1%' }} item md={4} xs={12}>
            <a href="/">
              <img
                src={require('../images/white_logo.png')}
                alt="logo"
                className={classes.logo}
              />
            </a>
            <Typography className={classes.poweredText}>
              Powered by{' '}
              <a
                target="_blank"
                href="https://www.mate-co.com"
                rel="noopener noreferrer"
                className={classes.mateLink}
              >
                Mate-co
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles({
  logo: {
    objectFit: 'contain',
    height: isMobile ? windowHeight / 10 : windowHeight / 7,
    cursor: 'pointer'
  },
  facebookIcon: {
    fontSize: '2.5em',
    color: '#3b5998'
  },
  instagramIcon: {
    fontSize: '2.5em',
    color: '#fb3958'
  },
  footerContainer: {
    backgroundColor: '#383838',
    height: isMobile ? '' : windowHeight / 4,
  },
  contactTitle: {
    color: 'white',
    fontSize: '1.5em',
    marginTop: '2%'
  },
  contactDetails: {
    color: 'white',
    fontSize: '1em',
  },
  poweredText: {
    color: 'white',
    fontSize: '1em',
    marginTop: '6%',
    textAlign: 'left'
  },
  mateLink: {
    color: 'white',
    '&:hover': {
      color: 'white'
    }
  },
  appStore: {
    width: isMobile ? '50%' : '90%',
    objectFit: 'contain'
  },
  googleStore: {
    width: isMobile ? '50%' : '90%',
    objectFit: 'contain',
    marginTop: isMobile ? '2%' : 0
  },
  appGalleryStore: {
    width: isMobile ? '50%' : '90%',
    objectFit: 'contain',
    marginTop: isMobile ? '2%' : 0,
    marginBottom: isMobile ? '2%' : 0
  }
});

export default Footer;