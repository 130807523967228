import React, { useContext } from 'react';
import {
  Dialog,
  Button,
  makeStyles,
  Slide,
  Box
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { appColor, windowHeight, windowWidth } from '../common';
import { Close } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopUpModal = ({ img, visible, onClose }) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={visible}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ style: { width: isMobile ? '98%' : '42%', borderRadius: 20, padding: 0, margin: 0 } }}
        disableScrollLock
      >
        <Close onClick={onClose} className={classes.closeIcon} />
        <img src={img} alt="pop-up-img" className={classes.image} />
      </Dialog>
    </>
  );
};

const useStyles = makeStyles({
  image: {
    //width: '100%',
    objectFit: 'contain'
  },
  closeIcon: {
    position: 'absolute',
    color: '#000000',
    top: '2%',
    right: '2%',
    zIndex: 222,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  buttonContainer: {
    display: 'flex',
    alignSelf: 'center',
    position: 'absolute',
    zIndex: 2,
    bottom: '2%'
  },
  button: {
    backgroundColor: appColor,
    width: windowWidth / 8,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
      color: '#FFFFFF'
    }
  }
});

export default PopUpModal;