import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Button,
  Fade,
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { TextField, inputLabelClasses } from '@mui/material'
import CustomModal from '../components/CustomModal';
import { Context as StudentsContext } from '../context/StudentsContext';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { appColor, windowWidth } from '../common';

const height = window.innerHeight;
const width = window.innerWidth;

const ForgotPasswordScreen = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const classes = useStyles();

  const navigate = useNavigate();

  const { checkStudentUserExistence } = useContext(StudentsContext);

  const handleCheckPhoneNumber = async () => {
    if (!phoneNumber) {
      setModalVisible(true);
      setModalText('نسيت إدخال رقم الهاتف');
      return;
    } else {
      setLoading(true);
      const studentExists = await checkStudentUserExistence(phoneNumber);
      if (!studentExists) {
        setModalVisible(true);
        setModalText('رقم الهاتف غير مسجل ، يرجى المحاولة مرة أخرى');
      } else {
        navigate('/verification', { state: { type: 'change_password', phoneNumber } });
      }
      setLoading(false);
    }
  }

  return (
    <Box>
      <Container className={classes.container}>
        <Fade timeout={1000} in={true}>
          <div>
            <Box className={classes.box}>
              <img
                src={require('../images/logo.png')}
                alt="logo"
                className={isMobileOnly ? classes.logoMobile : classes.logo}
              />
              <Typography className={classes.siginInText}>نسيت كلمة المرور</Typography>
              <Box style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: isMobile ? '80%' : '30%'
              }}>
              <TextField
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                style={styles.textField}
                id="outlined-basic"
                type="number"
                label='رقم الهاتف'
                variant="outlined"
                color="primary"
                InputProps={{
                  style: { fontFamily: 'tajawal' },
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal',
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: appColor
                    }
                  },
                }}
                onKeyPress={ev => {
                  if (ev.key === 'Enter') {
                    handleCheckPhoneNumber();
                    ev.preventDefault();
                  }
                }}
              />
              </Box>
              <Button
                type="submit"
                variant="contained"
                className={isMobileOnly ? classes.mobileButton : classes.button}
                onClick={handleCheckPhoneNumber}
              >
                تحقق {loading && <CircularProgress size={20} style={{ color: '#FFFFFF', marginRight: '2%' }} />}
              </Button>
              </Box>
          </div>
        </Fade>
        <CustomModal
          title='تنبيه!'
          content={modalText}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          showConfirmButton={false}
          loading={modalLoading}
          englishCloseText="Close"
          arabicCloseText="إغلاق"
        />
      </Container>
    </Box>
  );
};

const styles = {
  textField: {
    width: isMobile ? '100%' : '100%',
    marginTop: isMobile ? '5%' : '2%',
    backgroundColor: '#FFFFFF'
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    height: height / 1.15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'rtl'
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[3],
    padding: '2%',
    paddingBlock: isMobile ? '15%' : '4%',
    width: isMobile ? windowWidth / 1.1 : windowWidth / 1.2,
    backgroundColor: '#FFFFFF'
  },
  logo: {
    alignSelf: 'center',
    objectFit: 'contain',
    width: windowWidth / 8
  },
  logoMobile: {
    alignSelf: 'center',
    //width: width / 1.5,
    objectFit: 'contain',
    height: height / 8
  },
  siginInText: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBlock: '1%'
  },
  textField: {
    width: '100%',
    marginTop: 10,
    backgroundColor: '#FFFFFF'
  },
  mobileTextField: {
    width: '80%',
    marginTop: '5%'
  },
  notchedOutline: {
    border: '1px solid',
    borderColor: '#E77813 !important',
    borderRadius: 5,
  },
  button: {
    width: '30%',
    marginTop: '2%',
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  mobileButton: {
    width: '50%',
    marginTop: '5%',
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  noAccountText: {
    fontSize: '1.1em',
    marginTop: '1%'
  },
  signUpLink: {
    textDecorationLine: 'none',
    color: appColor,
    '&:hover': {
      color: appColor
    }
  },
  forgotLink: {
    textDecorationLine: 'none',
    color: appColor,
    marginTop: '2%',
    alignSelf: 'flex-end',
    '&:hover': {
      color: appColor
    }
  }
}));

export default ForgotPasswordScreen;