import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Button,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { Context as StudentsContext } from '../context/StudentsContext';
import { Context as OtpContext } from '../context/OtpContext';
import { TextField, inputLabelClasses } from '@mui/material'
import { VerifiedUser } from "@material-ui/icons";
import { appColor, windowHeight, windowWidth } from "../common";
import { isMobile } from "react-device-detect";
import CustomModal from "../components/CustomModal";

const VerificationScreen = () => {
  const [type, setType] = useState('');
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [foundBy, setFoundBy] = useState(null);
  const [generation, setGeneration] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');

  const [countdown, setCountdown] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [showSendAgain, setShowSendAgain] = useState(false);

  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { signup, signin } = useContext(StudentsContext);
  const { otpRequest, otpCheck } = useContext(OtpContext);

  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const confirmVerificationCode = async () => {
    setLoading(true);
    const { error } = await otpCheck(phoneNumber, verificationCode);
    if (!error) {
      if (type === 'signup') {
        await signup(firstName, lastName, phoneNumber, password, generation, foundBy);
        setModalText('تم التحقق بنجاح \n \n أهلا وسهلا بك في منصة سين جيم');
        setModalVisible(true);
        setLoading(false);
        setTimeout(() => {
          setModalVisible(false)
          navigate('/');
        }, 2000);
      } else if (type === 'signin') {
        await signin(phoneNumber, password);
        navigate('/');
      } else if (type === 'change_password') {
        navigate('/change-password', { state: { phoneNumber } });
      }
    } else {
      setLoading(false);
      if (error === "'Not Found") {
        setModalText('الرمز خاطئ ، يرجى المحاولة مرة أخرى');
      } else if (error === "Expired") {
        setModalText('انتهت صلاحية هذا الرمز ، يرجى النقر على إرسال الرمز مرة أخرى');
      } else {
        setModalText('الرمز خاطئ ، يرجى المحاولة مرة أخرى');
      }

      setModalVisible(true);
    }
  }

  useEffect(() => {
    if (location.state) {
      setType(location.state.type);
      setFirstName(location.state.firstName);
      setLastName(location.state.lastName);
      setPhoneNumber(location.state.phoneNumber);
      setPassword(location.state.password);
      otpRequest(location.state.phoneNumber);
      setGeneration(location.state.generation);
      setFoundBy(location.state.foundBy);
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setTimerActive(true);
    let interval;

    if (timerActive && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((count) => count - 1);
      }, 1000);
    }

    if (countdown === 0) {
      setTimerActive(false);
      setShowSendAgain(true)
    }

    return () => clearInterval(interval);
  }, [timerActive, countdown])

  return (
    <Box>
      <Container className={classes.container}>
        <div id="recaptcha-container" />
        <Box className={classes.box}>
          <VerifiedUser className={classes.accountIcon} />
          <Typography className={classes.codeTitle}>أدخل الرمز المرسل إلى هاتفك</Typography>
          <TextField
            type="number"
            style={styles.textField}
            value={verificationCode}

            onChange={e => setVerificationCode(e.target.value)}
            label="أدخل الرمز"
            variant="outlined"
            InputProps={{
              style: { fontFamily: 'tajawal' },
              classes: {
                notchedOutline: classes.notchedOutline
              }
            }}
            InputLabelProps={{
              sx: {
                fontFamily: 'tajawal',
                textAlign: 'center',
                [`&.${inputLabelClasses.shrink}`]: {
                  color: appColor
                }
              },
            }}
            className={classes.textField}
            inputProps={{ style: { textAlign: 'center' } }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
            }}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                confirmVerificationCode();
                ev.preventDefault();
              }
            }}
          />
          <Button
            variant="contained"
            className={classes.submitButton}
            onClick={confirmVerificationCode}
          >
            تحقق {loading && <CircularProgress size={20} style={{ color: '#FFFFFF', marginRight: '2%' }} />}
          </Button>
          {showSendAgain ?
            <Button
              className={classes.sendAgain}
              variant="text"
              onClick={() => {
                setTimerActive(true);
                setCountdown(60);
                setShowSendAgain(!showSendAgain);
                otpRequest(phoneNumber);
              }}
            >
              إرسال الرمز مرة أخرى
            </Button>
            : <Typography>
              يمكنك إعادة إرسال الرمز بعد 00:{String(countdown).padStart(2, '0')} ثانية
            </Typography>
          }
        </Box>
        <CustomModal
          title='تنبيه!'
          content={modalText}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          showConfirmButton={false}
          loading={loading}
          englishCloseText="Close"
          arabicCloseText="إغلاق"
        />
      </Container>
    </Box>
  );
};

const styles = {
  textField: {
    marginTop: isMobile ? '3%' : '1.5%',
    width: isMobile ? '80%' : '45%',
    textAlign: 'center',
    backgroundColor: '#FFFFFF'
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    height: windowHeight / 1.15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'rtl'
  },
  box: {
    display: 'flex',
    padding: '1%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[3],
    height: windowHeight / 1.45,
    width: windowWidth / 1.05
  },
  accountIcon: {
    color: appColor,
    fontSize: '5em',
    alignSelf: 'center',
  },
  codeTitle: {
    fontSize: '1.5em',
    textAlign: 'center',
    marginTop: '1%'
  },
  notchedOutline: {
    border: '1px solid',
    borderColor: '#E77813 !important',
    borderRadius: 5,
  },
  submitButton: {
    width: isMobile ? '75%' : '30%',
    marginTop: '2%',
    marginBottom: '1%',
    backgroundColor: appColor,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
    }
  },
  sendAgain: {
    color: appColor,
    fontSize: '0.9em',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: appColor,
      backgroundColor: '#FFFFFF'
    }
  },
  signUpLink: {
    color: 'gray',
    textDecorationLine: 'none',
    '&:hover': {
      color: appColor
    }
  }
}));

export default VerificationScreen;