import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Fade,
  Grid,
  MenuItem,
  Typography
} from "@material-ui/core";
import { Select } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { Context as AdminContext } from '../../context/AdminContext';
import { Context as TeacherContext } from '../../context/TeacherContext';
import { Context as SubjectContext } from '../../context/SubjectContext';
import { Context as TermContext } from '../../context/TermContext';
import { Context as UnitContext } from '../../context/UnitContext';
import { Context as LessonContext } from '../../context/LessonContext';
import { Context as QuestionContext } from '../../context/QuestionContext';
import CustomModal from "../../components/CustomModal";
import { Delete } from "@material-ui/icons";
import { appColor } from "../../common";

const DeleteSubject = () => {
  const [subjects, setSubjects] = useState([]);

  const [selectedSubjectGrade, setSelectedSubjectGrade] = useState('');
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjectModalVisible, setSubjectModalVisible] = useState(false);

  const [selectedTermGrade, setSelectedTermGrade] = useState('');
  const [selectedTermSubject, setSelectedTermSubject] = useState(null);
  const [deleteSelectedTerm, setDeleteSelectedTerm] = useState(null);
  const [termModalVisible, setTermModalVisible] = useState(false);

  const [selectedUnitGrade, setSelectedUnitGrade] = useState('');
  const [selectedUnitSubject, setSelectedUnitSubject] = useState(null);
  const [selectedUnitTerm, setSelectedUnitTerm] = useState(null);
  const [deleteSelectedUnit, setDeleteSelectedUnit] = useState(null);
  const [unitModalVisible, setUnitModalVisible] = useState(false);

  const [selectedLessonGrade, setSelectedLessonGrade] = useState('');
  const [selectedLessonSubject, setSelectedLessonSubject] = useState(null);
  const [selectedLessonTerm, setSelectedLessonTerm] = useState(null);
  const [selectedLessonUnit, setSelectedLessonUnit] = useState(null);
  const [deleteSelectedLesson, setDeleteSelectedLesson] = useState(null);
  const [lessonModalVisible, setLessonModalVisible] = useState(false);

  const [selectedQuestionGrade, setSelectedQuestionGrade] = useState('');
  const [selectedQuestionSubject, setSelectedQuestionSubject] = useState(null);
  const [selectedQuestionTerm, setSelectedQuestionTerm] = useState(null);
  const [selectedQuestionUnit, setSelectedQuestionUnit] = useState(null);
  const [selectedQuestionLesson, setSelectedQuestionLesson] = useState(null);
  const [deleteSelectedQuestion, setDeleteSelectedQuestion] = useState(null);
  const [questionModalVisible, setQuestionModalVisible] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModaContent] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false)
  const [loading, setLoading] = useState(false);

  const { state: { subjects: allSubjects }, fetchSubjects, deleteSubject } = useContext(SubjectContext);
  const { state: { terms }, fetchTerms, deleteTerm } = useContext(TermContext);
  const { state: { units }, fetchUnits, deleteUnit } = useContext(UnitContext);
  const { state: { lessons }, fetchLessons, deleteLesson } = useContext(LessonContext);
  const { state: { questions }, fetchQuestions, deleteQuestion } = useContext(QuestionContext);
  const { state: { teacherToken, subjects: teacherSubjects }, tryTeacherLocalSignin } = useContext(TeacherContext);
  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);

  const navigate = useNavigate();

  const handleDeleteSubjectSubmit = async () => {
    setLoading(true)
    const res = await deleteSubject(selectedSubject);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setSubjectModalVisible(true);
      setShowConfirmButton(false)
    } else {
      setSubjectModalVisible(true);
      setModalTitle('تم الحذف بنجاح');
      setModaContent(`تم حذف المادة بنجاح`);
      setShowConfirmButton(false);
      navigate(0)
    }
    setLoading(false);
  };

  const handleDeleteTermSubmit = async () => {
    setLoading(true)
    const res = await deleteTerm(deleteSelectedTerm);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setTermModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setTermModalVisible(true);
      setModalTitle('تمت الحذف بنجاح');
      setModaContent(`تم حذف الفصل بنجاح`);
      setShowConfirmButton(false);
      navigate(0)
    }
    setLoading(false);
  };

  const handleDeleteUnitSubmit = async () => {
    setLoading(true)
    const res = await deleteUnit(deleteSelectedUnit);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setUnitModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setUnitModalVisible(true);
      setModalTitle('تم الحذف بنجاح');
      setModaContent(`تم حذف الوحدة بنجاح`);
      setShowConfirmButton(false);
      navigate(0);
    }
    setLoading(false);
  };

  const handleDeleteLessonSubmit = async () => {
    setLoading(true)
    const res = await deleteLesson(deleteSelectedLesson);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setLessonModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setLessonModalVisible(true);
      setModalTitle('تم الحذف بنجاح');
      setModaContent(`تم حذف الدرس بنجاح`);
      setShowConfirmButton(false);
      navigate(0);
    }
    setLoading(false);
  };

  const handleDeleteQuestionSubmit = async () => {
    setLoading(true)
    const res = await deleteQuestion(deleteSelectedQuestion);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setQuestionModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setQuestionModalVisible(true);
      setModalTitle('تم الحذف بنجاح');
      setModaContent(`تم حذف السؤال بنجاح`);
      setShowConfirmButton(false);
      navigate(0);
    }
    setLoading(false);
  };



  useEffect(() => {
    if (adminToken) {
      tryAdminLocalSignin(navigate, '/delete_subject');
    } else if (teacherToken) {
      tryTeacherLocalSignin(navigate, '/delete_subject')
    } else {
      navigate('/');
    }

    async function fetchSubjectsData() {
      await fetchSubjects();
    }

    if (!subjects.length) fetchSubjectsData()
  }, []);

  useEffect(() => {
    if (adminToken) {
      setSubjects(allSubjects);
    } else if (teacherToken) {
      setSubjects(teacherSubjects);

      if (teacherSubjects.length) {
        setSelectedTermSubject(teacherSubjects[0]._id);
        setSelectedUnitSubject(teacherSubjects[0]._id);
        setSelectedLessonSubject(teacherSubjects[0]._id);
        setSelectedQuestionSubject(teacherSubjects[0]._id);
        fetchTerms(teacherSubjects[0]._id);
      }
    }
  }, [allSubjects, teacherSubjects]);

  if (!adminToken && !teacherToken) {
    return null;
  }

  return (
    <Container style={{ direction: 'rtl' }}>
      <Fade timeout={1000} in={true}>
        <div>
          {adminToken && <>
            <Box style={styles.titleContainer}>
              <Delete style={styles.icon} />
              <Typography style={styles.title}>حذف مادة</Typography>
            </Box>
            <hr />
            <Grid container>
              <Grid item md={4} xs={12}>
                <Typography style={styles.label}>اختر الجيل</Typography>
                <Select
                  placeholder="اختر الجيل"
                  variant="outlined"
                  style={styles.select}
                  value={selectedSubjectGrade}
                  sx={{
                    fontFamily: 'tajawal'
                  }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  onChange={(e) => {
                    setSelectedSubjectGrade(e.target.value);
                  }
                  }
                >
                  {[11, 12].map((grade) => (
                    <MenuItem value={grade}>
                      {grade}
                    </MenuItem>
                  )
                  )}
                </Select>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography style={styles.label}>اختر المادة</Typography>
                <Select
                  placeholder="اختر المادة"
                  variant="outlined"
                  style={styles.select}
                  value={selectedSubject}
                  sx={{
                    fontFamily: 'tajawal'
                  }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  //className={classes.selectNews}
                  onChange={(e) => setSelectedSubject(e.target.value)}
                >
                  {subjects.map((subject) => {
                    if (selectedSubjectGrade !== subject.grade) return;
                    return (
                      <MenuItem value={subject._id}>
                        {subject.subjectName}
                      </MenuItem>
                    )
                  }
                  )}
                </Select>
              </Grid>
              <Grid style={styles.gridStyle} item md={4} xs={12}>
                <Button
                  variant="contained"
                  style={styles.button}
                  onClick={() => {
                    if (!selectedSubject) {
                      return;
                    }
                    setSubjectModalVisible(true);
                    setModalTitle("تنبيه!");
                    setModaContent(`هل أنت متأكد من حذف المادة ؟`);
                    setShowConfirmButton(true);
                  }}
                >
                  أرسل
                </Button>
              </Grid>
            </Grid>

            <CustomModal
              visible={subjectModalVisible}
              title={modalTitle}
              content={modalContent}
              showConfirmButton={showConfirmButton}
              englishConfirmText="Yes"
              arabicConfirmText="نعم"
              englishCloseText="Close"
              arabicCloseText="إغلاق"
              onCloseBackDropClick={() => setSubjectModalVisible(false)}
              onCancel={() => {
                setSubjectModalVisible(false)
              }}
              onConfirm={handleDeleteSubjectSubmit}
              loading={loading}
            />
          </>}

          <Box style={{ ...styles.titleContainer, marginTop: adminToken ? '5%' : '2%' }}>
            <Delete style={styles.icon} />
            <Typography style={styles.title}>حذف فصل</Typography>
          </Box>
          <hr />
          <Grid container>
            <Grid item md={3} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedTermGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedTermGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                value={selectedTermSubject}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedTermSubject(e.target.value);
                  fetchTerms(e.target.value);
                }}
              >
                {subjects.map((subject) => {
                  if (selectedTermGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                value={deleteSelectedTerm}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => setDeleteSelectedTerm(e.target.value)}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid style={styles.gridStyle} item md={3} xs={12}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={() => {
                  if (!selectedTermSubject || !deleteSelectedTerm) {
                    return;
                  }
                  setTermModalVisible(true);
                  setModalTitle("تنبيه!");
                  setModaContent(`هل أنت متأكد من حذف الفصل ؟`);
                  setShowConfirmButton(true);
                }}
              >
                أرسل
              </Button>
            </Grid>
          </Grid>

          <CustomModal
            visible={termModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setTermModalVisible(false)}
            onCancel={() => {
              setTermModalVisible(false)
            }}
            onConfirm={handleDeleteTermSubmit}
            loading={loading}
          />

          <Box style={{ ...styles.titleContainer, marginTop: '5%' }}>
            <Delete style={styles.icon} />
            <Typography style={styles.title}>حذف وحدة</Typography>
          </Box>
          <hr />
          <Grid container>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedUnitGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedUnitGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                value={selectedUnitSubject}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedUnitSubject(e.target.value);
                  fetchTerms(e.target.value)
                }
                }
              >
                {subjects.map((subject) => {
                  if (selectedUnitGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                value={selectedUnitTerm}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedUnitTerm(e.target.value);
                  fetchUnits(e.target.value);
                }}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الوحدة</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                value={deleteSelectedUnit}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => setDeleteSelectedUnit(e.target.value)}
              >
                {units.map((unit) => (
                  <MenuItem value={unit._id}>
                    {unit.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid style={styles.gridStyle} item md={12} xs={12}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={() => {
                  if (!selectedUnitSubject || !selectedUnitTerm || !deleteSelectedUnit) {
                    return;
                  }
                  setUnitModalVisible(true);
                  setModalTitle("تنبيه!");
                  setModaContent(`هل أنت متأكد من حذف الوحدة ؟`);
                  setShowConfirmButton(true);
                }}
              >
                أرسل
              </Button>
            </Grid>
          </Grid>

          <CustomModal
            visible={unitModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setUnitModalVisible(false)}
            onCancel={() => setUnitModalVisible(false)}
            onConfirm={handleDeleteUnitSubmit}
            loading={loading}
          />

          <Box style={{ ...styles.titleContainer, marginTop: '5%' }}>
            <Delete style={styles.icon} />
            <Typography style={styles.title}>حذف درس</Typography>
          </Box>
          <hr />
          <Grid container>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedLessonGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedLessonGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                value={selectedLessonSubject}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedLessonSubject(e.target.value);
                  fetchTerms(e.target.value)
                }
                }
              >
                {subjects.map((subject) => {
                  if (selectedLessonGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                value={selectedLessonTerm}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedLessonTerm(e.target.value);
                  fetchUnits(e.target.value);
                }}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الوحدة</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                value={selectedLessonUnit}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedLessonUnit(e.target.value);
                  fetchLessons(e.target.value, false);
                }}
              >
                {units.map((unit) => (
                  <MenuItem value={unit._id}>
                    {unit.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الدرس</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                value={deleteSelectedLesson}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => setDeleteSelectedLesson(e.target.value)}
              >
                {lessons.map((lesson) => (
                  <MenuItem value={lesson._id}>
                    {lesson.lessonName}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid style={styles.gridStyle} item md={12} xs={12}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={() => {
                  if (!selectedLessonSubject || !selectedLessonTerm || !selectedLessonUnit || !deleteSelectedLesson) {
                    return;
                  }
                  setLessonModalVisible(true);
                  setModalTitle("تنبيه!");
                  setModaContent(`هل أنت متأكد من حذف الدرس؟`);
                  setShowConfirmButton(true);
                }}
              >
                أرسل
              </Button>
            </Grid>
          </Grid>

          <CustomModal
            visible={lessonModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setLessonModalVisible(false)}
            onCancel={() => setLessonModalVisible(false)}
            onConfirm={handleDeleteLessonSubmit}
            loading={loading}
          />

          <Box style={{ ...styles.titleContainer, marginTop: '5%' }}>
            <Delete style={styles.icon} />
            <Typography style={styles.title}>حذف سؤال</Typography>
          </Box>
          <hr />
          <Grid container>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedQuestionGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedQuestionGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                value={selectedQuestionSubject}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedQuestionSubject(e.target.value);
                  fetchTerms(e.target.value)
                }
                }
              >
                {subjects.map((subject) => {
                  if (selectedQuestionGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                value={selectedQuestionTerm}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedQuestionTerm(e.target.value);
                  fetchUnits(e.target.value);
                }}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الوحدة</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                value={selectedQuestionUnit}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedQuestionUnit(e.target.value);
                  fetchLessons(e.target.value, false);
                }}
              >
                {units.map((unit) => (
                  <MenuItem value={unit._id}>
                    {unit.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الدرس</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                value={selectedQuestionLesson}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedQuestionLesson(e.target.value);
                  fetchQuestions(e.target.value, false)
                }}
              >
                {lessons.map((lesson) => (
                  <MenuItem value={lesson._id}>
                    {lesson.lessonName}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر السؤال</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                value={deleteSelectedQuestion}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => setDeleteSelectedQuestion(e.target.value)}
              >
                {questions.map((question, index) => (
                  <MenuItem value={question._id}>
                    <Box style={{ display: 'flex' }}>
                      {index + 1}. <div dangerouslySetInnerHTML={{ __html: question.questionText }} />
                    </Box>
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid style={{ ...styles.gridStyle, marginBottom: '3%' }} item md={12} xs={12}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={() => {
                  if (!selectedQuestionSubject || !selectedQuestionTerm || !selectedQuestionUnit || !selectedQuestionLesson || !deleteSelectedQuestion) {
                    return;
                  }
                  setQuestionModalVisible(true);
                  setModalTitle("تنبيه!");
                  setModaContent(`هل أنت متأكد من حذف السؤال ؟`);
                  setShowConfirmButton(true);
                }}
              >
                أرسل
              </Button>
            </Grid>
          </Grid>

          <CustomModal
            visible={questionModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setQuestionModalVisible(false)}
            onCancel={() => setQuestionModalVisible(false)}
            onConfirm={handleDeleteQuestionSubmit}
            loading={loading}
          />
        </div>
      </Fade>
    </Container>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2%'
  },
  title: {
    fontSize: '1.3em',
    marginRight: '0.5%'
  },
  icon: {
    color: appColor,
  },
  gridStyle: {
    marginTop: '2%',
  },
  textField: {
    //marginTop: '2%',
    width: isMobile ? '70%' : '80%',
  },
  label: {
    fontSize: '1.2em',
    marginTop: '2%',
    marginBottom: '2%',
    fontWeight: '500'
  },
  select: {
    marginTop: '1%',
    width: '70%',
    backgroundColor: '#FFFFFF'
  },
  button: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'left',
    marginTop: isMobile ? '5%' : '1%',
    width: isMobile ? '30vw' : '10vw'
  }
}

export default DeleteSubject;