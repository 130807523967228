import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Divider,
  Fade,
  FormControl,
  Grid,
  MenuItem,
  RadioGroup,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Select, TextField } from '@mui/material'
import { isMobile } from "react-device-detect";
import { Context as AdminContext } from '../../context/AdminContext';
import { Context as TeacherContext } from '../../context/TeacherContext';
import { Context as SubjectContext } from '../../context/SubjectContext';
import { Context as TermContext } from '../../context/TermContext';
import { Context as UnitContext } from '../../context/UnitContext';
import { Context as LessonContext } from '../../context/LessonContext';
import { Context as QuestionContext } from '../../context/QuestionContext';
import CustomModal from "../../components/CustomModal";
import { Add, Edit, Close } from "@material-ui/icons";
import { appColor, windowHeight, windowWidth } from "../../common";
import Answer from "../components/Answer";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';
window.katex = katex;

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ 'align': [] }],
    [{ 'direction': 'rtl' }],
    ['formula']
  ],
};

const formats = [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'script',
  'header', 'blockquote', 'code-block',
  'indent', 'list',
  'direction', 'align',
  'link', 'image', 'video', 'formula',
]


const EditQuestion = () => {
  const [subjects, setSubjects] = useState([]);

  const [selectedLessonGrade, setSelectedLessonGrade] = useState('');
  const [selectedLessonSubject, setSelectedLessonSubject] = useState('');
  const [selectedLessonTerm, setSelectedLessonTerm] = useState('');
  const [selectedLessonUnit, setSelectedLessonUnit] = useState('');
  const [selectedEditedLesson, setSelectedEditedLesson] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [lessonNumber, setLessonNumber] = useState(null);
  const [lessonModalVisible, setLessonModalVisible] = useState(false);
  const [lessonActivated, setLessonActivated] = useState('');

  const [selectedQuestionGrade, setSelectedQuestionGrade] = useState('');
  const [selectedQuestionSubject, setSelectedQuestionSubject] = useState(null);
  const [selectedQuestionTerm, setSelectedQuestionTerm] = useState('');
  const [selectedQuestionUnit, setSelectedQuestionUnit] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');
  const [selectedEditedQuestion, setSelectedEditedQuestion] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [questionImage, setQuestionImage] = useState(null);

  const [selectedGradeForTransfer, setSelectedGradeForTransfer] = useState(null);
  const [selectedSubjectForTransfer, setSelectedSubjectForTransfer] = useState(null);
  const [selectedTermForTransfer, setSelectedTermForTransfer] = useState(null);
  const [selectedUnitForTransfer, setSelectedUnitForTransfer] = useState('');
  const [selectedFromLessonForTransfer, setSelectedFromLessonForTransfer] = useState(null);
  const [selectedToLessonForTransfer, setSelectedToLessonForTransfer] = useState(null);
  const [selectedQuestionForTransfer, setSelectedQuestionForTransfer] = useState(null);

  const [transferModalVisible, setTransferModalVisible] = useState(false);

  const [trueAnswer, setTrueAnswer] = useState(null);

  const [firstAnswerText, setFirstAnswerText] = useState('');
  const [firstAnswerImage, setFirstAnswerImage] = useState(null);

  const [firstNoteText, setFirstNoteText] = useState('');
  const [firstNoteImage, setFirstNoteImage] = useState(null);
  const [firstNoteLink, setFirstNoteLink] = useState('');
  const firstAnswerLabel = "الإجابة الأولى";

  const [secondAnswerText, setSecondAnswerText] = useState('');
  const [secondAnswerImage, setSecondAnswerImage] = useState(null);

  const [secondNoteText, setSecondNoteText] = useState('');
  const [secondNoteImage, setSecondNoteImage] = useState(null);
  const [secondNoteLink, setSecondNoteLink] = useState('');
  const secondAnswerLabel = "الإجابة الثانية";

  const [thirdAnswerText, setThirdAnswerText] = useState('');
  const [thirdAnswerImage, setThirdAnswerImage] = useState(null);

  const [thirdNoteText, setThirdNoteText] = useState('');
  const [thirdNoteImage, setThirdNoteImage] = useState(null);
  const [thirdNoteLink, setThirdNoteLink] = useState('');
  const thirdAnswerLabel = "الإجابة الثالثة";

  const [fourthAnswerText, setFourthAnswerText] = useState('');
  const [fourthAnswerImage, setFourthAnswerImage] = useState(null);

  const [fourthNoteText, setFourthNoteText] = useState('');
  const [fourthNoteImage, setFourthNoteImage] = useState(null);
  const [fourthNoteLink, setFourthNoteLink] = useState('');
  const fourthAnswerLabel = "الإجابة الرابعة";

  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModaContent] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false)
  const [loading, setLoading] = useState(false);
  const [resetPhoto, setResetPhoto] = useState(false);

  const { state: { subjects: allSubjects }, fetchSubjects } = useContext(SubjectContext);
  const { state: { terms }, fetchTerms } = useContext(TermContext);
  const { state: { units }, fetchUnits } = useContext(UnitContext);
  const { state: { lessons }, fetchLessons, editLesson } = useContext(LessonContext);
  const {
    state: { questions, numTransferedQuestions },
    editQuestion,
    getQuestionImagesUrls,
    fetchQuestions,
    transferQuestion,
    getNumberOfTransferedQuestions
  } = useContext(QuestionContext);
  const { state: { teacherToken, subjects: teacherSubjects }, tryTeacherLocalSignin } = useContext(TeacherContext);
  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);

  const classes = useStyles();
  const navigate = useNavigate();

  const handleQuestionSubmit = async () => {
    setLoading(true);
    const {
      questionImageUrl, firstAnswerImageUrl, secondAnswerImageUrl, thirdAnswerImageUrl, fourthAnswerImageUrl,
      firstNoteImageUrl, secondNoteImageUrl, thirdNoteImageUrl, fourthNoteImageUrl
    } = await getQuestionImagesUrls(
      questionImage,
      firstAnswerImage,
      secondAnswerImage,
      thirdAnswerImage,
      fourthAnswerImage,
      firstNoteImage,
      secondNoteImage,
      thirdNoteImage,
      fourthNoteImage
    );
    const res = await editQuestion(selectedEditedQuestion, {
      questionText,
      image: questionImageUrl,
      answers: [
        {
          text: firstAnswerText,
          image: firstAnswerImageUrl,
          textNote: firstNoteText,
          imageNote: firstNoteImageUrl,
          linkNote: firstNoteLink,
          isTrue: trueAnswer === firstAnswerLabel
        },
        {
          text: secondAnswerText,
          image: secondAnswerImageUrl,
          textNote: secondNoteText,
          imageNote: secondNoteImageUrl,
          linkNote: secondNoteLink,
          isTrue: trueAnswer === secondAnswerLabel
        },
        {
          text: thirdAnswerText,
          image: thirdAnswerImageUrl,
          textNote: thirdNoteText,
          imageNote: thirdNoteImageUrl,
          linkNote: thirdNoteLink,
          isTrue: trueAnswer === thirdAnswerLabel
        },
        {
          text: fourthAnswerText,
          image: fourthAnswerImageUrl,
          textNote: fourthNoteText,
          imageNote: fourthNoteImageUrl,
          linkNote: fourthNoteLink,
          isTrue: trueAnswer === fourthAnswerLabel
        }
      ]
    });
    if (res.error) {
      setModalTitle("تنبيه!")
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى')
      setModalVisible(true);
      setShowConfirmButton(false)
    } else {
      setModalVisible(true);
      setModalTitle('تم التعديل بنجاح')
      setModaContent(`تم تعديل السؤال بنجاح`)
      setShowConfirmButton(false);
      setQuestionText('');
      setQuestionImage(null);

      setFirstAnswerText('');
      setFirstAnswerImage(null);
      setFirstNoteText('');
      setFirstNoteImage(null);
      setFirstNoteLink('');

      setSecondAnswerText('');
      setSecondAnswerImage(null);
      setSecondNoteText('');
      setSecondNoteImage(null);
      setSecondNoteLink('');

      setThirdAnswerText('');
      setThirdAnswerImage(null);
      setThirdNoteText('');
      setThirdNoteImage(null);
      setThirdNoteLink('');

      setFourthAnswerText('');
      setFourthAnswerImage(null);
      setFourthNoteText('');
      setFourthNoteImage(null);
      setFourthNoteLink('');

      setTrueAnswer(null);

      fetchQuestions(selectedLesson, false);
      //navigate(0);
    }
    setLoading(false);
  };

  const handleLessonSubmit = async () => {
    setLoading(true);
    const res = await editLesson(selectedEditedLesson, lessonName, lessonNumber, lessonActivated);
    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setLessonModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setLessonModalVisible(true);
      setModalTitle('تم التعديل بنجاح');
      setModaContent(`تم تعديل اسم الدرس بنجاح`);
      setShowConfirmButton(false);
      navigate(0);
    }
    setLoading(false);
  };

  const handleTransferQuestionSubmit = async () => {
    setLoading(true);
    const res = await transferQuestion(selectedToLessonForTransfer, selectedQuestionForTransfer);
    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setTransferModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setTransferModalVisible(true);
      setModalTitle('تم النقل بنجاح');
      setModaContent(`تم نقل السؤال بنجاح`);
      setShowConfirmButton(false);
      fetchQuestions(selectedFromLessonForTransfer);
      getNumberOfTransferedQuestions(selectedToLessonForTransfer);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function fetchSubjectsData() {
      await fetchSubjects();
    }

    if (!subjects.length) fetchSubjectsData();

    if (adminToken) {
      tryAdminLocalSignin(navigate, '/edit_question');
    } else if (teacherToken) {
      tryTeacherLocalSignin(navigate, '/edit_question')
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (adminToken) {
      setSubjects(allSubjects);
    } else if (teacherToken) {
      setSubjects(teacherSubjects);

      if (teacherSubjects.length) {
        setSelectedLessonSubject(teacherSubjects[0]._id);
        setSelectedQuestionSubject(teacherSubjects[0]._id);
        fetchTerms(teacherSubjects[0]._id);
      }
    }
  }, [allSubjects, teacherSubjects]);

  const validateImagesSizes = async (event, setState) => {
    event.preventDefault();

    if (event.target.files[0].size > 1048576) {
      setModalTitle("تنبيه!")
      setModaContent('يجب أن يكون حجم الصورة أقل من 1 ميجا');
      setModalVisible(true);
      setShowConfirmButton(false);
      setResetPhoto(true);
    } else {
      setState(event.target.files[0]);
    }
  };

  if (!adminToken && !teacherToken) {
    return null;
  }

  return (
    <Container style={{ direction: 'rtl' }}>
      <Fade timeout={1000} in={true}>
        <div>
          <Box style={styles.titleContainer}>
            <Edit style={styles.icon} />
            <Typography style={styles.title}>تعديل درس</Typography>
          </Box>
          <Divider style={{ marginTop: '1%' }} />
          <Grid container>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedLessonGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedLessonGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedLessonSubject}
                onChange={(e) => {
                  setSelectedLessonSubject(e.target.value);
                  fetchTerms(e.target.value);
                }}
              >
                {subjects.map((subject) => {
                  if (selectedLessonGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedLessonTerm}
                onChange={(e) => {
                  setSelectedLessonTerm(e.target.value);
                  fetchUnits(e.target.value);
                }}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الوحدة</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedLessonUnit}
                onChange={(e) => {
                  setSelectedLessonUnit(e.target.value);
                  fetchLessons(e.target.value, false);
                }}
              >
                {units.map((unit) => (
                  <MenuItem value={unit._id}>
                    {unit.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الدرس</Typography>
              <Select
                placeholder="اختر الدرس"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedEditedLesson(e.target.value._id);
                  setLessonName(e.target.value.lessonName);
                  setLessonNumber(e.target.value.number);
                  setLessonActivated(e.target.value.activated);
                }}
              >
                {lessons.map((lesson) => (
                  <MenuItem value={lesson}>
                    {lesson.lessonName}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>رقم الدرس</Typography>
              <TextField
                variant="outlined"
                type="number"
                placeholder="رقم الدرس"
                style={styles.textField}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                value={lessonNumber}
                onChange={(e) => {
                  setLessonNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اسم الدرس</Typography>
              <TextField
                variant="outlined"
                placeholder="اكتب اسم الدرس"
                style={styles.textField}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                value={lessonName}
                onChange={(e) => {
                  setLessonName(e.target.value);
                }}
              // onKeyPress={ev => {
              //   if (ev.key === 'Enter') {
              //     setModalVisible(true);
              //     setModalTitle("تنبيه!");
              //     setModaContent(`هل أنت متأكد من إضافة ${lessonName}?`);
              //     setShowConfirmButton(true);
              //     ev.preventDefault();
              //   }
              // }}
              />
            </Grid>
            {selectedEditedLesson && <Grid item md={4} xs={12}>
              <Box style={{ backgroundColor: '#FFFFFF', marginTop: '5%', border: '1px solid #dddddd', borderRadius: 5, width: '70%' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lessonActivated}
                      onChange={() => setLessonActivated(!lessonActivated)}
                      name="activated"
                      style={{ color: appColor }}
                    />
                  }
                  label="الدرس مفعّل"
                />
              </Box>
            </Grid>
            }
          </Grid>
          <Button
            variant="contained"
            style={styles.button}
            onClick={() => {
              if (!selectedLessonSubject || !selectedLessonTerm || !selectedLessonUnit || !selectedEditedLesson || !lessonName || !lessonNumber) {
                return;
              }
              setLessonModalVisible(true);
              setModalTitle("تنبيه!");
              setModaContent(`هل أنت متأكد من تعديل هذا الدرس ؟`);
              setShowConfirmButton(true);
            }}
          >
            أرسل
          </Button>
          <CustomModal
            visible={lessonModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setLessonModalVisible(false)}
            onCancel={() => setLessonModalVisible(false)}
            onConfirm={handleLessonSubmit}
            loading={loading}
          />
          <Box style={styles.questionTitleContainer}>
            <Edit style={styles.icon} />
            <Typography style={styles.title}>تعديل سؤال</Typography>
          </Box>
          <hr style={{ marginTop: '1%' }} />
          <Grid container>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedQuestionGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedQuestionGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedQuestionSubject}
                onChange={(e) => {
                  setSelectedQuestionSubject(e.target.value);
                  fetchTerms(e.target.value);
                }}
              >
                {subjects.map((subject) => {
                  if (selectedQuestionGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedQuestionTerm}
                onChange={(e) => {
                  setSelectedQuestionTerm(e.target.value);
                  fetchUnits(e.target.value);
                }}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الوحدة</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedQuestionUnit}
                onChange={(e) => {
                  setSelectedQuestionUnit(e.target.value);
                  fetchLessons(e.target.value, false);
                }}
              >
                {units.map((unit) => (
                  <MenuItem value={unit._id}>
                    {unit.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الدرس</Typography>
              <Select
                placeholder="اختر الدرس"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedLesson}
                onChange={(e) => {
                  setSelectedLesson(e.target.value);
                  fetchQuestions(e.target.value, false);
                }}
              >
                {lessons.map((lesson) => (
                  <MenuItem value={lesson._id}>
                    {lesson.lessonName}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر السؤال</Typography>
              <Select
                placeholder="اختر السؤال"
                variant="outlined"
                style={{
                  ...styles.select,
                  direction: 'ltr'
                }}
                sx={{
                  fontFamily: 'tajawal',
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedEditedQuestion(e.target.value._id);
                  setQuestionText(e.target.value.questionText);
                  setQuestionImage(e.target.value.image)

                  const answers = e.target.value.answers;
                  setFirstAnswerText(answers[0].text);
                  setSecondAnswerText(answers[1].text);
                  setThirdAnswerText(answers[2].text);
                  setFourthAnswerText(answers[3].text);

                  setFirstAnswerImage(answers[0].image);
                  setSecondAnswerImage(answers[1].image);
                  setThirdAnswerImage(answers[2].image);
                  setFourthAnswerImage(answers[3].image);

                  setFirstNoteText(answers[0].textNote);
                  setSecondNoteText(answers[1].textNote);
                  setThirdNoteText(answers[2].textNote);
                  setFourthNoteText(answers[3].textNote);

                  setFirstNoteImage(answers[0].imageNote);
                  setSecondNoteImage(answers[1].imageNote);
                  setThirdNoteImage(answers[2].imageNote);
                  setFourthNoteImage(answers[3].imageNote);

                  setFirstNoteLink(answers[0].linkNote);
                  setSecondNoteLink(answers[1].linkNote);
                  setThirdNoteLink(answers[2].linkNote);
                  setFourthNoteLink(answers[3].linkNote);

                  if (answers[0].isTrue)
                    setTrueAnswer(firstAnswerLabel);
                  else if (answers[1].isTrue)
                    setTrueAnswer(secondAnswerLabel);
                  else if (answers[2].isTrue)
                    setTrueAnswer(thirdAnswerLabel);
                  else if (answers[3].isTrue)
                    setTrueAnswer(fourthAnswerLabel);
                }}
              >
                {questions.map((question, index) => {
                  return (
                    <MenuItem value={question}>
                      <Box style={{
                        display: 'flex',
                        flexDirection: selectedEditedLesson === '660d1aa65b1a4fa77f6dd7ab' ? 'row' : 'row-reverse'
                      }}
                      >
                        .{index + 1} <div dangerouslySetInnerHTML={{ __html: question.questionText }} />
                      </Box>
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={{ marginBlock: '2%', fontSize: '1.2em', fontWeight: 'bold' }}>
                عدد الأسئلة المضافة: <span style={{ color: 'red' }}>{questions.length}</span>
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography style={styles.label}>نص السؤال</Typography>
              {/* <TextField
                variant="outlined"
                placeholder="اكتب نص السؤال هنا..."
                multiline
                style={styles.textField}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
              /> */}
              <ReactQuill
                theme="snow"
                value={questionText}
                onChange={setQuestionText}
                modules={modules}
                formats={formats}
                style={{ backgroundColor: '#FFFFFF', width: '95%', direction: 'ltr' }}
              />
            </Grid>
            <Grid style={{ marginTop: '4%' }} item md={4} xs={12}>
              <Button
                className={classes.uploadButton}
                variant="contained"
                component="label"
              >
                تعديل صورة السؤال
                <input
                  name="photos"
                  type="file"
                  id="files"
                  onChange={event => validateImagesSizes(event, setQuestionImage)}
                  hidden
                />
              </Button>
              <Typography variant="h6">
                {questionImage ? 'تم التحميل' : 'الرجاء تحميل صورة (اختياري)'}
              </Typography>
            </Grid>
          </Grid>
          <FormControl
            onChange={(e) => {
              if (e.target.name === 'answer')
                setTrueAnswer(e.target.value)
            }}
            component="fieldset"
            style={{ width: '100%' }}
          >
            <RadioGroup data-aos="fade-right" aria-label="answer" name="answer">
              <Answer
                answerText={firstAnswerText}
                setAnswerText={setFirstAnswerText}
                uploadImageLabel="تعديل صورة الإجابة الأولى"
                answerImage={firstAnswerImage}
                setAnswerImage={setFirstAnswerImage}
                noteText={firstNoteText}
                setNoteText={setFirstNoteText}
                noteImage={firstNoteImage}
                setNoteImage={setFirstNoteImage}
                noteLink={firstNoteLink}
                setNoteLink={setFirstNoteLink}
                label={firstAnswerLabel}
                addNoteText="تعديل أو إضافة ملاحظة"
                trueAnswer={trueAnswer}
              />
              <Answer
                answerText={secondAnswerText}
                setAnswerText={setSecondAnswerText}
                uploadImageLabel="تعديل صورة الإجابة الثانية"
                answerImage={secondAnswerImage}
                setAnswerImage={setSecondAnswerImage}
                noteText={secondNoteText}
                setNoteText={setSecondNoteText}
                noteImage={secondNoteImage}
                setNoteImage={setSecondNoteImage}
                noteLink={secondNoteLink}
                setNoteLink={setSecondNoteLink}
                label={secondAnswerLabel}
                addNoteText="تعديل أو إضافة ملاحظة"
                trueAnswer={trueAnswer}
              />
              <Answer
                answerText={thirdAnswerText}
                setAnswerText={setThirdAnswerText}
                uploadImageLabel="تعديل صورة الإجابة الثالثة"
                answerImage={thirdAnswerImage}
                setAnswerImage={setThirdAnswerImage}
                noteText={thirdNoteText}
                setNoteText={setThirdNoteText}
                noteImage={thirdNoteImage}
                setNoteImage={setThirdNoteImage}
                noteLink={thirdNoteLink}
                setNoteLink={setThirdNoteLink}
                label={thirdAnswerLabel}
                addNoteText="تعديل أو إضافة ملاحظة"
                trueAnswer={trueAnswer}
              />
              <Answer
                answerText={fourthAnswerText}
                setAnswerText={setFourthAnswerText}
                uploadImageLabel="تعديل صورة الإجابة الرابعة"
                answerImage={fourthAnswerImage}
                setAnswerImage={setFourthAnswerImage}
                noteText={fourthNoteText}
                setNoteText={setFourthNoteText}
                noteImage={fourthNoteImage}
                setNoteImage={setFourthNoteImage}
                noteLink={fourthNoteLink}
                setNoteLink={setFourthNoteLink}
                label={fourthAnswerLabel}
                addNoteText="تعديل أو إضافة ملاحظة"
                trueAnswer={trueAnswer}
              />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            style={{ ...styles.questionButton, marginTop: '5%' }}
            onClick={() => {
              if (
                !selectedQuestionSubject ||
                !selectedQuestionTerm ||
                !selectedQuestionUnit ||
                !selectedLesson ||
                !questionText ||
                !firstAnswerText ||
                !secondAnswerText ||
                !thirdAnswerText ||
                !fourthAnswerText ||
                !trueAnswer
              ) {
                return;
              }
              setModalVisible(true);
              setModalTitle("تنبيه!");
              setModaContent(`هل أنت متأكد من تعديل هذا السؤال؟`);
              setShowConfirmButton(true);
            }}
          >
            أرسل
          </Button>
          <CustomModal
            visible={modalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setModalVisible(false)}
            onCancel={() => {
              setModalVisible(false);
              if (resetPhoto) {
                //setFirstAnswerImage(null)
              };
            }}
            onConfirm={handleQuestionSubmit}
            loading={loading}
          />
          <Box style={{ ...styles.questionTitleContainer, marginTop: '10%' }}>
            <Edit style={styles.icon} />
            <Typography style={styles.title}>نقل سؤال</Typography>
          </Box>
          <hr style={{ marginTop: '1%' }} />
          <Grid container>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedGradeForTransfer}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedGradeForTransfer(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedSubjectForTransfer}
                onChange={(e) => {
                  setSelectedSubjectForTransfer(e.target.value);
                  fetchTerms(e.target.value);
                }}
              >
                {subjects.map((subject) => {
                  if (selectedGradeForTransfer !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedTermForTransfer}
                onChange={(e) => {
                  setSelectedTermForTransfer(e.target.value);
                  fetchUnits(e.target.value);
                }}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الوحدة</Typography>
              <Select
                placeholder="اختر الوحدة"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedUnitForTransfer}
                onChange={(e) => {
                  setSelectedUnitForTransfer(e.target.value);
                  fetchLessons(e.target.value, false);
                }}
              >
                {units.map((unit) => (
                  <MenuItem value={unit}>
                    {unit.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الدرس المراد النقل منه (عدد الأسئلة: <span style={{ color: 'red' }}>{questions.length}</span>)</Typography>
              <Select
                placeholder="اختر الدرس"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedFromLessonForTransfer}
                onChange={(e) => {
                  setSelectedFromLessonForTransfer(e.target.value);
                  fetchQuestions(e.target.value, false);
                }}
              >
                {lessons.map((lesson) => (
                  <MenuItem value={lesson._id}>
                    {lesson.lessonName}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الدرس المراد النقل إليه (عدد الأسئلة: <span style={{ color: 'red' }}>{numTransferedQuestions}</span>)</Typography>
              <Select
                placeholder="اختر الدرس"
                variant="outlined"
                style={styles.select}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                value={selectedToLessonForTransfer}
                onChange={(e) => {
                  setSelectedToLessonForTransfer(e.target.value);
                  getNumberOfTransferedQuestions(e.target.value);
                }}
              >
                {lessons.map((lesson) => (
                  <MenuItem value={lesson._id}>
                    {lesson.lessonName}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر السؤال المراد نقله</Typography>
              <Select
                placeholder="اختر السؤال"
                variant="outlined"
                style={{ ...styles.select, direction: 'ltr' }}
                value={selectedQuestionForTransfer}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedQuestionForTransfer(e.target.value);
                }}
              >
                {questions.map((question, index) => (
                  <MenuItem value={question._id}>
                    <Box style={{ display: 'flex' }}>
                      {index + 1}. <div dangerouslySetInnerHTML={{ __html: question.questionText }} />
                    </Box>
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            style={selectedUnitForTransfer.name !== 'اختبارات نهائية' && numTransferedQuestions >= 50 ? styles.disabledQuestionButton : styles.questionButton}
            disabled={selectedUnitForTransfer.name !== 'اختبارات نهائية' && numTransferedQuestions >= 50 ? true : false}
            onClick={() => {
              if (
                !selectedSubjectForTransfer ||
                !selectedTermForTransfer ||
                !selectedUnitForTransfer ||
                !selectedFromLessonForTransfer ||
                !selectedToLessonForTransfer ||
                !selectedQuestionForTransfer
              ) {
                return;
              }
              setTransferModalVisible(true);
              setModalTitle("تنبيه!");
              setModaContent(`هل أنت متأكد من نقل هذا السؤال؟`);
              setShowConfirmButton(true);
            }}
          >
            أرسل
          </Button>
          <CustomModal
            visible={transferModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setTransferModalVisible(false)}
            onCancel={() => setTransferModalVisible(false)}
            onConfirm={handleTransferQuestionSubmit}
            loading={loading}
          />
        </div>
      </Fade>
    </Container>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1%'
  },
  questionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: isMobile ? '20%' : '7%'
  },
  title: {
    fontSize: '1.3em',
    marginRight: '0.5%'
  },
  icon: {
    color: appColor,
  },
  label: {
    fontSize: '1.2em',
    marginTop: '2%',
    marginBottom: '2%',
    fontWeight: '500'
  },
  ansewrLabel: {
    fontSize: '1.2em',
    // marginTop: '2%',
    marginBottom: '1%',
    fontWeight: '500'
  },
  textField: {
    //marginTop: '2%',
    width: isMobile ? '70%' : '70%',
    backgroundColor: '#FFFFFF'
  },
  answersFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  answersFields: {
    width: isMobile ? '70%' : '90%',
  },
  select: {
    marginTop: '1%',
    width: '70%',
    backgroundColor: '#FFFFFF',
    //direction: 'ltr',
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  noteText: {
    fontSize: '1.1em',
    fontWeight: '500'
  },
  radioButton: {
    color: appColor,
    height: '50%',
    marginTop: '7%'
  },
  button: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'left',
    marginTop: isMobile ? '5%' : '2%',
    width: isMobile ? '30vw' : '10vw',
    marginBottom: '5%'
  },
  noteSubmitButton: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    //float: 'right',
    marginTop: isMobile ? '5%' : '2%',
    width: isMobile ? '30vw' : '10vw',
    //marginBottom: '5%'
  },
  questionButton: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'left',
    marginTop: '5%',
    width: isMobile ? '30vw' : '10vw',
    marginBottom: '3%'
  },
  disabledQuestionButton: {
    float: 'left',
    marginTop: '5%',
    width: isMobile ? '30vw' : '10vw',
    marginBottom: '3%'
  }
};

const useStyles = makeStyles(theme => ({
  noteBox: {
    boxShadow: theme.shadows[3],
    padding: '2%',
    marginBlock: '2%'
  },
  uploadButton: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  }
}))

export default EditQuestion;