import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Button,
  Fade,
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { TextField, inputLabelClasses } from '@mui/material'
import CustomModal from '../components/CustomModal';
import { Context as TeacherContext } from '../context/TeacherContext';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { appColor } from '../common';

const height = window.innerHeight;
const width = window.innerWidth;

const TeacherLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [modalText, setModalText] = useState('');
  const [dialogVisible, setDialogVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const navigate = useNavigate();


  const { state: { teacherToken },  teacherSignin, tryTeacherLocalSignin } = useContext(TeacherContext);

  const signInUser = async () => {
    if (!phoneNumber || !password) {
      setDialogVisible(true);
      setModalText('نسيت إدخال رقم الهاتف أو كلمة المرور');
      return;
    }
    setLoading(true);
    const res = await teacherSignin(phoneNumber, password);
    if (res.error) {
      setLoading(false);
      setDialogVisible(true);
      setModalText('خطأ في رقم الهاتف أو كلمة المرور ، يرجى المحاولة مرة أخرى');
    } else {
      navigate('/add_question')
    }
  };

  useEffect(() => {
    async function localLogin() {
      setLoading(true);
      await tryTeacherLocalSignin(navigate, '/add_question', '/teacherarea');
      setLoading(false);
    }

    localLogin();
  }, []);

  if (teacherToken) {
    return null;
  }

  return (
    <Container className={classes.container}>
      <Fade timeout={1000} in={true}>
        <div>
          <Box className={classes.box}>
            <img
              src={require('../images/logo.png')}
              alt="logo"
              className={isMobileOnly ? classes.logoMobile : classes.logo}
            />
            <Typography className={classes.title}>تسجيل دخول المعلم</Typography>
            <TextField
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              style={styles.textField}
              id="outlined-basic"
              type="number"
              label='رقم الهاتف'
              variant="outlined"
              color="primary"
              InputProps={{
                style: { fontFamily: 'tajawal' },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'tajawal',
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: appColor
                  }
                },
              }}
            />
            <TextField
              value={password}
              onChange={e => setPassword(e.target.value)}
              style={styles.textField}
              id="outlined-basic"
              label='كلمة المرور'
              variant="outlined"
              color="primary"
              type="password"
              InputProps={{
                style: { fontFamily: 'tajawal' },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'tajawal',
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: appColor
                  }
                },
              }}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  signInUser();
                  ev.preventDefault();
                }
              }}
            />
            <Button
              type="submit"
              variant="contained"
              className={isMobileOnly ? classes.mobileButton : classes.button}
              onClick={signInUser}
            >
              تسجيل الدخول {loading && <CircularProgress size={20} style={{ color: '#FFFFFF', marginRight: '2%' }} />}
            </Button>
          </Box>
        </div>
      </Fade>
      <CustomModal
        title='تنبيه!'
        content={modalText}
        visible={dialogVisible}
        onCancel={() => setDialogVisible(false)}
        showConfirmButton={false}
        englishCloseText="Close"
        arabicCloseText="إغلاق"
      />
    </Container>
  );
};

const styles = {
  textField: {
    width: isMobile ? '75%' : '35%',
    marginTop: isMobile ? '5%' : '2%',
    backgroundColor: '#FFFFFF'
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    height: height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'rtl'
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[3],
    height: height / 1.2,
    width: width / 1.05
  },
  logo: {
    alignSelf: 'center',
    objectFit: 'contain',
    width: width / 8
  },
  logoMobile: {
    alignSelf: 'center',
    width: width / 1.5,
    objectFit: 'contain',
    height: height / 5
  },
  title: {
    marginTop: '2%',
    fontSize: '1.5em'
  },
  textField: {
    width: '35%',
    marginTop: '2%'
  },
  mobileTextField: {
    width: '80%',
    marginTop: '5%'
  },
  notchedOutline: {
    border: '1px solid',
    borderColor: '#E77813 !important',
    borderRadius: 5
  },
  button: {
    width: '30%',
    marginTop: '2%',
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  mobileButton: {
    width: '50%',
    marginTop: '5%',
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  }
}));

export default TeacherLogin;