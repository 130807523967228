import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Button,
  Fade,
  Box,
  CircularProgress,
  Typography,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { TextField, inputLabelClasses } from '@mui/material'
import CustomModal from '../components/CustomModal';
import { Context as StudentsContext } from '../context/StudentsContext';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { appColor, windowWidth } from '../common';

const height = window.innerHeight;
const width = window.innerWidth;

const LoginScreen = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const classes = useStyles();

  const navigate = useNavigate();

  const { checkUserCredentials } = useContext(StudentsContext);

  const handleSignIn = async () => {
    if (!phoneNumber || !password) {
      setModalVisible(true);
      setModalText('نسيت إدخال رقم الهاتف أو كلمة المرور');
      return;
    } else {
      setLoading(true);
      const { error } = await checkUserCredentials(phoneNumber, password);
      if (error) {
        setModalVisible(true);
        setModalText('رقم الهاتف أو كلمة المرور خاطئة ، يرجى المحاولة مرة أخرى');
      } else {
        navigate('/verification', { state: { type: 'signin', phoneNumber, password } });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Box>
      <Container className={classes.container}>
        <Fade timeout={1000} in={true}>
          <div>
            <Box className={classes.box}>
              <img
                src={require('../images/logo.png')}
                alt="logo"
                className={isMobileOnly ? classes.logoMobile : classes.logo}
              />
              <Typography className={classes.siginInText}>تسجيل الدخول</Typography>
              <Box style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: isMobile ? '80%' : '30%'
              }}>
                <TextField
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  style={styles.textField}
                  id="outlined-basic"
                  type="number"
                  label='رقم الهاتف'
                  variant="outlined"
                  color="primary"
                  InputProps={{
                    style: { fontFamily: 'tajawal' },
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'tajawal',
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: appColor
                      }
                    },
                  }}
                />
                <TextField
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  style={styles.textField}
                  id="outlined-basic"
                  label='كلمة المرور'
                  variant="outlined"
                  color="primary"
                  type={showPassword ? 'text' : "password"}
                  InputProps={{
                    style: { fontFamily: 'tajawal' },
                    classes: {
                      notchedOutline: classes.notchedOutline
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>

                    )
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'tajawal',
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: appColor
                      }
                    },
                  }}
                  onKeyPress={ev => {
                    if (ev.key === 'Enter') {
                      handleSignIn();
                      ev.preventDefault();
                    }
                  }}
                />
                <a className={classes.forgotLink} href='/forgot-password'>نسيت كلمة المرور؟</a>
              </Box>
              <Button
                type="submit"
                variant="contained"
                className={isMobileOnly ? classes.mobileButton : classes.button}
                onClick={handleSignIn}
              >
                تسجيل الدخول {loading && <CircularProgress size={20} style={{ color: '#FFFFFF', marginRight: '2%' }} />}
              </Button>
              <Typography className={classes.noAccountText}>
                ليس لديك حساب؟ <a className={classes.signUpLink} href='/sign-up'>سجل الآن</a>
              </Typography>
            </Box>
          </div>
        </Fade>
        <CustomModal
          title='تنبيه!'
          content={modalText}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          showConfirmButton={false}
          loading={modalLoading}
          englishCloseText="Close"
          arabicCloseText="إغلاق"
        />
      </Container>
    </Box>
  );
};

const styles = {
  textField: {
    width: isMobile ? '100%' : '100%',
    marginTop: isMobile ? '5%' : '2%',
    backgroundColor: '#FFFFFF'
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: height / 1.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'rtl'
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBlock: '3%',
    boxShadow: theme.shadows[3],
    padding: '2%',
    paddingBlock: isMobile ? '15%' : '4%',
    width: isMobile ? windowWidth / 1.1 : windowWidth / 1.2,
    backgroundColor: '#FFFFFF'
  },
  logo: {
    alignSelf: 'center',
    objectFit: 'contain',
    width: windowWidth / 13
  },
  logoMobile: {
    alignSelf: 'center',
    //width: width / 1.5,
    objectFit: 'contain',
    height: height / 8
  },
  siginInText: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBlock: '1%'
  },
  textField: {
    width: '100%',
    marginTop: 10,
    backgroundColor: '#FFFFFF'
  },
  mobileTextField: {
    width: '80%',
    marginTop: '5%'
  },
  notchedOutline: {
    border: '1px solid',
    borderColor: '#E77813 !important',
    borderRadius: 5,
  },
  button: {
    width: '30%',
    marginTop: '1%',
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  mobileButton: {
    width: '50%',
    marginTop: '5%',
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  noAccountText: {
    fontSize: '1.1em',
    marginTop: '1%'
  },
  signUpLink: {
    textDecorationLine: 'none',
    color: appColor,
    '&:hover': {
      color: appColor
    }
  },
  forgotLink: {
    textDecorationLine: 'none',
    color: appColor,
    marginTop: '2%',
    alignSelf: 'flex-end',
    '&:hover': {
      color: appColor
    }
  }
}));

export default LoginScreen;
