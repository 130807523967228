import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Typography
} from "@material-ui/core";
import { TextField, Select } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { Context as AdminContext } from '../context/AdminContext';
import { Context as SubjectContext } from '../context/SubjectContext';
import { Context as TermContext } from '../context/TermContext';
import { Context as CodeContext } from '../context/CodeContext';
import { Check, FileCopy, LockOpen } from "@material-ui/icons";
import { appColor } from "../common";
import { v1 as uuidv1 } from 'uuid'
import CustomModal from "../components/CustomModal";

const GenerateCode = () => {
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedTermSubject, setSelectedTermSubject] = useState('');
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [codeText, setCodeText] = useState([]);
  const [note, setNote] = useState('');
  const [clipboardCheckedText, setClipboardCheckedText] = useState('')
  const [clipboardCheckedIcon, setClipboardCheckedIcon] = useState('');
  const [numberOfCodes, setNumberOfCodes] = useState(1);

  const [modalVisible, setModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const { state: { subjects }, fetchSubjects } = useContext(SubjectContext);
  const { state: { terms }, fetchTerms } = useContext(TermContext);
  const { checkDuplicateCode, saveCodeInDb } = useContext(CodeContext);
  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSubjectsData() {
      await fetchSubjects();
    }

    tryAdminLocalSignin(navigate, '/code-generation')
    if (!subjects.length) fetchSubjectsData()
  }, []);

  function generateRandomCode(length) {
    let result = '';
    const characters = '0123456789' //'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const handleGenerateCode = async () => {
    setLoading(true)
    const code = generateRandomCode(10);

    let duplicate = true;
    while (duplicate) {
      try {
        duplicate = await checkDuplicateCode(code);
      } catch (err) {
        // error msg
        return;
      }
    }

    try {
      await saveCodeInDb(code, selectedTerms, note, selectedType);
      setLoading(false);
      return code;
    } catch (err) {
      console.log(err);
      setLoading(false);
      return '';
    }
  }

  if (!adminToken) {
    return null;
  }

  return (
    <Container style={{ direction: 'rtl' }}>
      <Fade timeout={1000} in={true}>
        <div>
          <Box style={styles.titleContainer}>
            <LockOpen style={styles.icon} />
            <Typography style={styles.title}>إنشاء كودات</Typography>
          </Box>
          <hr />
          <Grid container>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                value={selectedTermSubject}
                sx={{
                  fontFamily: 'tajawal'
                }}
                onChange={(e) => {
                  setSelectedTermSubject(e.target.value);
                  setSelectedTerms([]);
                  setSelectedType(null);
                  fetchTerms(e.target.value)
                }
                }
              >
                {subjects.map((subject) => {
                  if (selectedGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                //value={selectedTerms}
                sx={{
                  fontFamily: 'tajawal'
                }}
                onChange={(e) => {
                  if (e.target.value === 'all') {
                    const allTerms = terms.map(term => term._id);
                    setSelectedTerms(allTerms)
                  } else {
                    setSelectedTerms([e.target.value])
                  }
                }}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
                {terms.length > 1 &&
                  <MenuItem value="all">
                    الفصلان معاً
                  </MenuItem>
                }
              </Select>
            </Grid>
            <Grid style={{ marginTop: '2%' }} item md={6} xs={12}>
              <Typography style={styles.label}>نوع الكود</Typography>
              <Select
                placeholder="نوع الكود"
                variant="outlined"
                style={styles.select}
                value={selectedType}
                sx={{
                  fontFamily: 'tajawal'
                }}
                onChange={e => setSelectedType(e.target.value)}
              >
                {["مجاناً", "مدفوع"].map(type => (
                  <MenuItem value={type}>
                    {type}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid style={{ marginTop: '2%', alignItems: 'center' }} item md={6} xs={12}>
              <Typography style={styles.label}>ملاحظات (اختياري)</Typography>
              <TextField
                variant="outlined"
                placeholder="ملاحظات"
                style={styles.textField}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>
            <Grid style={{ marginTop: '2%', alignItems: 'center' }} item md={6} xs={12}>
              <Typography style={styles.label}>عدد الكودات</Typography>
              <TextField
                variant="outlined"
                placeholder="العدد"
                type="number"
                style={styles.textField}
                value={numberOfCodes}
                onChange={(e) => { if (e.target.value > 0) setNumberOfCodes(e.target.value) }}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>
            <Grid style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }} item md={6} xs={12}>
              <TextField
                variant="outlined"
                placeholder="الكود"
                style={styles.textField}
                //multiline
                disabled
                value={codeText}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
              <IconButton onClick={() => {
                if (codeText.length) {
                  navigator.clipboard.writeText(codeText.join('\n'));
                  setClipboardCheckedIcon(<Check style={{ color: 'green' }} />)
                  setClipboardCheckedText('تم النسخ')
                } else {
                  return;
                }
              }} aria-label="copy">
                <FileCopy style={{ color: appColor }} />
              </IconButton>
              <Typography>{clipboardCheckedText}</Typography> {clipboardCheckedIcon}
            </Grid>
            <Grid style={{ marginTop: '2%' }} item md={6} xs={12}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={async () => {
                  if (!selectedTermSubject || !selectedTerms.length || !selectedType) {
                    setModalVisible(true);
                  } else {
                    setCodeText([]);
                    let codes = [];
                    for (let i = 0; i < numberOfCodes; i++) {
                      const code = await handleGenerateCode();
                      codes.push(code);
                    }
                    setCodeText(codes);
                  }
                }}
              >
                إنشاء {loading && <CircularProgress size={20} style={{ color: '#FFFFFF', marginRight: '10%' }} />}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
      <CustomModal
        visible={modalVisible}
        title={'تنبيه!'}
        content={"يجب اختيار جميع الحقول الموجودة"}
        showConfirmButton={false}
        arabicCloseText="إغلاق"
        onCloseBackDropClick={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      />
    </Container>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2%'
  },
  title: {
    fontSize: '1.3em',
    marginRight: '0.5%'
  },
  icon: {
    color: appColor,
  },
  label: {
    fontSize: '1.2em',
    marginBottom: '1%',
    fontWeight: '500'
  },
  textField: {
    width: '70%',
    backgroundColor: '#FFFFFF',
    color: 'red'
  },
  select: {
    marginTop: '1%',
    width: '70%',
    backgroundColor: '#FFFFFF'
  },
  button: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    marginTop: isMobile ? '5%' : '1%',
    width: isMobile ? '30vw' : '10vw'
  },
  noPopUpText: {
    fontSize: '1.3em',
    textAlign: 'center'
  },
  popUpSubject: {
    fontSize: '1.3em',
    marginBottom: '1%'
  },
  popUpTitle: {
    marginBottom: '2%',
    fontSize: '1.1em'
  },
  popUpContent: {
    fontSize: '1.1em'
  }
};

export default GenerateCode;