import axios from 'axios';
import createDataContext from './createDataContext';
import { delete_images, uploadImage } from './common';

const mainPopupReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_popup':
      return { ...state, popUp: action.payload }
    case 'delete_popup':
      return { ...state, errorMessage: '' };
    case 'reset_popup':
      return { ...state, popUp: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const fetchPopUp = dispatch => async () => {
  try {
    const res = await axios.get('/api/main_popup')
    dispatch({ type: 'fetch_popup', payload: res.data.popUp })
  } catch (err) {
    console.log(err);
  }
}

const addPopUp = dispatch => async (image) => {
  try {
    const { error, imageUrl } = await uploadImage(image, 'popup');
    if (error) {
      throw new Error('Something went wrong while uploading the image to the main pop up');
    }
    await axios.post('/api/main_popup', { image: imageUrl });
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while adding the main pop-up' });
    return { error: 'Something went wrong while adding the main pop-up' };
  }
};

const deletePopUp = dispatch => async (popUpId) => {
  try {
    await axios.delete('/api/main_popup', { data: { popUpId } })
    dispatch({ type: 'delete_popup', payload: popUpId });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong while deleting the main pop-up' });
    return { error: 'Something went wrong while deleting the main pop-up' };
  }
};

const resetPopUp = dispatch => async () => {
  dispatch({ type: 'reset_popup' });
}

export const { Context, Provider } = createDataContext(
  mainPopupReducer,
  {
    fetchPopUp,
    addPopUp,
    deletePopUp,
    resetPopUp
  },
  {
    popUp: []
  }
)