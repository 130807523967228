import axios from 'axios';
import createDataContext from './createDataContext';

const lessonReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_lessons':
      return { ...state, lessons: action.payload };
    case 'fetch_copy_lessons':
      return { ...state, lessonsForCopy: action.payload };
    case 'delete_lesson':
      return { ...state, errorMessage: '' };
    case 'reset_lessons':
      return { ...state, lessons: [], lessonsForCopy: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const fetchLessons = dispatch => async (unitId, getActivatedOnly) => {
  try {
    const res = await axios.get('/api/lesson', { params: { unitId, getActivatedOnly } })
    dispatch({ type: 'fetch_lessons', payload: res.data.lessons })
  } catch (err) {
    console.log(err);
  }
}

const fetchLessonsForCopy = dispatch => async (unitId, getActivatedOnly) => {
  try {
    const res = await axios.get('/api/lesson', { params: { unitId, getActivatedOnly } })
    dispatch({ type: 'fetch_copy_lessons', payload: res.data.lessons })
  } catch (err) {
    console.log(err);
  }
}

const addLesson = dispatch => async (lessonName, lessonNumber, unitId) => {
  try {
    await axios.post('/api/lesson', { lessonName, lessonNumber, unitId });
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while adding the lesson' });
    return { error: 'Something went wrong while adding the lesson' };
  }
};

const editLesson =
  dispatch =>
    async (
      selectedLessonId,
      newLessonName,
      newLessonNumber,
      lessonActivated
    ) => {
      try {
        await axios.post('/api/edit/lesson', {
          lessonId: selectedLessonId,
          editedFields: {
            lessonName: newLessonName,
            number: newLessonNumber,
            activated: lessonActivated
          }
        });
        dispatch({
          type: 'edit_lesson',
          payload: {
            _id: selectedLessonId,
            lessonName: newLessonName,
            number: newLessonNumber,
            activated: lessonActivated
          }
        });
      } catch (err) {
        dispatch({
          type: 'error',
          payload: 'Something went wrong while editing the lesson'
        });
        return { error: 'Error in Editing the lesson' };
      }
      return { error: '' };
    };

const deleteLesson = dispatch => async (lessonId) => {
  try {
    await axios.delete('/api/lesson', { data: { lessonId } })
    dispatch({ type: 'delete_lesson', payload: lessonId });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong while deleting the lesson' });
    return { error: 'Something went wrong while deleting the lesson' };
  }
};

const resetLessons = dispatch => async () => {
  dispatch({ type: 'reset_lessons' });
}

export const { Context, Provider } = createDataContext(
  lessonReducer,
  {
    fetchLessons,
    fetchLessonsForCopy,
    addLesson,
    editLesson,
    deleteLesson,
    resetLessons
  },
  {
    lessons: [],
    lessonsForCopy: []
  }
)