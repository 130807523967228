import axios from 'axios';
import createDataContext from './createDataContext';
import { delete_images, delete_pdf, uploadImage, uploadPdf } from './common';

const termReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_terms':
      return { ...state, terms: action.payload }
    case 'delete_term':
      return { ...state, errorMessage: '' };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const fetchTerms = dispatch => async (subjectId) => {
  try {
    const res = await axios.get('/api/term', { params: { subjectId } })
    dispatch({ type: 'fetch_terms', payload: res.data.terms })
  } catch (err) {
    console.log(err);
  }
}

const addTerm = dispatch => async (subjectId, name, image, noteBook) => {
  try {
    const { error, imageUrl } = await uploadImage(image, 'terms');
    let params = { subjectId, name, image: imageUrl };
    if (noteBook) {
      const { errorFile, fileUrl } = await uploadPdf(noteBook, 'terms');
      params = { ...params, noteBook: fileUrl };
      if (errorFile) {
        throw new Error('Something went wrong while uploading the file');
      }
    }
    if (error) {
      throw new Error('Something went wrong while uploading the image');
    }

    await axios.post('/api/term', params);
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while adding the term' });
    return { error: 'Something went wrong while adding the term' };
  }
};

const editTerm =
  dispatch =>
    async (
      selectedTermId,
      newTermName,
      newTermImage,
      newTermNoteBook
    ) => {
      try {
        await axios.post('/api/edit/term', {
          termId: selectedTermId,
          editedFields: {
            name: newTermName
          }
        });
        dispatch({
          type: 'edit_term',
          payload: {
            _id: selectedTermId,
            name: newTermName,
          }
        });
      } catch (err) {
        dispatch({
          type: 'error',
          payload: 'Something went wrong while editing the term'
        });
        return { error: 'Error in Editing the term' };
      }

      if (newTermImage) {
        const { error, imageUrl } = await uploadImage(
          newTermImage,
          `terms`
        );
        if (error) {
          dispatch({
            type: 'error',
            payload: error
          });
          return { error };
        }

        try {
          const edit_image_res = await axios.post('/api/edit/term', {
            termId: selectedTermId,
            editedFields: {
              image: imageUrl
            }
          });
          const delete_res = await delete_images([
            edit_image_res.data.image
          ]);
          if (delete_res.error) {
            dispatch({
              type: 'error',
              payload: delete_res.error
            });
            return { error: delete_res.error };
          }
        } catch (err) {
          dispatch({
            type: 'error',
            payload: 'Something went wrong while editing the term'
          });
          return { error: 'Error in Editing the term' };
        }
      }

      if (newTermNoteBook) {
        const { errorFile, fileUrl } = await uploadPdf(
          newTermNoteBook,
          `terms`
        );
        if (errorFile) {
          dispatch({
            type: 'error',
            payload: errorFile
          });
          return { errorFile };
        }

        try {
          const edit_pdf_res = await axios.post('/api/edit/term', {
            termId: selectedTermId,
            editedFields: {
              noteBook: fileUrl
            }
          });
          const delete_res = await delete_pdf([
            edit_pdf_res.data.noteBook
          ]);
          if (delete_res.errorFile) {
            dispatch({
              type: 'error',
              payload: delete_res.errorFile
            });
            return { errorFile: delete_res.errorFile };
          }
        } catch (err) {
          dispatch({
            type: 'error',
            payload: 'Something went wrong while editing the term'
          });
          return { errorFile: 'Error in Editing the term' };
        }
      }

      return { error: '' };
    };

const deleteTerm = dispatch => async (termId) => {
  try {
    await axios.delete('/api/term', { data: { termId } })
    dispatch({ type: 'delete_term', payload: termId });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong while deleting the term' });
    return { error: 'Something went wrong while deleting the term' };
  }
};

export const { Context, Provider } = createDataContext(
  termReducer,
  {
    fetchTerms,
    addTerm,
    editTerm,
    deleteTerm
  },
  {
    terms: []
  }
)