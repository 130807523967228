import React, { useState, useContext } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import {
  Drawer,
  makeStyles,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  useMediaQuery,
  Collapse,
  Typography
} from '@material-ui/core';
import { Context as TeacherContext } from '../../context/TeacherContext';
import { Context as AdminContext } from '../../context/AdminContext';

import {
  AddCircle,
  Edit,
  Delete,
  ExitToApp,
  Menu,
  ExpandMore,
  ExpandLess,
  Message,
  Subtitles,
  AccessibilityNew,
  LockOpen,
  Visibility,
  NotificationsActive
} from '@material-ui/icons';

const drawerWidth = 240;
const height = window.innerHeight;

const Sidebar = ({ children }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openArticle, setOpenArticle] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { state: { teacherToken, firstName, lastName }, teacherSignout } = useContext(TeacherContext);
  const { state: { adminToken }, adminSignout } = useContext(AdminContext);

  const menuItems = [
    {
      title: 'إضافة',
      icon: <AddCircle className={classes.addIcon} />,
      subs: [
        { subname: 'إضافة مادة . فصل . وحدة', subPath: '/add_subject' },
        { subname: 'إضافة درس . سؤال', subPath: '/add_question' }
      ]
    },
    {
      title: 'تعديل',
      icon: <Edit className={classes.drawerLink} />,
      subs: [
        { subname: 'تعديل مادة . فصل . وحدة', subPath: '/edit_subject' },
        { subname: 'تعديل درس . سؤال', subPath: '/edit_question' }
      ]
    },
    {
      title: 'حذف',
      icon: <Delete className={classes.drawerLink} />,
      path: '/delete_subject'
    },
    {
      title: 'عرض الأسئلة',
      icon: <Visibility className={classes.drawerLink} />,
      path: '/show_questions'
    },
    {
      title: 'إعلان',
      icon: <Message className={classes.drawerLink} />,
      path: '/pop-up'
    },
    {
      title: 'إنشاء كودات',
      icon: <LockOpen className={classes.drawerLink} />,
      path: '/code-generation'
    },
    {
      title: 'تفاصيل كودات',
      icon: <Subtitles className={classes.drawerLink} />,
      path: '/code-details'
    },
    {
      title: 'بيانات الطلاب',
      icon: <AccessibilityNew className={classes.drawerLink} />,
      path: '/students'
    },
    {
      title: 'اشعارات التطبيق',
      icon: <NotificationsActive className={classes.drawerLink} />,
      path: '/notifications'
    },
    {
      title: 'تسجيل الخروج',
      icon: <ExitToApp className={classes.drawerLink} />
    }
  ];

  const teacherMenuItems = [
    {
      title: 'إضافة',
      icon: <AddCircle className={classes.addIcon} />,
      subs: [
        { subname: 'إضافة فصل . وحدة', subPath: '/add_subject' },
        { subname: 'إضافة درس . سؤال', subPath: '/add_question' }
      ]
    },
    {
      title: 'تعديل',
      icon: <Edit className={classes.drawerLink} />,
      subs: [
        { subname: 'تعديل فصل . وحدة', subPath: '/edit_subject' },
        { subname: 'تعديل درس . سؤال', subPath: '/edit_question' }
      ]
    },
    {
      title: 'حذف',
      icon: <Delete className={classes.drawerLink} />,
      path: '/delete_subject'
    },
    {
      title: 'عرض الأسئلة',
      icon: <Visibility className={classes.drawerLink} />,
      path: '/show_questions'
    },
    {
      title: 'تسجيل الخروج',
      icon: <ExitToApp className={classes.drawerLink} />
    }
  ]

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <Menu />
      </IconButton>
      <Drawer
        variant={isMdUp ? 'permanent' : 'temporary'}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className={classes.drawer}
        classes={{ paper: classes.drawerTitle }}
        ModalProps={{ keepMounted: true }}
      >
        <Container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <img
            src={require('../../images/white_logo.png')}
            alt="logo"
            style={{
              objectFit: 'contain',
              width: '40%',
              height: '80%',
              marginBlock: '15%',
              alignSelf: 'center'
            }}
          />
          {teacherToken && <Typography style={{ textAlign: 'center', color: '#FFFFFF', marginBottom: '10%', fontSize: '1.2em' }}>الأستاذ {firstName} {lastName}</Typography>}
        </Container>
        <List className={classes.list}>
          {adminToken ?
            menuItems.map(item => {
              let isOpen;
              if (item.title === 'إضافة') {
                isOpen = openAdd;
              } else if (item.title === 'تعديل') {
                isOpen = openEdit;
              } else if (item.title === 'حذف') {
                isOpen = openDelete;
              }
              return (
                <>
                  <ListItem
                    button
                    key={item.title}
                    onClick={() => {
                      if (item.title === 'إضافة') {
                        setOpenAdd(!openAdd);
                      } else if (item.title === 'تعديل') {
                        setOpenEdit(!openEdit);
                      }
                      else if (item.title === 'تسجيل الخروج') {
                        adminSignout(navigate)
                      }
                      else if (item.path) {
                        handleDrawerToggle();
                        navigate(item.path);
                      }
                    }}
                    className={
                      location.pathname === item.path ? classes.active : null
                    }
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      className={classes.drawerLink}
                      primary={item.title}
                    />
                    {item.subs ? isOpen ? <ExpandLess style={{ color: '#FFFFFF' }} /> : <ExpandMore style={{ color: '#FFFFFF' }} /> : null}
                  </ListItem>
                  {item.subs ? (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <List
                        component="div"
                        disablePadding
                        className={classes.subList}
                      >
                        {item.subs.map(({ subname, subPath }) => (
                          <ListItem
                            onClick={() => {
                              navigate(subPath);
                              handleDrawerToggle();
                            }}
                            button
                            className={classes.nested}
                          >
                            <ListItemText primary={subname} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  ) : null}
                </>
              );
            })
            :
            teacherMenuItems.map(item => {
              let isOpen;
              if (item.title === 'إضافة') {
                isOpen = openAdd;
              } else if (item.title === 'تعديل') {
                isOpen = openEdit;
              } else if (item.title === 'حذف') {
                isOpen = openDelete;
              }
              return (
                <>
                  <ListItem
                    button
                    key={item.title}
                    onClick={() => {
                      if (item.title === 'إضافة') {
                        setOpenAdd(!openAdd);
                      } else if (item.title === 'تعديل') {
                        setOpenEdit(!openEdit);
                      }
                      else if (item.title === 'تسجيل الخروج') {
                        if (teacherToken) {
                          teacherSignout(navigate)
                        }
                      }
                      else if (item.path) {
                        handleDrawerToggle();
                        navigate(item.path);
                      }
                    }}
                    className={
                      location.pathname === item.path ? classes.active : null
                    }
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      className={classes.drawerLink}
                      primary={item.title}
                    />
                    {item.subs ? isOpen ? <ExpandLess style={{ color: '#FFFFFF' }} /> : <ExpandMore style={{ color: '#FFFFFF' }} /> : null}
                  </ListItem>
                  {item.subs ? (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <List
                        component="div"
                        disablePadding
                        className={classes.subList}
                      >
                        {item.subs.map(({ subname, subPath }) => (
                          <ListItem
                            onClick={() => {
                              navigate(subPath);
                              handleDrawerToggle();
                            }}
                            button
                            className={classes.nested}
                          >
                            <ListItemText primary={subname} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  ) : null}
                </>
              );
            })
          }
        </List>
      </Drawer>
      <div style={{ width: '100%' }}>{children}<Outlet /></div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth
  },
  drawerTitle: {
    width: drawerWidth,
    background: '#E77813'
  },
  root: {
    display: 'flex'
  },
  drawerLogo: {
    color: 'white',
    marginTop: '5%'
  },
  list: {
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  },
  active: {
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  drawerLink: {
    color: '#FFFFFF'
  },
  addIcon: {
    color: 'white'
  },
  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    height: height / 20,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: '#000000',
    textAlign: 'right'
  },
  subList: {
    backgroundColor: '#FFFFFF'
  },
}));

export default Sidebar;
