import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Grow,
  Slide,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { appColor, windowHeight, windowWidth } from '../common';
import Lottie from 'react-lottie';
import animationData from '../images/welcome.json';
import { isMobile } from 'react-device-detect';
import { Context as SubjectContext } from '../context/SubjectContext';
import { Context as StudentsContext } from '../context/StudentsContext';
import { Context as MainPopupContext } from '../context/MainPopupContext';
import { Player } from '@lottiefiles/react-lottie-player';
import CustomModal from '../components/CustomModal';
import PopUpModal from '../components/PopupModal';

const AWS_URL = 'https://qsbank-bucket.s3.eu-central-1.amazonaws.com/';

const HomeScreen = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [showPopUp, setShowPopUp] = useState(true);
  const [loading, setLoading] = useState(false);

  const { state: { subjects }, fetchSubjects } = useContext(SubjectContext);
  const { state: { token, _id: studentId }, signout, tryLocalSignin, updateStudentRegisteredTerms, checkStudentAccountExistence } = useContext(StudentsContext);
  const { state: { popUp }, fetchPopUp } = useContext(MainPopupContext);

  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      await fetchSubjects();
      await fetchPopUp();
      setLoading(false);
    }

    fetchData()
    tryLocalSignin(navigate, '/', {}, '/', false, false);
  }, []);

  useEffect(() => {
    async function checkStuedntAccount() {
      const exists = await checkStudentAccountExistence(studentId);
      //console.log(token, studentId, exists);
      if (exists) {
        updateStudentRegisteredTerms();
      } else {
        signout(navigate);
      }
    };

    if (token && studentId) {
      checkStuedntAccount();
    }
  }, [token, studentId]);

  const classes = useStyles();
  return (
    <Box>
      <Container>
        <Grid container style={{ marginTop: '2%' }}>
          <Grid className={classes.gridContainer} item md={6} xs={12}>
            {/* <img src={require('../images/welcome.gif')} alt="welcome" className={classes.welcome} data-mode="video" /> */}
            <Lottie
              options={defaultOptions}
              height={400}
              width={400}
              isClickToPauseDisabled
            />
          </Grid>
          <Grid className={classes.gridContainer} item md={6} xs={12}>
            <Grow in timeout={800}>
              {/* <Typography className={classes.title}>
                بنك الأسئلة بين يديك
              </Typography> */}
              <>
                <img src={require('../images/logo_with_text.png')} className={classes.logo} />
                {!token && <Typography className={classes.title}>بنك الأسئلة بين يديك</Typography>}
              </>
            </Grow>
            <hr style={{ width: '100%' }} />
            <Box className={classes.buttonsContainer}>
              {token ?
                <Grow in>
                  <Typography className={classes.title}>بنك الأسئلة بين يديك</Typography>
                </Grow>
                :
                <>
                  <Grow in>
                    <Button
                      variant="contained"
                      className={classes.signUpButton}
                      onClick={() => navigate('/sign-up')}
                    >
                      تسجيل جديد
                    </Button>
                  </Grow>
                  <Grow in timeout={800}>
                    <Button
                      variant="contained"
                      className={classes.signInButton}
                      onClick={() => navigate('/sign-in')}
                    >
                      تسجيل الدخول
                    </Button>
                  </Grow>
                </>
              }
            </Box>
            {token && <Grow in>
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1%' }}>
                <Button
                  variant="contained"
                  className={classes.codeButton}
                  onClick={() => navigate('/code-request')}
                >
                  اطلب رمز المادة
                </Button>
              </Box>
            </Grow>}
          </Grid>
        </Grid>
        <Typography style={{ textAlign: 'center', fontSize: '2em', marginTop: '5%' }}>المواد</Typography>
        <hr />
        <Grid style={{ marginBottom: '5%' }} container>
          {loading && <CircularProgress className={classes.loadingStyle} />}
          <Grid style={{ display: 'flex', justifyContent: 'center' }} item md={6}>
            <Button onClick={() => {
              if (!token) {
                setModalVisible(true);
              } else {
                navigate('/subjects', { state: { grade: 12 } })
              }
            }}>
              <img alt='2007' src={require('../images/2007.png')} className={classes.buttonImage} />
            </Button>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'center' }} item md={6}>
            <Button onClick={() => {
              if (!token) {
                setModalVisible(true);
              } else {
                navigate('/subjects', { state: { grade: 11 } })
              }
            }}>
              <img alt='2007' src={require('../images/2008.png')} className={classes.buttonImage} />
            </Button>
          </Grid>
          {/* {subjects.map((subject) => {
            return (
              <Grow timeout={2000} in={true}>
                <Grid className={classes.gridContainer} item md={4} xs={12}>
                  <Button
                    disabled={subject.activated === false ? true : false}
                    className={classes.subjectButtonContainer}
                    classes={{ root: classes.button, label: classes.label }}
                    onClick={() => {
                      if (!token) {
                        setModalVisible(true);
                      } else {
                        navigate('/terms', {
                          state: { subjectId: subject._id, subjectName: subject.subjectName }
                        });
                      }
                    }}
                  >
                    <Player
                      autoplay
                      loop
                      src={AWS_URL + subject.subjectImage}
                      style={{ height: 250, width: 250, borderRadius: 30 }}
                    />
                    <Typography className={classes.subjectTitle}>{subject.subjectName}</Typography>
                    <Typography className={classes.subjectTitle}>{subject.activated === false ? 'قريباً...' : ''}</Typography>
                  </Button>
                </Grid>
              </Grow>
            )
          })} */}
          {/* <Grid item md={6} xs={12}>
            <img src={require('../images/logo.png')} style={{ width: 0, height: 0, objectFit: 'contain' }} />
          </Grid> */}
        </Grid>
        <CustomModal
          title='تنبيه!'
          content="يرجى تسجيل الدخول لتتمكن من التسجيل في المواد"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          showConfirmButton
          //loading={loading}
          onConfirm={() => navigate('/sign-in')}
          arabicConfirmText="تسجيل الدخول"
          arabicCloseText="إغلاق"
        />
        {popUp.length ? <PopUpModal
          visible={showPopUp && popUp.length}
          onClose={() => setShowPopUp(false)}
          content={popUp[0].content}
          img={AWS_URL + popUp[0].image}
        /> : null}
      </Container>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  loadingStyle: {
    alignSelf: 'center',
    position: 'absolute',
    left: '50%',
    color: appColor,
    zIndex: 333
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '2em',
    fontFamily: 'tajawal',
    textAlign: 'center'
  },
  logo: {
    height: windowHeight / 3,
    objectFit: 'contain',
    //border: '1px solid',
    //flexGrow: 1
  },
  welcome: {
    objectFit: 'contain',
    height: windowHeight / 1.5,
    marginTop: '2%'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '5%'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  buttonImage: {
    objectFit: 'cover',
    borderRadius: 10,
    height: '80%',
    width: isMobile ? '95%' : windowWidth / 3
  },
  signUpButton: {
    width: isMobile ? 180 : 250,
    fontSize: '1.2em',
    marginInline: '1%',
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor
    }
  },
  signInButton: {
    marginInline: '1%',
    fontSize: '1.2em',
    width: isMobile ? 180 : 250,
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor
    }
  },
  codeButton: {
    //marginInline: '1%',
    fontSize: '1.2em',
    width: isMobile ? 180 : 250,
    backgroundColor: appColor,
    marginTop: '2%',
    color: 'white',
    '&:hover': {
      backgroundColor: appColor
    }
  },
  subjectButtonContainer: {
    borderRadius: 15,
    marginBottom: '5%',
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    flexDirection: 'column'
  },
  subjectImage: {
    objectFit: 'cover',
    //height: isMobile ? '100%' : windowHeight / 1.2,
    borderRadius: 15,
    //width: windowWidth / 2.8,
  },
  subjectTitle: {
    fontSize: '1.8em',
  }
}));

export default HomeScreen;