import axios from 'axios';
import createDataContext from './createDataContext';

const adminReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return { ...state, errorMessage: action.payload };
    case 'admin_signin':
      return { ...state, errorMessage: '', adminToken: action.payload }
    case 'admin_signup':
      return { ...state, errorMessage: '', adminToken: action.payload }
    case 'update_admin_info':
      return {
        ...state,
        ...action.payload
      }
    case 'admin_signout':
      return { ...state, errorMessage: '', adminToken: null };
    default:
      return state;
  };
};

async function saveItem(key, value) {
  await window.localStorage.setItem(key, JSON.stringify(value));
}

async function getItem(key) {
  let result = await window.localStorage.getItem(key);
  return JSON.parse(result);
}

async function deleteItem(key) {
  await window.localStorage.removeItem(key);
}

const tryAdminLocalSignin = dispatch => async (navigate, successRoute, failRoute = '/') => {
  const adminToken = await getItem('admin_token');
  if (adminToken) {
    dispatch({ type: 'admin_signin', payload: adminToken });
    //navigate(successRoute);
  } else {
    navigate(failRoute);
  }
};

const adminSignin = dispatch => async (username, password) => {
  try {
    const response = await axios.post('/api/admin/signin', { username, password })
    await saveItem('admin_token', response.data.token);
    await saveItem('admin_user', response.data.user);
    dispatch({ type: 'admin_signin', payload: response.data.token });
    dispatch({ type: 'update_admin_info', payload: response.data.user });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong with sign in' });
    return { error: 'Something went wrong with sign in' };
  }
};

const adminSignout = dispatch => async navigate => {
  await deleteItem('admin_token');
  dispatch({ type: 'admin_signout' });
  navigate('/adminarea');
};

export const { Context, Provider } = createDataContext(
  adminReducer,
  {
    adminSignin,
    tryAdminLocalSignin,
    adminSignout,
  },
  {
    adminToken: window.localStorage.getItem('admin_token')
      ? window.localStorage.getItem('admin_token')
      : null,
    errorMessage: '',
    _id: null,
    username: null
  }
);