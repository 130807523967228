import React, { useState } from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { appColor, windowHeight, windowWidth } from "../common";
import { isMobile } from "react-device-detect";
import Lottie from 'react-lottie';
import animationData from '../images/note.json';
import ImageViewer from 'react-simple-image-viewer';
import { Close } from "@material-ui/icons";

const AWS_URL = 'https://qsbank-bucket.s3.eu-central-1.amazonaws.com/';

const Answer = ({
  key,
  onClick,
  style,
  showNoteBox,
  answerText,
  answerImage,
  textNote,
  imageNote,
  linkNote,
  disabled,
  answerNumber,
  answerTextStyle,
  noteDirection,
  noteTitleText,
  answerTextDirection
}) => {
  const [imageOpened, setImageOpened] = useState(false)
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        key={key}
        variant="contained"
        className={classes.answerButton}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        <Box style={answerTextStyle}>
          <Box style={{ display: 'flex', flexDirection: answerTextDirection }}>
            <Typography className={classes.answerText}>{answerNumber}. </Typography>
            <div dangerouslySetInnerHTML={{ __html: answerText }} style={{ marginBottom: 0 }} className={classes.answerText} />
          </Box>
          {answerImage ?
            <img src={AWS_URL + answerImage} className={classes.imageAnswerStyle} />
            : null}
        </Box>
      </Button>
      {showNoteBox && (textNote || imageNote || linkNote) ?
        <Box style={noteDirection} className={classes.noteBox}>
          {/* <Box style={{border: '1px solid', direction: 'ltr'}}> */}
          <Box style={{ display: 'flex', flexDirection: 'row', width: '16%', alignItems: 'center' }}>
            <Lottie
              options={defaultOptions}
              height={80}
              width={110}
              isClickToPauseDisabled
            />
            <Typography className={classes.textNoteTitle}>{noteTitleText}</Typography>
          </Box>
          <Typography className={classes.textNoteStyle}>{textNote}</Typography>
          {imageNote ?
            <img
              src={AWS_URL + imageNote}
              onClick={() => setImageOpened(true)}
              alt="note"
              className={classes.imageNoteStyle}
            />
            : null}
          {/* <Typography className={classes.linkNoteStyle}>{linkNote}</Typography> */}
          {linkNote ?
            <div style={{ display: 'flex', justifyContent: 'center', background: '#FFFFFF' }}>
              <iframe
                title="youtube"
                className={classes.iframeStyle}
                allowFullScreen
                src={linkNote}
              />
            </div>
            : null}
        </Box>
        // </Box>
        : null}
      {imageOpened && (
        <ImageViewer
          src={[AWS_URL + imageNote]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={() => setImageOpened(false)}
          backgroundStyle={{ position: 'fixed', zIndex: 3333 }}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  answerButton: {
    // marginBottom: '2%',
    // justifyContent: 'flex-start',
    // backgroundColor: '#FFFFFF',
    // width: '100%',
    // padding: '1%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: appColor,
      color: '#FFFFFF'
    }
  },
  answerText: {
    textAlign: 'right',
    fontSize: '1.2em',
    fontWeight: 'bold',
    paddingLeft: '0.5%'
  },
  imageAnswerStyle: {
    width: 'auto',
    height: 'auto',
    maxWidth: '40%',
    maxHeight: '40%',
    objectFit: 'contain',
    alignSelf: 'center',
    marginBottom: '5%',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  noteBox: {
    boxShadow: theme.shadows[3],
    padding: '1%',
    marginBottom: '2%',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    width: '80%',
    alignSelf: 'center',
    //direction: 'ltr'
  },
  textNoteTitle: {
    textAlign: 'right',
    fontSize: '1.5em',
    fontWeight: 'bold',
    marginBottom: '2%'
  },
  textNoteStyle: {
    //textAlign: 'right',
    fontSize: '1em',
    fontWeight: 'bold',
    marginBottom: '2%'
  },
  imageNoteStyle: {
    width: 'auto',
    height: 'auto',
    maxWidth: '30%',
    maxHeight: '30%',
    objectFit: 'contain',
    alignSelf: 'center',
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  linkNoteStyle: {
    textAlign: 'right',
    fontSize: '1em',
    //marginBlock: '2%'
  },
  iframeStyle: {
    width: isMobile ? '100%' : windowWidth / 2,
    height: isMobile ? windowHeight / 4 : windowHeight / 2,
    borderRadius: 10,
    backgroundColor: '#FFFFFF'
  }
}));

export default Answer;