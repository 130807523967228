import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Grid, Grow, Typography, makeStyles } from "@material-ui/core";
import { useNavigate, useLocation } from 'react-router-dom';
import { Context as SubjectContext } from '../context/SubjectContext';
import { Context as StudentsContext } from '../context/StudentsContext';
import { Player } from '@lottiefiles/react-lottie-player';
import { appColor } from "../common";
import { isMobile } from "react-device-detect";

const AWS_URL = 'https://qsbank-bucket.s3.eu-central-1.amazonaws.com/';

const SubjectsScreen = () => {
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const { state: { subjects }, fetchSubjects } = useContext(SubjectContext);
    const { state: { token }, tryLocalSignin } = useContext(StudentsContext);

    const navigate = useNavigate();
    const location = useLocation();

    let grade;
    if (!location.state)
        navigate('/');
    else {
        grade = location.state.grade;
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        async function fetchData() {
            await fetchSubjects();
            setLoading(false);
        }
        fetchData();
        tryLocalSignin(navigate, '/subjects');
    }, []);

    const classes = useStyles();
    return (
        <Container style={{ marginTop: '3%' }}>
            <Typography className={classes.title}>المواد</Typography>
            <hr />
            <Grid container>
                {subjects.map((subject) => {
                    if (subject.grade !== grade) return;
                    return (
                        <Grid className={classes.gridContainer} item md={4} xs={12}>
                            <Button
                                disabled={subject.activated === false ? true : false}
                                className={classes.subjectButtonContainer}
                                classes={{ root: classes.button, label: classes.label }}
                                onClick={() => {
                                    if (!token) {
                                        setModalVisible(true);
                                    } else {
                                        navigate('/terms', {
                                            state: { subjectId: subject._id, subjectName: subject.subjectName }
                                        });
                                    }
                                }}
                            >
                                <Player
                                    autoplay
                                    loop
                                    src={AWS_URL + subject.subjectImage}
                                    style={{ height: 250, width: 250, borderRadius: 30 }}
                                />
                                <Typography className={classes.subjectTitle}>{subject.subjectName}</Typography>
                                <Typography className={classes.subjectTitle}>{subject.activated === false ? 'قريباً...' : ''}</Typography>
                            </Button>
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '1.5em',
        textAlign: 'center'
    },
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //flexDirection: 'column',
        marginBottom: '5%'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    signUpButton: {
        width: isMobile ? 180 : 250,
        fontSize: '1.2em',
        marginInline: '1%',
        backgroundColor: appColor,
        color: 'white',
        '&:hover': {
            backgroundColor: appColor
        }
    },
    signInButton: {
        marginInline: '1%',
        fontSize: '1.2em',
        width: isMobile ? 180 : 250,
        backgroundColor: appColor,
        color: 'white',
        '&:hover': {
            backgroundColor: appColor
        }
    },
    codeButton: {
        //marginInline: '1%',
        fontSize: '1.2em',
        width: isMobile ? 180 : 250,
        backgroundColor: appColor,
        marginTop: '2%',
        color: 'white',
        '&:hover': {
            backgroundColor: appColor
        }
    },
    subjectButtonContainer: {
        borderRadius: 15,
        marginBottom: '5%',
        display: 'flex',
        flexDirection: 'column'
    },
    label: {
        flexDirection: 'column'
    },
    subjectImage: {
        objectFit: 'cover',
        //height: isMobile ? '100%' : windowHeight / 1.2,
        borderRadius: 15,
        //width: windowWidth / 2.8,
    },
    subjectTitle: {
        fontSize: '1.8em',
    }
}))

export default SubjectsScreen;