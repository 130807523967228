import React, { useEffect } from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@mui/material';
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import SignUpScreen from "./screens/SignUpScreen";
import VerificationScreen from "./screens/VerificationScreen";
import SubjectsScreen from "./screens/SubjectsScreen";
import TermsScreen from "./screens/TermsScreen";
import UnitsScreen from "./screens/UnitsScreen";
import LessonsScreen from "./screens/LessonsScreen";
import QuestionsScreen from "./screens/QuestionsScreen";
import CodeRequestScreen from "./screens/CodeRequestScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import ErrorScreen from "./screens/ErrorScreen";

import GenerateCode from "./admin/GenerateCode";
import CodesDetails from "./admin/CodesDetails";
import AdminLogin from "./admin/AdminLogin";
import TeacherLogin from "./admin/TeacherLogin";
import AddSubject from "./admin/add/AddSubject";
import AddQuestion from "./admin/add/AddQustion";
import PopUp from "./admin/PopUp";
import StudentsTable from "./admin/StudentsTable";
import Notifications from "./admin/Notifications";

import EditSubject from "./admin/edit/EditSubject";
import EditQuestion from "./admin/edit/EditQuestion";

import DeleteSubject from "./admin/delete/DeleteSubject";
import ShowQuestionsScreen from "./admin/ShowQuestionsScreen";

import Sidebar from "./admin/components/Sidebar";

import { Provider as SubjectProvider } from './context/SubjectContext';
import { Provider as TermProvider } from './context/TermContext';
import { Provider as UnitProvider } from './context/UnitContext';
import { Provider as LessonProvider } from './context/LessonContext';
import { Provider as QuestionProvider } from './context/QuestionContext';
import { Provider as TeacherProvider } from './context/TeacherContext';
import { Provider as PopUpProvider } from './context/PopUpContext';
import { Provider as CodeProvider } from './context/CodeContext';
import { Provider as StudentsProvider } from './context/StudentsContext';
import { Provider as OtpProvider } from './context/OtpContext';
import { Provider as AdminProvider } from './context/AdminContext';
import { Provider as MainPopupProvider } from './context/MainPopupContext';
import { Provider as NotificationProvider } from './context/NotificationContext';

import './css/font.css';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import Layout from "./components/Layout";
import { appColor, windowHeight, windowWidth } from "./common";
import { WhatsApp } from "@material-ui/icons";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";

const WhatsAppButton = () =>
  <a href='https://api.whatsapp.com/send?phone=962777912639'>
    <WhatsApp
      style={{
        position: 'fixed',
        bottom: '3%',
        right: '3%',
        height: isMobile ? windowHeight * 0.055 : windowHeight * 0.07,
        width: isMobile ? windowHeight * 0.055 : windowHeight * 0.07,
        overflowY: 'scroll',
        overflowX: 'hidden',
        backgroundColor: appColor,
        borderRadius: isMobile ? (windowHeight * 0.055) / 2 : (windowHeight * 0.07) / 2,
        color: "white",
        zIndex: 222
      }}
    />
  </a>

const App = () => {
  //font
  const THEME = createTheme({
    direction: 'rtl',
    components: {
      MuiTextField: {
        defaultProps: {
          InputProps: { style: { fontFamily: 'tajawal' } },
          InputLabelProps: { sx: { fontFamily: 'tajawal' } }
        }
      },
      MuiSelect: {
        defaultProps: {
          sx: {
            fontFamily: 'tajawal'
          }
        }
      }
    },
    typography: {
      fontFamily: 'tajawal'
    }
  });

  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });


  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={THEME}>
        <TeacherProvider>
          <AdminProvider>
            <MainPopupProvider>
              <OtpProvider>
                <CodeProvider>
                  <StudentsProvider>
                    <NotificationProvider>
                      <PopUpProvider>
                        <SubjectProvider>
                          <TermProvider>
                            <UnitProvider>
                              <LessonProvider>
                                <QuestionProvider>
                                  <Router>
                                    <Routes>
                                      <Route element={<><Layout /><WhatsAppButton /></>}>
                                        <Route path="/" element={<HomeScreen />} />
                                        <Route path="/sign-in" element={<LoginScreen />} />
                                        <Route path="/sign-up" element={<SignUpScreen />} />
                                        <Route path="/verification" element={<VerificationScreen />} />
                                        <Route path="/subjects" element={<SubjectsScreen />} />
                                        <Route path="/terms" element={<TermsScreen />} />
                                        <Route path="/units" element={<UnitsScreen />} />
                                        <Route path="/lessons" element={<LessonsScreen />} />
                                        <Route path="/questions" element={<QuestionsScreen />} />
                                        <Route path="/code-request" element={<CodeRequestScreen />} />
                                        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
                                        <Route path="/change-password" element={<ChangePasswordScreen />} />
                                        <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
                                      </Route>
                                      <Route path="/adminarea" element={<AdminLogin />} />
                                      <Route path="/teacherarea" element={<TeacherLogin />} />
                                      <Route path="/error" element={<ErrorScreen />} />
                                      <Route element={<Sidebar />}>
                                        <Route path="/add_subject" element={<AddSubject />} />
                                        <Route path="/add_question" element={<AddQuestion />} />
                                        <Route path="/edit_subject" element={<EditSubject />} />
                                        <Route path="/edit_question" element={<EditQuestion />} />
                                        <Route path="/delete_subject" element={<DeleteSubject />} />
                                        <Route path="/pop-up" element={<PopUp />} />
                                        <Route path="/code-generation" element={<GenerateCode />} />
                                        <Route path="/code-details" element={<CodesDetails />} />
                                        <Route path="/students" element={<StudentsTable />} />
                                        <Route path="/show_questions" element={<ShowQuestionsScreen />} />
                                        <Route path="/notifications" element={<Notifications />} />
                                      </Route>
                                    </Routes>
                                  </Router>
                                </QuestionProvider>
                              </LessonProvider>
                            </UnitProvider>
                          </TermProvider>
                        </SubjectProvider>
                      </PopUpProvider>
                    </NotificationProvider>
                  </StudentsProvider>
                </CodeProvider>
              </OtpProvider>
            </MainPopupProvider>
          </AdminProvider>
        </TeacherProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
