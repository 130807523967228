import axios from 'axios';
import createDataContext from './createDataContext';

const otpReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const otpRequest = dispatch => async (phoneNumber) => {
  try {
    await axios.post('/api/otp', { phoneNumber });
    return { error: '' };
  } catch (err) {
    return { error: err.response.data };
  }
}

const otpCheck = dispatch => async (phoneNumber, code) => {
  try {
    await axios.post('/api/otp/check', { phoneNumber, code });
    return { error: '' };
  } catch (err) {
    return { error: err.response.data };
  }
}

export const { Context, Provider } = createDataContext(
  otpReducer,
  {
    otpRequest,
    otpCheck
  }
)