import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  makeStyles
} from '@material-ui/core';
import '../css/Navbar.css';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Context as StudentsContext } from '../context/StudentsContext';
import { AccountCircle, ArrowDropDown, ExitToApp } from '@material-ui/icons';
import { appColor } from '../common';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const NavBar = () => {
  const [open, setOpen] = useState(false)
  // const [scrolled, setScrolled] = useState(false);

  const { state: { firstName, lastName, token }, signout } = useContext(StudentsContext);

  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();

  const classes = useStyles();

  const styles = {
    link: {
      color: '#FFFFFF',
      fontSize: '1em',
    }
  }

  return (
    <>
      <Navbar
        style={{
          paddingTop: 0,
          background: 'linear-gradient(to right, #E77813, #FFA34F)',
          paddingBottom: 0
        }}
        fixed="top"
        className={isMobileOnly ? classes.navmobile : classes.nav}
        expand="lg"
      >
        <Container>
          <Navbar.Brand href='/'>
            <img
              src={require('../images/white_logo.png')}
              alt="logo"
              className={classes.logo}
            />
          </Navbar.Brand>
          {/* <Navbar.Toggle
            className={classes.nav}
            aria-controls="navbarScroll"
          />
          <Navbar.Collapse id="navbarScroll"> */}
          <Nav
            navbarScroll
            className={`navbar-nav ms-auto ${classes.navLinks}`}
          >
            <div
              style={{
                display: 'flex',
                //flexDirection: isMobileOnly ? 'column' : 'row',
                alignItems: 'center'
              }}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => setOpen(!open)}
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="menu"
                  ref={anchorRef}
                >
                  <AccountCircle className={classes.accountIcon} />
                </IconButton>
                <Typography
                  onClick={() => setOpen(!open)}
                  className={classes.name}
                >
                  {token ? `${firstName} ${lastName}` : 'تسجيل الدخول'}
                </Typography>
                <ArrowDropDown onClick={() => setOpen(!open)} className={classes.name} style={{ fontSize: '1.1em' }} />
              </Box>
              <Popper anchorEl={anchorRef.current} open={open} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        {token
                          ?
                          <MenuList id="split-button-menu">
                            <MenuItem
                              onClick={() => { signout(navigate); setOpen(false); }}
                            >
                              <ExitToApp style={{ color: appColor, marginInline: '2%' }} /> تسجيل الخروج
                            </MenuItem>
                          </MenuList>
                          :
                          <MenuList id="split-button-menu">
                            <MenuItem
                              onClick={() => {
                                navigate('/sign-in')
                                setOpen(false);
                              }}
                            >
                              <ExitToApp style={{ color: appColor, marginInline: '2%' }} /> تسجيل الدخول
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                navigate('/sign-up')
                                setOpen(false);
                              }}
                            >
                              <ExitToApp style={{ color: appColor, marginInline: '2%' }} /> تسجيل جديد
                            </MenuItem>
                          </MenuList>
                        }
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              {/* <Nav.Link
                  style={styles.link}
                  href="/"
                  className={classes.home}
                >
                  {language === 'ar' ? 'الرئيسة' : 'Home'}
                </Nav.Link> */}
              {/* <Nav.Link
                  style={styles.link}
                  href="#about"
                >
                  {language === 'ar' ? 'نبذة عنا' : 'About us'}
                </Nav.Link>
                <Nav.Link
                  style={styles.link}
                  href="#services"
                >
                  {language === 'ar' ? 'الشروط والقواعد' : 'Terms and conditions'}
                </Nav.Link> */}
              {/* <Nav.Link style={styles.link} href="#contact">
                  {language === 'ar' ? 'تواصل معنا' : 'Contact us'}
                </Nav.Link>
                {token &&
                  <Nav.Link style={styles.link} href="/teacher-lectures">
                    {language === 'ar' ? 'حسابي' : 'My Account'}
                  </Nav.Link>
                } */}
              {/* {token &&
                  <Nav.Link
                    style={styles.link}
                    onClick={() => signout(navigate)}
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    {language === 'ar' ? 'تسجيل الخروج' : 'Sign out'}
                  </Nav.Link>
                } */}
            </div>
          </Nav>
          {/* </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <div className={classes.toolbar}></div>
    </>
  );
};



const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  logo: {
    objectFit: 'contain',
    height: isMobile ? windowHeight / 20 : windowHeight / 11,
    cursor: 'pointer'
  },
  home: {
    fontSize: '1.1em',
    '&:hover': {
      color: 'orange'
    }
    //backgroundColor: 'red'
  },
  cartIcon: {
    color: '#92c83e',
    '&:hover': {
      color: 'black'
    }
  },
  nav: {
    //height: 10,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
    transition: '0.3s ease',
  },
  navMobile: {
    top: 0,
    height: windowHeight / 6,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)',
    transition: '0.3s ease'
  },
  name: {
    color: '#FFFFFF',
    fontSize: '1.1em',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  accountIcon: {
    color: '#FFFFFF', //'#E77813',
    fontSize: '1.4em'
  },
  profileImage: {
    objectFit: 'contain',
    height: windowHeight / 20,
    borderRadius: 100
  },
}));

export default NavBar;
