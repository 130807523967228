import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Fade,
  Box
} from '@material-ui/core';
import { TextField } from '@mui/material'
import { isMobile } from 'react-device-detect';
import CustomModal from "../components/CustomModal";
import { Context as AdminContext } from '../context/AdminContext';
import { Context as NotificationsContext } from '../context/NotificationContext';
import { NotificationsActive } from '@material-ui/icons';
import { appColor } from '../common';

const Notifications = () => {
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationText, setNotificationText] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();

  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);
  const { sendNotification } = useContext(NotificationsContext);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await sendNotification(notificationTitle, notificationText);

    if (res.error) {
      setModalContent('حدث خطأ في إرسال الإشعار ، يرجى المحاولة مرة أخرى');
      setShowConfirmButton(false);
    } else {
      setModalContent('تم إرسال الإشعار بنجاح');
      setShowConfirmButton(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    tryAdminLocalSignin(navigate, '/notifications');
  }, []);

  if (!adminToken) {
    return null;
  }

  return (
    <Container style={{ direction: 'rtl' }}>
      <Box className={classes.titleContainer}>
        <NotificationsActive className={classes.icon} />
        <Typography className={classes.title}>الإشعارات</Typography>
      </Box>
      <Divider />
      <Grid className={classes.container} container>
        <Grid xs={12}>
          <Typography className={classes.label}>عنوان الإشعار</Typography>
          <TextField
            value={notificationTitle}
            onChange={e => setNotificationTitle(e.target.value)}
            placeholder="عنوان الإشعار"
            variant="outlined"
            className={classes.textField}
            InputProps={{ style: { fontFamily: 'tajawal' } }}
            InputLabelProps={{
              sx: {
                fontFamily: 'tajawal'
              }
            }}
          />
        </Grid>
        <Grid xs={12}>
          <Typography style={{ marginTop: '3%' }} className={classes.label}>نص الإشعار</Typography>
          <TextField
            value={notificationText}
            onChange={e => setNotificationText(e.target.value)}
            placeholder="نص الإشعار"
            variant="outlined"
            multiline
            className={classes.textField}
            InputProps={{ style: { fontFamily: 'tajawal' } }}
            InputLabelProps={{
              sx: {
                fontFamily: 'tajawal'
              }
            }}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => {
          if (!notificationTitle || !notificationText) {
            return;
          }
          setModalContent('هل أنت متأكد من إرسال هذا الإشعار؟');
          setShowConfirmButton(true);
          setModalVisible(true);
        }}
      >
        أرسل
      </Button>
      <CustomModal
        title="تنبيه!"
        content={modalContent}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onConfirm={handleSubmit}
        showConfirmButton={showConfirmButton}
        loading={loading}
        arabicConfirmText="نعم"
        arabicCloseText="إغلاق"
      />
    </Container>
  );
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.6em',
    marginBlock: '1%'
  },
  icon: {
    color: appColor,
  },
  container: {
    marginTop: isMobile ? '5%' : '2%'
  },
  label: {
    fontSize: '1.4em',
    marginBottom: '1%'
  },
  textField: {
    //marginTop: '2%',
    width: isMobile ? '70%' : '60%',
    backgroundColor: '#FFFFFF'
  },
  button: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'left',
    marginTop: isMobile ? '5%' : '2%',
    width: isMobile ? '30vw' : '10vw',
    marginBottom: '5%',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
});

export default Notifications;