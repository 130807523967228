export const windowHeight = window.innerHeight;
export const windowWidth = window.innerWidth;
export const appColor = '#E77813';
export const unitsImages = {
  0: 'units/images/exam.jpg',
  1: 'units/images/first_unit.png',
  2: 'units/images/second_unit.png',
  3: 'units/images/third_unit.png',
  4: 'units/images/fourth_unit.png',
  5: 'units/images/fifth_unit.png',
  6: 'units/images/sixth_unit.png',
  7: 'units/images/seventh_unit.png',
  8: 'units/images/eighth_unit.png',
  9: 'units/images/ninth_unit.png',
  10: 'units/images/tenth_unit.png',
};