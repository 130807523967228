import axios from 'axios';
import createDataContext from './createDataContext';
import { delete_images, uploadImage, uploadJson } from './common';

const subjectReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_subjects':
      return { ...state, subjects: action.payload }
    case 'delete_subject':
      return { ...state, errorMessage: '' };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const fetchSubjects = dispatch => async () => {
  try {
    const res = await axios.get('/api/subject')
    dispatch({ type: 'fetch_subjects', payload: res.data.subjects })
  } catch (err) {
    console.log(err);
  }
}

const addSubject = dispatch => async (subjectName, subjectImage, grade) => {
  try {
    const { error, imageUrl } = await uploadJson(subjectImage, 'subjects');
    if (error) {
      throw new Error('Something went wrong while uploading the image');
    }
    await axios.post('/api/subject', { subjectName, subjectImage: imageUrl, grade });
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while adding the subject' });
    return { error: 'Something went wrong while adding the subject' };
  }
};

const editSubject =
  dispatch =>
    async (
      selectedSubjectId,
      newSubjectName,
      newSubjectOrder,
      newSubjectImage
    ) => {
      try {
        await axios.post('/api/edit/subject', {
          subjectId: selectedSubjectId,
          editedFields: {
            subjectName: newSubjectName,
            order: newSubjectOrder
          }
        });
        dispatch({
          type: 'edit_subject',
          payload: {
            _id: selectedSubjectId,
            subjectName: newSubjectName,
            order: newSubjectOrder
          }
        });
      } catch (err) {
        dispatch({
          type: 'error',
          payload: 'Something went wrong while editing the subject'
        });
        return { error: 'Error in Editing the subject' };
      }

      if (newSubjectImage) {
        const { error, imageUrl } = await uploadImage(
          newSubjectImage,
          `subjects`
        );
        if (error) {
          dispatch({
            type: 'error',
            payload: error
          });
          return { error };
        }

        try {
          const edit_image_res = await axios.post('/api/edit/subject', {
            subjectId: selectedSubjectId,
            editedFields: {
              subjectImage: imageUrl
            }
          });
          const delete_res = await delete_images([
            edit_image_res.data.subjectImage
          ]);
          if (delete_res.error) {
            dispatch({
              type: 'error',
              payload: delete_res.error
            });
            return { error: delete_res.error };
          }
        } catch (err) {
          dispatch({
            type: 'error',
            payload: 'Something went wrong while editing the subject'
          });
          return { error: 'Error in Editing the subject' };
        }
      }

      return { error: '' };
    };

const deleteSubject = dispatch => async (subjectId) => {
  try {
    await axios.delete('/api/subject', { data: { subjectId } })
    dispatch({ type: 'delete_subject', payload: subjectId });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong while deleting the subject' });
    return { error: 'Something went wrong while deleting the subject' };
  }
}

export const { Context, Provider } = createDataContext(
  subjectReducer,
  {
    fetchSubjects,
    addSubject,
    editSubject,
    deleteSubject
  },
  {
    subjects: []
  }
)