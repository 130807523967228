import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Fade,
  Grid,
  MenuItem,
  // Select,
  Typography
} from "@material-ui/core";
import { TextField, Select } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { Context as AdminContext } from '../../context/AdminContext';
import { Context as TeacherContext } from '../../context/TeacherContext';
import { Context as SubjectContext } from '../../context/SubjectContext';
import { Context as TermContext } from '../../context/TermContext';
import { Context as UnitContext } from '../../context/UnitContext';
import CustomModal from "../../components/CustomModal";
import { AddCircle } from "@material-ui/icons";
import { appColor, unitsImages } from "../../common";

const AddSubject = () => {
  const [subjects, setSubjects] = useState([]);
  const [subjectName, setSubjectName] = useState('');
  const [subjectImage, setSubjectImage] = useState(null);
  const [subjectModalVisible, setSubjectModalVisible] = useState(false);
  const [resetSubjectPhoto, setResetSubjectPhoto] = useState(false);
  const [selectedSubjectGrade, setSelectedSubjectGrade] = useState('');

  const [selectedTermGrade, setSelectedTermGrade] = useState('');
  const [selectedTermSubject, setSelectedTermSubject] = useState('');
  const [selectedTerm, setSelectedTerm] = useState('');
  const [termName, setTermName] = useState('');
  const [termImage, setTermImage] = useState(null);
  const [termNoteBook, setTermNoteBook] = useState(null);
  const [termModalVisible, setTermModalVisible] = useState(false);
  const [resetTermPhoto, setResetTermPhoto] = useState(false);
  const [resetTermNoteBook, setResetTermNoteBook] = useState(false);

  const [selectedUnitGrade, setSelectedUnitGrade] = useState('');
  const [selectedUnitSubject, setSelectedUnitSubject] = useState('');
  const [unitName, setUnitName] = useState('');
  const [unitNumber, setUnitNumber] = useState(null);
  //const [unitImage, setUnitImage] = useState(null);
  const [unitModalVisible, setUnitModalVisible] = useState(false);
  const [resetUnitPhoto, setResetUnitPhoto] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModaContent] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false)
  const [loading, setLoading] = useState(false);

  const { state: { subjects: allSubjects }, fetchSubjects, addSubject } = useContext(SubjectContext);
  const { state: { terms }, fetchTerms, addTerm } = useContext(TermContext);
  const { addUnit } = useContext(UnitContext);
  const { state: { teacherToken, subjects: teacherSubjects }, tryTeacherLocalSignin } = useContext(TeacherContext);
  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);

  const navigate = useNavigate();

  const validateSubjectImageSize = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 2097152) {
        setModalTitle("تنبيه!")
        setModaContent('يجب أن يكون حجم الصورة أقل من 2 ميجا');
        setSubjectModalVisible(true);
        setShowConfirmButton(false);
        setResetSubjectPhoto(true);
        return;
      }
    });

    setSubjectImage(event.target.files[0]);
  };

  const handleSubjectSubmit = async () => {
    setLoading(true)
    const res = await addSubject(subjectName, subjectImage, selectedSubjectGrade);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setSubjectModalVisible(true);
      setShowConfirmButton(false)
    } else {
      setSubjectModalVisible(true);
      setModalTitle('تمت الإضافة بنجاح');
      setModaContent(`تمت إضافة مادة ${subjectName} بنجاح`);
      setShowConfirmButton(false);
      navigate(0)
    }
    setLoading(false);
  };

  const validateTermImageSize = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalTitle("تنبيه!")
        setModaContent('يجب أن يكون حجم الصورة أقل من 1 ميجا');
        setTermModalVisible(true);
        setShowConfirmButton(false);
        setResetTermPhoto(true);
        return;
      }
    });

    setTermImage(event.target.files[0]);
  };

  const validateTermNoteBookSize = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 5242880) {
        setModalTitle("تنبيه!")
        setModaContent('يجب أن يكون حجم الملف أقل من 5 ميجا');
        setTermModalVisible(true);
        setShowConfirmButton(false);
        setResetTermNoteBook(true);
        return;
      }
    });

    setTermNoteBook(event.target.files[0]);
  };

  const handleTermSubmit = async () => {
    setLoading(true)
    const res = await addTerm(selectedTermSubject, termName, termImage, termNoteBook);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setTermModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setTermModalVisible(true);
      setModalTitle('تمت الإضافة بنجاح');
      setModaContent(`تمت إضافة الفصل بنجاح`);
      setShowConfirmButton(false);
      navigate(0)
    }
    setLoading(false);
  };

  // const validateUnitImageSize = async event => {
  //   event.preventDefault();

  //   Object.values(event.target.files).forEach(imgFile => {
  //     if (imgFile.size > 1048576) {
  //       setModalTitle("تنبيه!");
  //       setModaContent('يجب أن يكون حجم الصورة أقل من 1 ميجا');
  //       setUnitModalVisible(true);
  //       setShowConfirmButton(false);
  //       setResetUnitPhoto(true);
  //       return;
  //     }
  //   });

  //   setUnitImage(event.target.files[0]);
  // };

  const handleUnitSubmit = async () => {
    setLoading(true)
    const unitImage = unitsImages[unitNumber];
    const res = await addUnit(selectedTerm, unitName, unitNumber, unitImage);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setUnitModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setUnitModalVisible(true);
      setModalTitle('تمت الإضافة بنجاح');
      setModaContent(`تمت إضافة الوحدة بنجاح`);
      setShowConfirmButton(false);
      navigate(0);
    }
    setLoading(false);
  };



  useEffect(() => {
    if (adminToken) {
      tryAdminLocalSignin(navigate, '/add_subject');
      setSubjects(allSubjects);
    } else if (teacherToken) {
      tryTeacherLocalSignin(navigate, '/add_subject')
      setSubjects(teacherSubjects);
    } else {
      navigate('/');
    }

    async function fetchSubjectsData() {
      await fetchSubjects();
    }

    if (!subjects.length) fetchSubjectsData()
  }, []);

  useEffect(() => {
    if (adminToken) {
      setSubjects(allSubjects);
    } else if (teacherToken) {
      setSubjects(teacherSubjects);

      if (teacherSubjects.length) {
        setSelectedTermSubject(teacherSubjects[0]._id);
        setSelectedUnitSubject(teacherSubjects[0]._id);
        fetchTerms(teacherSubjects[0]._id);
      }
    }
  }, [allSubjects, teacherSubjects]);

  if (!adminToken && !teacherToken) {
    return null;
  }

  return (
    <Container style={{ direction: 'rtl' }}>
      <Fade timeout={1000} in={true}>
        <div>
          {adminToken &&
            <>
              <Box style={styles.titleContainer}>
                <AddCircle style={styles.icon} />
                <Typography style={styles.title}>إضافة مادة</Typography>
              </Box>
              <hr />
              <Grid container>
                <Grid item md={6} xs={12}>
                  <Typography style={styles.label}>اختر الجيل</Typography>
                  <Select
                    placeholder="اختر الجيل"
                    variant="outlined"
                    style={styles.select}
                    value={selectedSubjectGrade}
                    sx={{
                      fontFamily: 'tajawal'
                    }}
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    onChange={(e) => {
                      setSelectedSubjectGrade(e.target.value);
                    }
                    }
                  >
                    {[11, 12].map((grade) => (
                      <MenuItem value={grade}>
                        {grade}
                      </MenuItem>
                    )
                    )}
                  </Select>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography style={styles.label}>اسم المادة</Typography>
                  <TextField
                    variant="outlined"
                    label="اسم المادة"
                    style={styles.textField}
                    value={subjectName}
                    InputProps={{ style: { fontFamily: 'tajawal' } }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'tajawal'
                      }
                    }}
                    onChange={(e) => setSubjectName(e.target.value)}
                  />
                </Grid>
                <Grid style={styles.gridStyle} item md={6} xs={12}>
                  <Button
                    //className={isMobile ? classes.uploadButton : ''}
                    variant="contained"
                    component="label"
                    color="primary"
                  >
                    تحميل الصورة
                    <input
                      name="photos"
                      type="file"
                      id="files"
                      onChange={event => validateSubjectImageSize(event)}
                      hidden
                    />
                  </Button>
                  <Typography variant="h6">
                    {subjectImage ? 'تم التحميل' : 'الرجاء تحميل الصورة'}
                  </Typography>
                </Grid>
                <Grid style={styles.gridStyle} item md={6} xs={12}>
                  <Button
                    variant="contained"
                    style={styles.button}
                    onClick={() => {
                      if (!subjectName || !subjectImage) {
                        return;
                      }
                      setSubjectModalVisible(true);
                      setModalTitle("تنبيه!");
                      setModaContent(`هل أنت متأكد من إضافة مادة ${subjectName}؟`);
                      setShowConfirmButton(true);
                    }}
                  >
                    أرسل
                  </Button>
                </Grid>
              </Grid>

              <CustomModal
                visible={subjectModalVisible}
                title={modalTitle}
                content={modalContent}
                showConfirmButton={showConfirmButton}
                englishConfirmText="Yes"
                arabicConfirmText="نعم"
                englishCloseText="Close"
                arabicCloseText="إغلاق"
                onCloseBackDropClick={() => setSubjectModalVisible(false)}
                onCancel={() => {
                  setSubjectModalVisible(false)
                  if (resetSubjectPhoto) setSubjectImage(null);
                }}
                onConfirm={handleSubjectSubmit}
                loading={loading}
              />

              <Box style={{ ...styles.titleContainer, marginTop: adminToken ? '5%' : '2%' }}>
                <AddCircle style={styles.icon} />
                <Typography style={styles.title}>إضافة فصل</Typography>
              </Box>
              <hr />
              <Grid container>
                <Grid item md={6} xs={12}>
                  <Typography style={styles.label}>اختر الجيل</Typography>
                  <Select
                    placeholder="اختر الجيل"
                    variant="outlined"
                    style={styles.select}
                    value={selectedTermGrade}
                    sx={{
                      fontFamily: 'tajawal'
                    }}
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    onChange={(e) => {
                      setSelectedTermGrade(e.target.value);
                    }
                    }
                  >
                    {[11, 12].map((grade) => (
                      <MenuItem value={grade}>
                        {grade}
                      </MenuItem>
                    )
                    )}
                  </Select>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography style={styles.label}>اختر المادة</Typography>
                  <Select
                    placeholder="اختر المادة"
                    variant="outlined"
                    style={styles.select}
                    value={selectedTermSubject}
                    sx={{
                      fontFamily: 'tajawal'
                    }}
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    onChange={(e) => setSelectedTermSubject(e.target.value)}
                  >
                    {subjects.map((subject) => {
                      if (selectedTermGrade !== subject.grade) return;
                      return (
                        <MenuItem value={subject._id}>
                          {subject.subjectName}
                        </MenuItem>
                      )
                    }
                    )}
                  </Select>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography style={styles.label}>اسم الفصل</Typography>
                  <TextField
                    variant="outlined"
                    placeholder="اسم الفصل"
                    style={styles.textField}
                    value={termName}
                    InputProps={{ style: { fontFamily: 'tajawal' } }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'tajawal'
                      }
                    }}
                    onChange={(e) => setTermName(e.target.value)}
                  />
                </Grid>
                <Grid style={styles.gridStyle} item md={4} xs={12}>
                  <Button
                    //className={isMobile ? classes.uploadButton : ''}
                    variant="contained"
                    component="label"
                    color="primary"
                  >
                    تحميل الصورة
                    <input
                      name="photos"
                      type="file"
                      id="files"
                      onChange={event => validateTermImageSize(event)}
                      hidden
                    />
                  </Button>
                  <Typography variant="h6">
                    {termImage ? 'تم التحميل' : 'الرجاء تحميل الصورة'}
                  </Typography>
                </Grid>
                <Grid style={styles.gridStyle} item md={4} xs={12}>
                  <Button
                    //className={isMobile ? classes.uploadButton : ''}
                    variant="contained"
                    component="label"
                    color="primary"
                  >
                    تحميل دوسية الفصل
                    <input
                      name="files"
                      type="file"
                      id="files"
                      onChange={event => validateTermNoteBookSize(event)}
                      hidden
                    />
                  </Button>
                  <Typography variant="h6">
                    {termNoteBook ? 'تم التحميل' : 'الرجاء تحميل الدوسية(اختياري)'}
                  </Typography>
                </Grid>
                <Grid style={styles.gridStyle} item md={12} xs={12}>
                  <Button
                    variant="contained"
                    style={styles.button}
                    onClick={() => {
                      if (!selectedTermSubject || !termName || !termImage) {
                        return;
                      }
                      setTermModalVisible(true);
                      setModalTitle("تنبيه!");
                      setModaContent(`هل أنت متأكد من إضافة ${termName}؟`);
                      setShowConfirmButton(true);
                    }}
                  >
                    أرسل
                  </Button>
                </Grid>
              </Grid>

              <CustomModal
                visible={termModalVisible}
                title={modalTitle}
                content={modalContent}
                showConfirmButton={showConfirmButton}
                englishConfirmText="Yes"
                arabicConfirmText="نعم"
                englishCloseText="Close"
                arabicCloseText="إغلاق"
                onCloseBackDropClick={() => setTermModalVisible(false)}
                onCancel={() => {
                  setTermModalVisible(false)
                  if (resetTermPhoto) setTermImage(null);
                  if (resetTermNoteBook) setTermNoteBook(null);
                }}
                onConfirm={handleTermSubmit}
                loading={loading}
              />
            </>
          }

          <Box style={{ ...styles.titleContainer, marginTop: adminToken ? '5%' : '2%' }}>
            <AddCircle style={styles.icon} />
            <Typography style={styles.title}>إضافة وحدة</Typography>
          </Box>
          <hr />
          <Grid container>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedUnitGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedUnitGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                value={selectedUnitSubject}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedUnitSubject(e.target.value);
                  fetchTerms(e.target.value)
                }
                }
              >
                {subjects.map((subject) => {
                  if (selectedUnitGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                value={selectedTerm}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => setSelectedTerm(e.target.value)}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid style={{ ...styles.gridStyle, marginTop: '5%' }} item md={4} xs={12}>
              <TextField
                variant="outlined"
                label="رقم الوحدة"
                type="number"
                style={styles.textField}
                value={unitNumber}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                onChange={(e) => setUnitNumber(e.target.value)}
              />
            </Grid>
            <Grid style={{ ...styles.gridStyle, marginTop: '5%' }} item md={4} xs={12}>
              <TextField
                variant="outlined"
                label="اسم الوحدة"
                style={styles.textField}
                value={unitName}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                onChange={(e) => setUnitName(e.target.value)}
              />
            </Grid>
            {/* <Grid style={{ ...styles.gridStyle, marginTop: '5%' }} item md={4} xs={12}>
              <Button
                //className={isMobile ? classes.uploadButton : ''}
                variant="contained"
                component="label"
                color="primary"
              >
                تحميل الصورة
                <input
                  name="photos"
                  type="file"
                  id="files"
                  onChange={event => validateUnitImageSize(event)}
                  hidden
                />
              </Button>
              <Typography variant="h6">
                {unitImage ? 'تم التحميل' : 'الرجاء تحميل الصورة'}
              </Typography>
            </Grid> */}
            <Grid style={{ ...styles.gridStyle, marginBottom: '3%' }} item md={12} xs={12}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={() => {
                  if (!selectedUnitSubject || !selectedTerm || !unitName || !unitNumber) {
                    return;
                  }
                  setUnitModalVisible(true);
                  setModalTitle("تنبيه!");
                  setModaContent(`هل أنت متأكد من إضافة ${unitName}؟`);
                  setShowConfirmButton(true);
                }}
              >
                أرسل
              </Button>
            </Grid>
          </Grid>

          <CustomModal
            visible={unitModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setUnitModalVisible(false)}
            onCancel={() => {
              setUnitModalVisible(false)
              //if (resetUnitPhoto) setUnitImage(null);
            }}
            onConfirm={handleUnitSubmit}
            loading={loading}
          />
        </div>
      </Fade>
    </Container>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2%'
  },
  title: {
    fontSize: '1.3em',
    marginRight: '0.5%'
  },
  icon: {
    color: appColor,
  },
  gridStyle: {
    marginTop: '2%',
  },
  textField: {
    //marginTop: '2%',
    width: isMobile ? '70%' : '80%',
    backgroundColor: '#FFFFFF'
  },
  label: {
    fontSize: '1.2em',
    marginBottom: '2%',
    fontWeight: '500'
  },
  select: {
    marginTop: '1%',
    width: '70%',
    backgroundColor: '#FFFFFF'
  },
  button: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'left',
    marginTop: isMobile ? '5%' : '1%',
    width: isMobile ? '30vw' : '10vw'
  }
}

export default AddSubject;