import axios from 'axios';
import createDataContext from './createDataContext';

const popUpReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_popup':
      return { ...state, popUp: action.payload }
    case 'delete_popup':
      return { ...state, errorMessage: '' };
    case 'reset_popup':
      return { ...state, popUp: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const fetchPopUp = dispatch => async (termId) => {
  try {
    const res = await axios.get('/api/popup', { params: { termId } })
    dispatch({ type: 'fetch_popup', payload: res.data.popUp })
  } catch (err) {
    console.log(err);
  }
}

const addPopUp = dispatch => async (termId, title, content) => {
  try {
    await axios.post('/api/popup', { termId, title, content });
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while adding the pop-up' });
    return { error: 'Something went wrong while adding the pop-up' };
  }
};

const deletePopUp = dispatch => async (popUpId) => {
  try {
    await axios.delete('/api/popup', { data: { popUpId } })
    dispatch({ type: 'delete_popup', payload: popUpId });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong while deleting the pop-up' });
    return { error: 'Something went wrong while deleting the pop-up' };
  }
};

const resetPopUp = dispatch => async () => {
  dispatch({ type: 'reset_popup' });
}

export const { Context, Provider } = createDataContext(
  popUpReducer,
  {
    fetchPopUp,
    addPopUp,
    deletePopUp,
    resetPopUp
  },
  {
    popUp: []
  }
)