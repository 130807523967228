import axios from 'axios';
import createDataContext from './createDataContext';
import { delete_images, uploadImage } from './common';

const questionReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_questions':
      return { ...state, questions: action.payload }
    case 'fetch_copy_questions':
      return { ...state, questionsForCopy: action.payload };
    case 'fetch_number_transfered_questions':
      return { ...state, numTransferedQuestions: action.payload };
    case 'delete_question':
      return { ...state, errorMessage: '' };
    case 'reset_questions':
      return { ...state, questions: [], questionsForCopy: [], numTransferedQuestions: 0 };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const getImageUrl = async image => {
  if (image) {
    if (image instanceof File) {
      const { imageUrl } = await uploadImage(image, 'questions');
      return imageUrl;
    } else {
      return image;
    }
  } else {
    return null;
  }
}

const getQuestionImagesUrls = dispatch => async (
  questionImage, 
  firstAnswerImage,
  secondAnswerImage,
  thirdAnswerImage,
  fourthAnswerImage,
  firstNoteImage, 
  secondNoteImage, 
  thirdNoteImage, 
  fourthNoteImage
  ) => {
  const questionImageUrl = await getImageUrl(questionImage);
  const firstAnswerImageUrl = await getImageUrl(firstAnswerImage);
  const secondAnswerImageUrl = await getImageUrl(secondAnswerImage);
  const thirdAnswerImageUrl = await getImageUrl(thirdAnswerImage);
  const fourthAnswerImageUrl = await getImageUrl(fourthAnswerImage);
  const firstNoteImageUrl = await getImageUrl(firstNoteImage);
  const secondNoteImageUrl = await getImageUrl(secondNoteImage);
  const thirdNoteImageUrl = await getImageUrl(thirdNoteImage);
  const fourthNoteImageUrl = await getImageUrl(fourthNoteImage);

  return { questionImageUrl, firstAnswerImageUrl, secondAnswerImageUrl, thirdAnswerImageUrl, fourthAnswerImageUrl,
           firstNoteImageUrl, secondNoteImageUrl, thirdNoteImageUrl, fourthNoteImageUrl }
};

const fetchQuestions = dispatch => async (lessonId, shuffle) => {
  try {
    const res = await axios.get('/api/question', { params: { lessonId, shuffle } });
    dispatch({ type: 'fetch_questions', payload: res.data.questions })
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while fetching questions' });
    return { error: 'Something went wrong while fetching questions' };
  }
}

const fetchQuestionsForCopy = dispatch => async (lessonId, shuffle) => {
  try {
    const res = await axios.get('/api/question', { params: { lessonId, shuffle } });
    dispatch({ type: 'fetch_copy_questions', payload: res.data.questions })
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while fetching questions' });
    return { error: 'Something went wrong while fetching questions' };
  }
};

const getNumberOfTransferedQuestions = dispatch => async (lessonId) => {
  try {
    const res = await axios.get('/api/question/num_transfered', { params: { lessonId } });
    dispatch({ type: 'fetch_number_transfered_questions', payload: res.data.numQuestions });
    return { error: '' };
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while fetching the number of questions' });
    return { error: 'Something went wrong while fetching the number of questions' };
  }
};

const transferQuestion = dispatch => async (toLessonId, questionId) => {
  try {
    await axios.post('/api/transfer/question', { toLessonId, questionId });
    return { error: '' };
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while transfering the questions' });
    return { error: 'Something went wrong while transfering the questions' };
  }
};

const addQuestion = dispatch => async questionDetails => {
  try {
    await axios.post('/api/question', { questionDetails });
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while adding the question' });
    return { error: 'Something went wrong while adding the question' };
  }
};

const editQuestion =
  dispatch =>
    async (
      selectedQuestionId,
      questionDetails
    ) => {
      try {
        await axios.post('/api/edit/question', {
          questionId: selectedQuestionId,
          editedFields: questionDetails
        });
      } catch (err) {
        dispatch({
          type: 'error',
          payload: 'Something went wrong while editing the question'
        });
        return { error: 'Error in Editing the question' };
      }

      // if (newSubjectImage) {
      //   const { error, imageUrl } = await uploadImage(
      //     newSubjectImage,
      //     `questions`
      //   );
      //   if (error) {
      //     dispatch({
      //       type: 'error',
      //       payload: error
      //     });
      //     return { error };
      //   }

      //   try {
      //     const edit_image_res = await axios.post('/api/edit/subject', {
      //       subjectId: selectedQuestionId,
      //       editedFields: {
      //         subjectImage: imageUrl
      //       }
      //     });
      //     const delete_res = await delete_images([
      //       edit_image_res.data.subjectImage
      //     ]);
      //     if (delete_res.error) {
      //       dispatch({
      //         type: 'error',
      //         payload: delete_res.error
      //       });
      //       return { error: delete_res.error };
      //     }
      //   } catch (err) {
      //     dispatch({
      //       type: 'error',
      //       payload: 'Something went wrong while editing the subject'
      //     });
      //     return { error: 'Error in Editing the subject' };
      //   }
      // }

      return { error: '' };
};

const deleteQuestion = dispatch => async (questionId) => {
  try {
    await axios.delete('/api/question', { data: { questionId } })
    dispatch({ type: 'delete_question', payload: questionId });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong while deleting the question' });
    return { error: 'Something went wrong while deleting the question' };
  }
};

const resetQuestions = dispatch => async () => {
  dispatch({ type: 'reset_questions' });
}

export const { Context, Provider } = createDataContext(
  questionReducer,
  {
    addQuestion,
    editQuestion,
    getQuestionImagesUrls,
    getNumberOfTransferedQuestions,
    transferQuestion,
    fetchQuestions,
    fetchQuestionsForCopy,
    deleteQuestion,
    resetQuestions
  },
  {
    questions: [],
    questionsForCopy: [],
    numTransferedQuestions: 0
  }
)