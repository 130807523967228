import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Grow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { appColor, windowHeight, windowWidth } from '../common';
import { Context as StudentsContext } from '../context/StudentsContext';
import { Context as TermContext } from '../context/TermContext';
import { isMobile } from 'react-device-detect';
import CustomModal from '../components/CustomModal';
import { Lock } from '@material-ui/icons';

const AWS_URL = 'https://qsbank-bucket.s3.eu-central-1.amazonaws.com/';

const TermsScreen = () => {
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');

  const { state: { token }, tryLocalSignin } = useContext(StudentsContext);
  const { state: { terms }, fetchTerms } = useContext(TermContext);

  const isLandscape = window.matchMedia('(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)').matches;

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  let subjectId, subjectName;

  if (!location.state)
    navigate('/');
  else {
    subjectId = location.state.subjectId;
    subjectName = location.state.subjectName;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    async function fetchData() {
      await fetchTerms(subjectId);
      //await updateStudentRegisteredTerms();
      setLoading(false);
    }

    fetchData();
    tryLocalSignin(navigate, '/terms', { subjectId, subjectName });
  }, []);

  if (!token) {
    return null;
  }

  const landscapeStyles = {
    width: '100%'
  };

  return (
    <Box>
      <Container style={{ marginTop: '3%', direction: 'rtl' }}>
        {loading && <CircularProgress className={classes.loadingStyle} />}
        <Typography className={classes.title}>مادة {subjectName}</Typography>
        <hr />
        <Grid container style={{ minHeight: windowHeight / 1.5 }}>
          {terms.map((term) => (
            <Grow timeout={700} in={true}>
              <Grid className={classes.gridContainer} item md={6} xs={12}>
                <Button
                  onClick={() => {
                    // if (subjectId === '653bbe44a3e06deff21637e4') {
                    //   navigate('/units', {
                    //     state: { subjectId, termId: term._id, termName: term.name, subjectName, noteBook: term.noteBook }
                    //   })
                    // } else if (!registeredTerms.includes(term._id)) {
                    //   navigate('/code-request')
                    // } else {
                    navigate('/units', {
                      state: { subjectId, termId: term._id, termName: term.name, subjectName, noteBook: term.noteBook }
                    })
                    //}
                  }}
                  className={classes.button}
                  style={isLandscape ? landscapeStyles : null}
                >
                  <Box
                    //className={subjectId === '653bbe44a3e06deff21637e4' ? '' : !registeredTerms.includes(term._id) ? classes.blackContainer : ''}
                    style={isLandscape ? { width: '97%' } : null}
                  />
                  <img
                    src={AWS_URL + term.image}
                    alt="صورة الفصل"
                    className={classes.termImage}
                    style={isLandscape ? landscapeStyles : null}
                  />
                  {/* {subjectId === '653bbe44a3e06deff21637e4' ? null : !registeredTerms.includes(term._id) && <Lock className={classes.lockIcon} />} */}
                  {/* <Typography className={classes.termTitle}>{term.name}</Typography> */}
                </Button>
              </Grid>
            </Grow>
          ))}
        </Grid>
        <CustomModal
          title='!لست مسجلاً في هذا الفصل'
          content="يجب التسجيل في الفصل أولاً ، إضغط على زر التسجيل"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          showConfirmButton
          onConfirm={() => navigate('/code-request', {
            state: { subjectId, subjectName }
          })}
          arabicConfirmText="التسجيل"
          arabicCloseText="إغلاق"
        />
      </Container>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  loadingStyle: {
    alignSelf: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: appColor,
    zIndex: 333
  },
  title: {
    textAlign: 'center',
    fontSize: '1.8em'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '5%'
  },
  termContainer: {
    backgroundColor: 'black',
    zIndex: 2,
    position: 'absolute',
    height: windowHeight / 2,
    borderRadius: 15,
    width: windowWidth / 2.8,
    opacity: 0.6
  },
  termImage: {
    objectFit: 'cover',
    height: '100%',
    borderRadius: 15,
    width: isMobile ? windowWidth / 1.2 : '100%',
  },
  button: {
    borderRadius: 15,
    marginBottom: '5%',
    width: '93%'
  },
  blackContainer: {
    backgroundColor: 'black',
    zIndex: 2,
    position: 'absolute',
    height: '97%',
    borderRadius: 15,
    width: isMobile ? windowWidth / 1.2 : '97%',
    opacity: 0.6
  },
  lockIcon: {
    position: 'absolute',
    top: '10%',
    right: '5%',
    color: '#FFFFFF',
    fontSize: '3em',
    zIndex: 3
  }
}));

export default TermsScreen;