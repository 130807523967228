import axios from 'axios';
import createDataContext from './createDataContext';
//import { delete_images, uploadImage } from './common';

const unitReducer = (state, action) => {
  switch (action.type) {
    case 'fetch_units':
      return { ...state, units: action.payload }
    case 'delete_unit':
      return { ...state, errorMessage: '' };
    case 'reset_units':
      return { ...state, units: [] };
    case 'error':
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

const fetchUnits = dispatch => async (termId) => {
  try {
    const res = await axios.get('/api/unit', { params: { termId } })
    dispatch({ type: 'fetch_units', payload: res.data.units })
  } catch (err) {
    console.log(err);
  }
}

const addUnit = dispatch => async (termId, name, number, image) => {
  try {
    const imageUrl = image; //const { error, imageUrl } = await uploadImage(image, 'units');
    // if (error) {
    //   throw new Error('Something went wrong while uploading the image');
    // }
    await axios.post('/api/unit', { termId, name, number, image: imageUrl });
    return { error: '' }
  } catch (err) {
    console.log(err);
    dispatch({ type: 'error', payload: 'Something went wrong while adding the unit' });
    return { error: 'Something went wrong while adding the unit' };
  }
};

const editUnit =
  dispatch =>
    async (
      selectedUnitId,
      newUnitName,
      newUnitNumber,
      newUnitImage
    ) => {
      try {
        await axios.post('/api/edit/unit', {
          unitId: selectedUnitId,
          editedFields: {
            name: newUnitName,
            number: newUnitNumber,
            image: newUnitImage
          }
        });
        dispatch({
          type: 'edit_unit',
          payload: {
            _id: selectedUnitId,
            name: newUnitName,
            number: newUnitNumber,
            image: newUnitImage
          }
        });
      } catch (err) {
        dispatch({
          type: 'error',
          payload: 'Something went wrong while editing the unit'
        });
        return { error: 'Error in Editing the unit' };
      }

      // if (newUnitImage) {
      //   const { error, imageUrl } = await uploadImage(
      //     newUnitImage,
      //     `units`
      //   );
      //   if (error) {
      //     dispatch({
      //       type: 'error',
      //       payload: error
      //     });
      //     return { error };
      //   }

      //   try {
      //     const edit_image_res = await axios.post('/api/edit/unit', {
      //       unitId: selectedUnitId,
      //       editedFields: {
      //         image: imageUrl
      //       }
      //     });
      //     const delete_res = await delete_images([
      //       edit_image_res.data.image
      //     ]);
      //     if (delete_res.error) {
      //       dispatch({
      //         type: 'error',
      //         payload: delete_res.error
      //       });
      //       return { error: delete_res.error };
      //     }
      //   } catch (err) {
      //     dispatch({
      //       type: 'error',
      //       payload: 'Something went wrong while editing the unit'
      //     });
      //     return { error: 'Error in Editing the unit' };
      //   }
      // }

      return { error: '' };
    };

const deleteUnit = dispatch => async (unitId) => {
  try {
    await axios.delete('/api/unit', { data: { unitId } })
    dispatch({ type: 'delete_unit', payload: unitId });
    return { error: '' }
  } catch (err) {
    dispatch({ type: 'error', payload: 'Something went wrong while deleting the unit' });
    return { error: 'Something went wrong while deleting the unit' };
  }
};

const resetUnits = dispatch => async () => {
  dispatch({ type: 'reset_units' });
}

export const { Context, Provider } = createDataContext(
  unitReducer,
  {
    fetchUnits,
    addUnit,
    editUnit,
    deleteUnit,
    resetUnits
  },
  {
    units: []
  }
)