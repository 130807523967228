import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  makeStyles,
  Grid,
  MenuItem,
  Fade,
} from '@material-ui/core';
import { PaginationItem, Select } from '@mui/material'
import Pagination from '@material-ui/lab/Pagination'
import { appColor, windowHeight, windowWidth } from '../common';
import { Context as SubjectContext } from '../context/SubjectContext';
import { Context as TermContext } from '../context/TermContext';
import { Context as UnitContext } from '../context/UnitContext';
import { Context as LessonContext } from '../context/LessonContext';
import { Context as QuestionContext } from '../context/QuestionContext';
import { Context as AdminContext } from '../context/AdminContext';
import { Context as TeacherContext } from '../context/TeacherContext';
import Answer from '../components/Answer';
import { isMobile } from 'react-device-detect';
import { ArrowBackIos, ArrowForwardIos, Visibility } from '@material-ui/icons';
import ImageViewer from 'react-simple-image-viewer';

const AWS_URL = 'https://qsbank-bucket.s3.eu-central-1.amazonaws.com/';

const ShowQuestionsScreen = () => {
  const [subjects, setSubjects] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLessonGrade, setSelectedLessonGrade] = useState('');
  const [selectedLessonSubject, setSelectedLessonSubject] = useState('');
  const [selectedLessonTerm, setSelectedLessonTerm] = useState('');
  const [selectedLessonUnit, setSelectedLessonUnit] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');

  const [questionImageViewer, setQuestionImageViewer] = useState(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 5);
  };

  const { state: { subjects: allSubjects }, fetchSubjects } = useContext(SubjectContext);
  const { state: { terms }, fetchTerms } = useContext(TermContext);
  const { state: { units }, fetchUnits, resetUnits } = useContext(UnitContext);
  const { state: { lessons }, fetchLessons, resetLessons } = useContext(LessonContext);
  const { state: { questions }, fetchQuestions, resetQuestions } = useContext(QuestionContext);
  const { state: { teacherToken, subjects: teacherSubjects }, tryTeacherLocalSignin } = useContext(TeacherContext);
  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);

  const classes = useStyles();
  const navigate = useNavigate();

  const questionsPerPage = 5;
  const pageCount = Math.ceil(questions.length / questionsPerPage);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchSubjectsData() {
      await fetchSubjects();
    }

    if (!subjects.length) fetchSubjectsData();

    fetchSubjectsData();

    if (adminToken) {
      tryAdminLocalSignin(navigate, '/show_questions');
    } else if (teacherToken) {
      tryTeacherLocalSignin(navigate, '/show_questions')
    } else {
      navigate('/');
    }

    return () => {
      resetQuestions();
    }
  }, []);

  useEffect(() => {
    if (adminToken) {
      setSubjects(allSubjects);
    } else if (teacherToken) {
      setSubjects(teacherSubjects);

      if (teacherSubjects.length) {
        setSelectedLessonSubject(teacherSubjects[0]._id);
        fetchTerms(teacherSubjects[0]._id);
      }
    }
  }, [allSubjects, teacherSubjects]);

  const start = (currentPage - 1) * questionsPerPage;
  const end = start + questionsPerPage;
  let questionNumber = start + 1;

  if (!adminToken && !teacherToken) {
    return null;
  }

  return (
    <Box>
      <Container style={{ marginTop: '2%' }}>
        <Fade timeout={1000} in={true}>
          <div>
            {loading && <CircularProgress className={classes.loadingStyle} />}
            <Box style={styles.titleContainer}>
              <Visibility style={styles.icon} />
              <Typography style={styles.title}>عرض الأسئلة</Typography>
            </Box>
            <hr />
            <Grid container style={{ direction: 'rtl' }}>
              <Grid item md={4} xs={12}>
                <Typography style={styles.label}>اختر الجيل</Typography>
                <Select
                  placeholder="اختر الجيل"
                  variant="outlined"
                  style={styles.select}
                  value={selectedLessonGrade}
                  sx={{
                    fontFamily: 'tajawal'
                  }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  onChange={(e) => {
                    setSelectedLessonGrade(e.target.value);
                  }
                  }
                >
                  {[11, 12].map((grade) => (
                    <MenuItem value={grade}>
                      {grade}
                    </MenuItem>
                  )
                  )}
                </Select>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography style={styles.label}>اختر المادة</Typography>
                <Select
                  placeholder="اختر المادة"
                  variant="outlined"
                  style={styles.select}
                  sx={{
                    fontFamily: 'tajawal'
                  }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={selectedLessonSubject}
                  onChange={(e) => {
                    setSelectedLessonSubject(e.target.value);
                    fetchTerms(e.target.value);
                    resetQuestions();
                    setSelectedLesson('');
                    resetLessons();
                    resetUnits();
                  }}
                >
                  {subjects.map((subject) => {
                    if (selectedLessonGrade !== subject.grade) return;
                    return (
                      <MenuItem value={subject._id}>
                        {subject.subjectName}
                      </MenuItem>
                    )
                  }
                  )}
                </Select>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography style={styles.label}>اختر الفصل</Typography>
                <Select
                  placeholder="اختر الفصل"
                  variant="outlined"
                  style={styles.select}
                  sx={{
                    fontFamily: 'tajawal'
                  }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={selectedLessonTerm}
                  onChange={(e) => {
                    setSelectedLessonTerm(e.target.value);
                    fetchUnits(e.target.value);
                    resetQuestions();
                    setSelectedLesson('');
                    resetLessons();
                  }}
                >
                  {terms.map((term) => (
                    <MenuItem value={term._id}>
                      {term.name}
                    </MenuItem>
                  )
                  )}
                </Select>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography style={styles.label}>اختر الوحدة</Typography>
                <Select
                  placeholder="اختر الوحدة"
                  variant="outlined"
                  style={styles.select}
                  sx={{
                    fontFamily: 'tajawal'
                  }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  value={selectedLessonUnit}
                  onChange={(e) => {
                    setSelectedLessonUnit(e.target.value);
                    fetchLessons(e.target.value, false);
                    resetQuestions();
                    setSelectedLesson('');
                    resetLessons();
                  }}
                >
                  {units.map((unit) => (
                    <MenuItem value={unit._id}>
                      {unit.name}
                    </MenuItem>
                  )
                  )}
                </Select>
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography style={styles.label}>اختر الدرس</Typography>
                <Select
                  placeholder="اختر الدرس"
                  variant="outlined"
                  style={styles.select}
                  sx={{
                    fontFamily: 'tajawal'
                  }}
                  MenuProps={{
                    disableScrollLock: true
                  }}
                  onChange={(e) => {
                    setSelectedLesson(e.target.value._id);
                    setLessonName(e.target.value.lessonName);
                    fetchQuestions(e.target.value, false);
                    setCurrentPage(1);
                  }}
                >
                  {lessons.map((lesson) => (
                    <MenuItem value={lesson}>
                      {lesson.lessonName}
                    </MenuItem>
                  )
                  )}
                </Select>
              </Grid>
            </Grid>
            {questions.length ?
              <>
                <Typography className={classes.title}>أسئلة على {lessonName}</Typography>
                <hr />
                {questions.slice(start, end).map(({ questionText, image, answers }, questionIndex) => {
                  const isLast = questions.length - 1 === questionIndex;
                  return (
                    <>
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        {/* <Typography className={classes.questionText}>{questionNumber++}. {questionText}</Typography> */}
                        <Box style={{ display: 'flex', flexDirection: selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'row' : 'row-reverse' }}>
                          <Typography className={classes.questionText}>
                            {selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? `${questionNumber++}.` : `.${questionNumber++}`}
                          </Typography>
                          <div dangerouslySetInnerHTML={{ __html: questionText }} className={classes.questionText} />
                        </Box>
                        {image ?
                          <img
                            src={AWS_URL + image}
                            className={classes.imageQuestionStyle}
                            onClick={() => setQuestionImageViewer(true)}
                          />
                          : null}
                        {questionImageViewer && (
                          <ImageViewer
                            src={[AWS_URL + image]}
                            currentIndex={0}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={() => setQuestionImageViewer(false)}
                            backgroundStyle={{ position: 'fixed', zIndex: 3333 }}
                          />
                        )}
                      </Box>
                      {answers.map((answer, answerIndex) => {
                        let answerBackgroundColor = '#FFFFFF';
                        let answerTextColor = '#000000';
                        let showNoteBox = true;
                        if (answer.isTrue) {
                          answerBackgroundColor = 'green';
                          answerTextColor = '#FFFFFF';
                          showNoteBox = true;
                        }

                        return (
                          <Answer
                            key={answerIndex}
                            style={{
                              backgroundColor: answerBackgroundColor,
                              color: answerTextColor,
                              marginBottom: isMobile ? '5%' : '2%',
                              width: '100%',
                              padding: isMobile ? '2%' : '1%',
                              textTransform: 'none'
                            }}
                            answerTextStyle={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              direction: selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'ltr' : 'rtl'
                            }}
                            arabicDot={selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? '' : '.'}
                            englishDot={selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? '.' : ''}
                            noteDirection={{ direction: selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'ltr' : 'rtl' }}
                            showNoteBox={showNoteBox}
                            answerText={answer.text}
                            answerNumber={
                              answerIndex === 0 ? selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'A' : 'أ' :
                                answerIndex === 1 ? selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'B' : 'ب' :
                                  answerIndex === 2 ? selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'C' : 'ج' :
                                    answerIndex === 3 ? selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'D' : 'د' :
                                      null
                            }
                            answerImage={answer.image}
                            textNote={answer.textNote}
                            imageNote={answer.imageNote}
                            linkNote={answer.linkNote}
                            noteTitleText={selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'Notes:' : 'ملاحظات:'}
                            disabled={true}
                          />
                        )
                      })}
                      {!isLast && <hr />}
                    </>
                  )
                })}
                <Box className={classes.paginationContainer}>
                  <Pagination
                    classes={{ root: classes.ul }}
                    className={classes.paginationStyle}
                    count={pageCount}
                    page={currentPage}
                    style={{ direction: selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? 'ltr' : 'rtl' }}
                    onChange={handlePageChange}
                    scroll
                    renderItem={item => (
                      <PaginationItem
                        components={{ previous: selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? ArrowBackIos : ArrowForwardIos, next: selectedLessonSubject === '660d1aa65b1a4fa77f6dd7ab' ? ArrowForwardIos : ArrowBackIos }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              </>
              : null}
          </div>
        </Fade>
      </Container>
    </Box>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    direction: 'rtl'
  },
  questionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: isMobile ? '20%' : '7%'
  },
  title: {
    fontSize: '1.3em',
    //marginTop: '3%'
  },
  icon: {
    color: appColor,
  },
  label: {
    fontSize: '1.2em',
    marginTop: '2%',
    marginBottom: '2%',
    fontWeight: '500'
  },
  ansewrLabel: {
    fontSize: '1.2em',
    // marginTop: '2%',
    marginBottom: '1%',
    fontWeight: '500'
  },
  textField: {
    //marginTop: '2%',
    width: isMobile ? '70%' : '70%',
    backgroundColor: '#FFFFFF'
  },
  answersFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  answersFields: {
    width: isMobile ? '70%' : '90%',
  },
  select: {
    marginTop: '1%',
    width: '70%',
    backgroundColor: '#FFFFFF'
  },
  noteText: {
    fontSize: '1.1em',
    fontWeight: '500'
  },
  radioButton: {
    color: appColor,
    height: '50%',
    marginTop: '7%'
  },
  button: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'left',
    marginTop: isMobile ? '5%' : '2%',
    width: isMobile ? '30vw' : '10vw',
    marginBottom: '5%'
  },
  noteSubmitButton: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    //float: 'right',
    marginTop: isMobile ? '5%' : '2%',
    width: isMobile ? '30vw' : '10vw',
    //marginBottom: '5%'
  },
  questionButton: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'left',
    marginTop: isMobile ? '5%' : '1%',
    width: isMobile ? '30vw' : '10vw',
    marginBottom: '3%'
  }
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    marginTop: '2%'
  },
  loadingStyle: {
    alignSelf: 'center',
    position: 'fixed',
    top: '50%',
    left: '50%',
    color: appColor,
    zIndex: 333
  },
  questionText: {
    color: appColor,
    fontSize: '1.3em',
    marginBottom: '2%',
    //fontWeight: 'bold'
  },
  imageQuestionStyle: {
    width: 'auto',
    height: 'auto',
    maxWidth: '40%',
    maxHeight: '40%',
    objectFit: 'contain',
    alignSelf: 'center',
    marginBottom: '5%',
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  imageNoteStyle: {
    width: windowWidth / 4,
    height: windowHeight / 4,
    objectFit: 'contain'
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  paginationStyle: {
    marginBottom: isMobile ? '5%' : '2%',
    alignSelf: 'center',
  },
  ul: {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: appColor,
        color: 'white',
        '&:hover': {
          background: appColor,
        }
      },
    },
  },
  finishButton: {
    width: isMobile ? '70%' : '15%',
    backgroundColor: appColor,
    color: '#FFFFFF',
    marginBottom: isMobile ? '5%' : '2%',
    '&:hover': {
      backgroundColor: appColor,
      color: '#FFFFFF',
    }
  }
}));

export default ShowQuestionsScreen;