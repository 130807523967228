import React, { useState } from "react";
import {
  Box,
  Button,
  Fade,
  Grid,
  Radio,
  //TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { TextField } from '@mui/material'
import { isMobile } from "react-device-detect";
import { Add, Close } from "@material-ui/icons";
import { appColor } from "../../common";
import CustomModal from "../../components/CustomModal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ 'align': [] }],
    [{ 'direction': 'rtl' }],
    ['formula']
  ]
};

const formats = [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'script',
  'header', 'blockquote', 'code-block',
  'indent', 'list',
  'direction', 'align',
  'link', 'image', 'video', 'formula',
]

const Answer = ({
  answerText,
  setAnswerText,
  answerImage,
  setAnswerImage,
  uploadImageLabel,
  noteText,
  setNoteText,
  noteImage,
  setNoteImage,
  noteLink,
  setNoteLink,
  label,
  addNoteText,
  trueAnswer,
  answerDirection,
  arabicDot,
  englishDot
}) => {
  const [showAnswerNote, setShowAnswerNote] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModaContent] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false)
  const [resetPhoto, setResetPhoto] = useState(false);

  const classes = useStyles();

  const validateImagesSizes = async (event, setState) => {
    event.preventDefault();

    if (event.target.files[0].size > 1048576) {
      setModalTitle("تنبيه!")
      setModaContent('يجب أن يكون حجم الصورة أقل من 1 ميجا');
      setModalVisible(true);
      setShowConfirmButton(false);
      setResetPhoto(true);
    } else {
      setState(event.target.files[0]);
    }
  };

  return (
    <>
      <Fade timeout={1000} in={true}>
        <div>
          <Grid container style={{ marginTop: '2%' }}>
            <Grid style={{ display: 'flex' }} item md={4}>
              <Radio style={styles.radioButton} checked={trueAnswer === label} value={label} />
              <Box style={styles.answersFieldsContainer}>
                <Typography style={styles.ansewrLabel}>{label}</Typography>
                {/* <TextField
                  variant="outlined"
                  placeholder={label}
                  multiline
                  style={styles.answersFields}
                  value={answerText}
                  InputProps={{ style: { fontFamily: 'tajawal' } }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'tajawal'
                    }
                  }}
                  onChange={(e) => setAnswerText(e.target.value)}
                /> */}
                <ReactQuill
                  theme="snow"
                  //placeholder={label}
                  value={answerText}
                  onChange={setAnswerText}
                  modules={modules}
                  formats={formats}
                  style={{ backgroundColor: '#FFFFFF', maxWidth: '90%', display: 'flex', flexDirection: 'column', direction: 'ltr' }}
                />
              </Box>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center' }} item md={4}>
              <Button
                variant="text"
                startIcon={showAnswerNote ? <Close style={styles.icon} /> : <Add style={styles.icon} />}
                style={styles.noteText}
                onClick={() => setShowAnswerNote(!showAnswerNote)}
              >
                {showAnswerNote ? 'إغلاق' : addNoteText}
              </Button>
            </Grid>
            <Grid style={{ marginTop: '4%' }} item md={4}>
              <Button
                className={classes.uploadButton}
                variant="contained"
                component="label"
              >
                {uploadImageLabel}
                <input
                  name="photos"
                  type="file"
                  id="files"
                  onChange={event => validateImagesSizes(event, setAnswerImage)}
                  hidden
                />
              </Button>
              <Typography variant="h6">
                {answerImage ? 'تم التحميل' : 'الرجاء تحميل صورة (اختياري)'}
              </Typography>
            </Grid>
            {showAnswerNote &&
              <Grid xs={12} item>
                <Box className={classes.noteBox}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography style={styles.ansewrLabel}>نص الملاحظة (اختياري)</Typography>
                      <TextField
                        variant="outlined"
                        label="اكتب نص الملاحظة هنا..."
                        multiline
                        style={styles.answersFields}
                        value={noteText}
                        InputProps={{ style: { fontFamily: 'tajawal' } }}
                        InputLabelProps={{
                          sx: {
                            fontFamily: 'tajawal'
                          }
                        }}
                        onChange={(e) => setNoteText(e.target.value)}
                      />
                    </Grid>
                    <Grid xs={12} item style={{ marginTop: '3%', marginBottom: '1%' }}>
                      <Button
                        className={classes.uploadButton}
                        variant="contained"
                        component="label"
                      >
                        تحميل الصورة
                        <input
                          name="photos"
                          type="file"
                          id="files"
                          onChange={event => validateImagesSizes(event, setNoteImage)}
                          hidden
                        />
                      </Button>
                      <Typography variant="h6">
                        {noteImage ? 'تم التحميل' : 'الرجاء اختيار صورة (اختياري)'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBlock: '1%' }}>
                      <Typography style={styles.ansewrLabel}>رابط فيديو اليوتيوب (اختياري)</Typography>
                      <TextField
                        variant="outlined"
                        placeholder="مثال: https://www.youtube.com/yourvideo"
                        multiline
                        style={styles.answersFields}
                        value={noteLink}
                        onChange={(e) => setNoteLink(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            }
          </Grid>
          <CustomModal
            visible={modalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setModalVisible(false)}
            onCancel={() => {
              setModalVisible(false);
              // if (resetPhoto) {
              //   setNoteImage(null)
              // };
            }}
          />
        </div>
      </Fade>
    </>
  );
};


const styles = {
  title: {
    fontSize: '1.3em',
    marginLeft: '0.5%'
  },
  icon: {
    color: appColor,
  },
  ansewrLabel: {
    fontSize: '1.2em',
    marginBottom: '2%',
    fontWeight: '500'
  },
  answersFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '10%'
  },
  answersFields: {
    width: '90%',
    backgroundColor: '#FFFFFF'
  },
  noteText: {
    fontSize: '1.1em',
    fontWeight: '500',
  },
  radioButton: {
    color: appColor,
    height: '50%',
    marginTop: '7%'
  },
  button: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'right',
    marginTop: isMobile ? '5%' : '2%',
    width: isMobile ? '30vw' : '10vw',
    marginBottom: '5%'
  }
};

const useStyles = makeStyles(theme => ({
  noteBox: {
    boxShadow: theme.shadows[3],
    padding: '2%',
    //marginBlock: '2%'
  },
  uploadButton: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  }
}))

export default Answer;