import React, { useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Button,
  Fade,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { TextField, inputLabelClasses } from '@mui/material'
import { Context as CodeContext } from '../context/CodeContext';
import { Context as StudentsContext } from '../context/StudentsContext';
import { VerifiedUser } from "@material-ui/icons";
import { appColor, windowHeight, windowWidth } from "../common";
import Lottie from 'react-lottie';
import animationData from '../images/sale.json';
import { isMobile } from "react-device-detect";
import CustomModal from "../components/CustomModal";

const CodeRequestScreen = () => {
  const [termCode, setTermCode] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');
  const [loading, setLoading] = useState(false);

  const { checkCodeAvailability } = useContext(CodeContext);
  const { state: { _id: studentId }, tryLocalSignin, updateStudentRegisteredTerms, registerInTerm } = useContext(StudentsContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };


  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    tryLocalSignin(navigate, '/code-request');
  }, [])

  const handleCheckCode = async () => {
    const wrongCodeMsg = 'الرمز خاطئ ، يرجى المحاولة مرة أخرى';
    if (termCode.length !== 10 && termCode.length !== 14) {
      setModalText(wrongCodeMsg);
      setModalVisible(true);
      return;
    };

    setLoading(true);
    const { codeId, codeTerms, codeSubject } = await checkCodeAvailability(termCode);

    if (codeId) {
      const { error } = await registerInTerm(studentId, codeTerms, codeId);
      if (error) {
        setLoading(false);
        setModalText(wrongCodeMsg);
        setModalVisible(true);
      } else {
        await updateStudentRegisteredTerms();
        setLoading(false);
        navigate('/terms', {
          state: { subjectId: codeSubject._id, subjectName: codeSubject.subjectName }
        });
      }
    } else {
      setLoading(false);
      setModalText(wrongCodeMsg);
      setModalVisible(true);
    }
  }

  return (
    <Box>
      <Container className={classes.container}>
        <Fade timeout={1000} in={true}>
          <div>
            <Box className={classes.box}>
              <Lottie
                options={defaultOptions}
                // height={100}
                // width={120}
                isClickToPauseDisabled
                style={{ objectFit: 'contain', height: 110, width: 150, }}
              />
              <Typography className={classes.offerTitle}>بنك اسئلة شامل كل صغيرة وكبيرة في المادة بس بـ </Typography>
              <Box style={{ display: 'flex', alignItems: 'center', border: '2px solid #E77813', width: isMobile ? '80%' : '20%', justifyContent: 'center', borderRadius: 5, backgroundColor: '#FFFFFF', marginBlock: '1%' }}>
                <span style={{ color: appColor, fontSize: '1.8em', marginLeft: '2%' }}>2.99 دينار</span>
              </Box>
              <Typography style={{ marginBottom: '1%' }} className={classes.offerTitle}>للحصول على الرمز يرجى الإتصال أو التواصل عن طريق الواتساب على الرقم التالي: <a target="_blank" className={classes.numberStyle} href='https://api.whatsapp.com/send?phone=962777912639'>انقر هنا</a></Typography>
              <VerifiedUser className={classes.accountIcon} />
              <Typography className={classes.codeTitle}>إذا كنت تمتلك الرمز أدخله الآن</Typography>
              <TextField
                style={styles.textField}
                value={termCode}
                onChange={e => setTermCode(e.target.value)}
                label="أدخل الرمز"
                variant="outlined"
                InputProps={{
                  style: { fontFamily: 'tajawal' },
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal',
                    textAlign: 'center',
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: appColor
                    }
                  },
                }}
                inputProps={{ style: { textAlign: 'center' } }}
                onKeyPress={ev => {
                  if (ev.key === 'Enter') {
                    handleCheckCode();
                    ev.preventDefault();
                  }
                }}
              />
              <Button
                variant="contained"
                className={classes.submitButton}
                onClick={handleCheckCode}
              >
                تحقق {loading && <CircularProgress size={20} style={{ color: '#FFFFFF', marginRight: '2%' }} />}
              </Button>
            </Box>
            <CustomModal
              title='تنبيه!'
              content={modalText}
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              showConfirmButton={false}
              arabicCloseText="إغلاق"
            />
          </div>
        </Fade>
      </Container>
    </Box>
  );
};

const styles = {
  textField: {
    marginTop: isMobile ? '3%' : '1.5%',
    width: isMobile ? '80%' : '45%',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    alignSelf: 'center'
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: windowHeight / 1.15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'rtl'
  },
  box: {
    display: 'flex',
    padding: '1%',
    paddingBlock: isMobile ? '10%' : '',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[3],
    width: windowWidth / 1.05,
    marginBlock: isMobile ? '5%' : '1%'
  },
  accountIcon: {
    color: appColor,
    fontSize: '4em',
    alignSelf: 'center',
    //marginTop: '5%'
  },
  offerTitle: {
    fontSize: isMobile ? '1.3em' : '1.3em',
    marginTop: isMobile ? '3%' : '0.5%',
    textAlign: 'center',
  },
  codeTitle: {
    fontSize: '1.5em',
    textAlign: 'center',
    marginTop: '1%'
  },
  notchedOutline: {
    border: '1px solid',
    borderColor: '#E77813 !important',
    borderRadius: 5,
  },
  submitButton: {
    width: isMobile ? '75%' : '30%',
    marginTop: isMobile ? '5%' : '2%',
    marginBottom: '1%',
    backgroundColor: appColor,
    alignSelf: 'center',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: appColor,
    }
  },
  sendAgain: {
    color: appColor,
    fontSize: '0.9em',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: appColor,
      backgroundColor: '#FFFFFF'
    }
  },
  signUpLink: {
    color: 'gray',
    textDecorationLine: 'none',
    '&:hover': {
      color: appColor
    }
  },
  numberStyle: {
    color: appColor,
    textDecorationLine: 'none',
    '&:hover': {
      color: appColor,
    }
  }
}));

export default CodeRequestScreen;