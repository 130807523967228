import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Box, makeStyles } from '@material-ui/core';
import { Outlet } from 'react-router-dom'
import { windowHeight } from '../common';

const Layout = ({ children }) => {

  const classes = useStyles();
  return (
    <div>
      <Navbar />
      <Box className={classes.container}>
        {children}<Outlet />
      </Box>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    minHeight: windowHeight / 1.52,
    display: 'flex',
    flexDirection: 'column'
  }
})

export default Layout;