import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { appColor, windowHeight, windowWidth } from '../common';
import Lottie from 'react-lottie';
import examsLottie from '../images/exams.json';
import noteBookLottie from '../images/notebook.json'
import adLottie from '../images/ad.json'
import { Context as UnitContext } from '../context/UnitContext';
import { Context as PopUpContext } from '../context/PopUpContext';
import { Context as StudentsContext } from '../context/StudentsContext';
import { isMobile } from 'react-device-detect';
import CustomModal from '../components/CustomModal';

const AWS_URL = 'https://qsbank-bucket.s3.eu-central-1.amazonaws.com/';

const UnitsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [exams, setExams] = useState(null);

  const [modalVisible, setModalVisible] = useState(true);

  const { state: { units }, fetchUnits, resetUnits } = useContext(UnitContext);
  const { state: { popUp }, fetchPopUp, resetPopUp } = useContext(PopUpContext);
  const { state: { token, _terms: registeredTerms }, tryLocalSignin } = useContext(StudentsContext);

  const isLandscape = window.matchMedia('(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)').matches;

  const examsOptions = {
    loop: true,
    autoplay: true,
    animationData: examsLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };

  const noteBookOptions = {
    loop: true,
    autoplay: true,
    animationData: noteBookLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };

  const adOptions = {
    loop: true,
    autoplay: true,
    animationData: adLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };


  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();

  let subjectId, termId, subjectName, termName, noteBook

  if (!location.state)
    navigate('/');
  else {
    subjectId = location.state.subjectId;
    termId = location.state.termId;
    subjectName = location.state.subjectName;
    termName = location.state.termName;
    noteBook = location.state.noteBook;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    async function fetchData() {
      await fetchUnits(termId);
      await fetchPopUp(termId);
      setLoading(false);
    }

    fetchData();
    tryLocalSignin(navigate, '/units', { termId, subjectName, termName });
    return () => {
      resetUnits();
      resetPopUp();
    }
  }, []);

  useEffect(() => {
    if (units.length) {
      setExams(units.filter((unit) => unit.name === 'اختبارات نهائية')[0]);
    }
  }, [units]);

  const landscapeStyles = {
    width: '100%'
  };

  return (
    <Box>
      <Container style={{ marginTop: '3%', direction: 'rtl' }}>
        {loading && <CircularProgress className={classes.loadingStyle} />}
        <Typography className={classes.title}>مادة {subjectName} - {termName}</Typography>
        <hr />
        {registeredTerms.includes(termId) ?
          <Grid container>
            {noteBook && <Grid item md={6} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Link to={AWS_URL + noteBook} target="_blank" download="Example">
                <Button
                  classes={{ label: classes.label }}
                  className={classes.noteBookButton}
                >
                  <Lottie
                    options={noteBookOptions}
                    height={250}
                    width={250}
                    isClickToPauseDisabled
                  />
                  <Typography className={classes.examsTitle}>حمّل دوسية الفصل</Typography>
                </Button>
              </Link>
            </Grid>}
          </Grid>
          : null}
        <Grid container>
          {units.map((unit) => {
            if (unit.name === 'اختبارات نهائية') {
              return null
            }
            return (
              <Fade in timeout={1000}>
                <Grid className={classes.gridContainer} item md={6} xs={12}>
                  <Button
                    onClick={() => navigate('/lessons', {
                      state: { subjectId, termId, unitId: unit._id, unitName: unit.name, termName, subjectName }
                    })}
                    className={classes.button}
                    style={isLandscape ? landscapeStyles : null}
                  >
                    <img
                      src={AWS_URL + unit.image}
                      alt="صورة الوحدة"
                      className={classes.unitImage}
                      style={isLandscape ? landscapeStyles : null}
                    />
                    {/* <Typography className={classes.unitTitle}>{unit.name}</Typography> */}
                  </Button>
                </Grid>
              </Fade>
            )
          })}
          {registeredTerms.includes(termId) ?
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              {exams &&
                <Button
                  onClick={() => navigate('/lessons', {
                    state: { subjectId, termId, unitId: exams._id, unitName: exams.name, termName, subjectName }
                  })}
                  classes={{ label: classes.label }}
                  className={classes.examsButton}
                >
                  <Lottie
                    options={examsOptions}
                    height={250}
                    width={250}
                    isClickToPauseDisabled
                  />
                  <Typography className={classes.examsTitle}>{exams.name}</Typography>
                </Button>
              }
            </Grid>
            : null}
        </Grid>
        {popUp.length ? <CustomModal
          visible={modalVisible && popUp.length}
          title={popUp[0].title}
          content={popUp[0].content}
          iconTitle={<Lottie
            options={adOptions}
            height={200}
            width={200}
            isClickToPauseDisabled
          />}
          showConfirmButton={false}
          englishCloseText="Close"
          arabicCloseText="إغلاق"
          onCloseBackDropClick={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          loading={loading}
        /> : null}

      </Container>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  loadingStyle: {
    alignSelf: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: appColor,
    zIndex: 333
  },
  title: {
    textAlign: 'center',
    fontSize: isMobile ? '1.4em' : '1.8em'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '5%'
  },
  unitImage: {
    objectFit: 'cover',
    height: '100%',
    borderRadius: 15,
    width: isMobile ? windowWidth / 1.2 : '100%',
  },
  label: {
    flexDirection: 'column'
  },
  button: {
    borderRadius: 15,
    marginBottom: '5%',
    width: '95%'
  },
  noteBookButton: {
    borderRadius: 15,
    marginBottom: '5%',
    boxShadow: theme.shadows[3],
    backgroundColor: '#FFFFFF',
  },
  examsButton: {
    borderRadius: 15,
    marginBottom: '5%',
    boxShadow: theme.shadows[3],
    backgroundColor: '#FFFFFF',
    width: isMobile ? '90%' : '45%'
  },
  unitTitle: {
    position: 'absolute',
    color: '#FFFFFF',
    fontSize: '2em',
    zIndex: 3
  },
  examsTitle: {
    fontSize: '1.8em',
    marginBottom: '2%',
  }
}));

export default UnitsScreen;