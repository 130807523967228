import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  makeStyles,
  Container,
  Button,
  Fade,
  Box,
  CircularProgress,
  Typography,
  Grid,
  MenuItem
} from '@material-ui/core';
import { Select, TextField } from '@mui/material'
import CustomModal from '../components/CustomModal';
import { Context as StudentsContext } from '../context/StudentsContext';
//import { Context as LanguageContext } from '../context/LanguageContext';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { appColor, windowHeight, windowWidth } from '../common';

const SignUpScreen = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [selectedGeneration, setSelectedGeneration] = useState('');
  const [selectedFoundBy, setSelectedFoundBy] = useState('');
  const [otherField, setOtherField] = useState('');
  const [modalText, setModalText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const navigate = useNavigate();


  const { checkStudentUserExistence } = useContext(StudentsContext);

  const handleSigningUp = async () => {
    if (!firstName || !lastName || !phoneNumber || !password || !selectedGeneration) {
      setModalText("جميع الحقول مطلوبة ، يرجى المحاولة مرة أخرى");
      setModalVisible(true);
      return;
    }
    const phoneNumberRegex = /^07\d{8}$/
    if (password.length < 6) {
      setModalText("يجب أن تحتوي كلمة االسر على 6 عناصر على الأقل");
      setModalVisible(true);
    } else if (!phoneNumberRegex.test(phoneNumber)) {
      setModalText("رقم الهاتف غير صحيح! يرجى المحاولة مرة أخرى");
      setModalVisible(true);
    } else {
      setLoading(true);
      const customerExists = await checkStudentUserExistence(phoneNumber);
      setLoading(false);

      if (!customerExists) {
        let foundBy = selectedFoundBy;
        if (foundBy === "أخرى")
          foundBy = otherField;

        navigate('/verification', { state: { type: 'signup', firstName, lastName, phoneNumber, password, generation: selectedGeneration, foundBy } });
      } else {
        setModalText("رقم الهاتف الذي أدخلته موجود! يرجى إدخال رقم هاتف آخر");
        setModalVisible(true);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Box>
      <Container className={classes.container}>
        <Fade timeout={1000} in={true}>
          <div>
            <Box className={classes.box}>
              <Typography className={classes.siginInText}>تسجيل جديد</Typography>
              <hr />
              <Grid container>
                <Grid className={classes.gridStyle} item md={6} xs={12}>
                  <Typography className={classes.label}>الإسم الأول</Typography>
                  <TextField
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    style={styles.textField}
                    id="firstName"
                    placeholder='اكتب اسمك الأول...'
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      style: { fontFamily: 'tajawal' },
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'tajawal'
                      }
                    }}
                  />
                </Grid>
                <Grid className={classes.gridStyle} item md={6} xs={12}>
                  <Typography className={classes.label}>العائلة</Typography>
                  <TextField
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    style={styles.textField}
                    id="lastName"
                    placeholder='اكتب اسم العائلة...'
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      style: { fontFamily: 'tajawal' },
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'tajawal'
                      }
                    }}
                  />
                </Grid>
                <Grid className={classes.gridStyle} item md={6} xs={12}>
                  <Typography className={classes.label}>رقم الهاتف</Typography>
                  <TextField
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    style={styles.textField}
                    id="phonenumber"
                    type="number"
                    placeholder='رقم الهاتف'
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      style: { fontFamily: 'tajawal' },
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'tajawal'
                      }
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.label}>كلمة المرور</Typography>
                  <TextField
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    style={styles.textField}
                    id="password"
                    placeholder='كلمة المرور'
                    variant="outlined"
                    color="primary"
                    type="password"
                    InputProps={{
                      style: { fontFamily: 'tajawal' },
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'tajawal'
                      }
                    }}
                    onKeyPress={ev => {
                      if (ev.key === 'Enter') {
                        handleSigningUp();
                        ev.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid className={classes.gridStyle} item md={6} xs={12}>
                  <Typography className={classes.label}>انت أي جيل؟</Typography>
                  <Select
                    placeholder="اختر المادة"
                    variant="outlined"
                    className={classes.select}
                    value={selectedGeneration}
                    sx={{
                      fontFamily: 'tajawal',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: appColor
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: appColor,
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: appColor,
                      },
                    }}
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    onChange={(e) => setSelectedGeneration(e.target.value)}
                  >
                    {["2007", "2008"].map(generation => (
                      <MenuItem value={generation}>
                        {generation}
                      </MenuItem>
                    )
                    )}
                  </Select>
                </Grid>
                <Grid className={classes.gridStyle} item md={6} xs={12}>
                  <Typography className={classes.label}>كيف تعرّفت على المنصة؟</Typography>
                  <Select
                    placeholder="اختر المادة"
                    variant="outlined"
                    className={classes.select}
                    value={selectedFoundBy}
                    sx={{
                      fontFamily: 'tajawal',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: appColor
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: appColor,
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: appColor,
                      },
                    }}
                    MenuProps={{
                      disableScrollLock: true
                    }}
                    onChange={(e) => setSelectedFoundBy(e.target.value)}
                  >
                    {["انستغرام", "فيسبوك", "صديق", "أخرى"].map(foundBy => (
                      <MenuItem value={foundBy}>
                        {foundBy}
                      </MenuItem>
                    )
                    )}
                  </Select>
                </Grid>
                {selectedFoundBy === 'أخرى' &&
                  <Grid className={classes.gridStyle} item md={6} xs={12}>
                    <Typography className={classes.label}>أخبرنا كيف تعرفت على المنصة؟</Typography>
                    <TextField
                      value={otherField}
                      onChange={e => setOtherField(e.target.value)}
                      style={styles.textField}
                      id="otherfield"
                      placeholder='اكتب هنا...'
                      variant="outlined"
                      color="primary"
                      InputProps={{
                        style: { fontFamily: 'tajawal' },
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      InputLabelProps={{
                        sx: {
                          fontFamily: 'tajawal'
                        }
                      }}
                    />
                  </Grid>
                }
              </Grid>
              <Button
                variant="contained"
                className={isMobileOnly ? classes.mobileButton : classes.button}
                onClick={handleSigningUp}
              >
                تسجيل جديد {loading && <CircularProgress size={20} style={{ color: '#FFFFFF', marginRight: '2%' }} />}
              </Button>
            </Box>
          </div>
        </Fade>
        <CustomModal
          title='تنبيه!'
          content={modalText}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          showConfirmButton={false}
          loading={loading}
          englishCloseText="Close"
          arabicCloseText="إغلاق"
        />
      </Container>
    </Box>
  );
};

const styles = {
  textField: {
    width: isMobile ? '100%' : '95%',
    marginTop: '2%',
    backgroundColor: '#FFFFFF',
    marginBottom: isMobile ? '5%' : ''
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    //height: windowHeight / 1.15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    direction: 'rtl',
  },
  box: {
    display: 'flex',
    padding: isMobile ? '5%' : '3%',
    marginBlock: isMobile ? '5%' : '3%',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[3],
    //height: windowHeight / 1.45,
    //width: windowWidth / 1.05,
    backgroundColor: '#FFFFFF'
  },
  siginInText: {
    fontSize: '1.5em',
    color: appColor,
    fontWeight: 'bold',
    //marginBlock: '1%'
  },
  gridStyle: {
    marginBottom: '3%'
  },
  label: {
    fontSize: '1.1em',
    fontWeight: 'bold'
  },
  textField: {
    width: '95%',
    marginTop: '2%',
    backgroundColor: '#FFFFFF'
  },
  mobileTextField: {
    width: '80%',
    marginTop: '5%'
  },
  select: {
    marginTop: '2%',
    width: isMobile ? '100%' : '95%',
    backgroundColor: '#FFFFFF'
  },
  notchedOutline: {
    border: '1px solid',
    borderColor: '#E77813 !important',
    borderRadius: 5
  },
  button: {
    width: '30%',
    marginTop: '3%',
    backgroundColor: appColor,
    alignSelf: 'center',
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  mobileButton: {
    width: '50%',
    marginTop: '5%',
    alignSelf: 'center',
    backgroundColor: appColor,
    color: 'white',
    '&:hover': {
      backgroundColor: appColor,
      color: 'white'
    }
  },
  noAccountText: {
    fontSize: '1.1em',
    marginTop: '1%',
    textAlign: 'center'
  },
  signUpLink: {
    textDecorationLine: 'none',
    color: appColor,
    '&:hover': {
      color: appColor
    }
  }
}));

export default SignUpScreen;