import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Fade,
  Grid,
  MenuItem,
  // Select,
  Typography
} from "@material-ui/core";
import { TextField, Select } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { Context as AdminContext } from '../context/AdminContext';
import { Context as SubjectContext } from '../context/SubjectContext';
import { Context as TermContext } from '../context/TermContext';
import { Context as PopUpContext } from '../context/PopUpContext';
import { Context as MainPopupContext } from '../context/MainPopupContext';
import CustomModal from "../components/CustomModal";
import { Message } from "@material-ui/icons";
import { appColor } from "../common";

const AWS_URL = 'https://qsbank-bucket.s3.eu-central-1.amazonaws.com/';

const PopUp = () => {
  const [popupPhoto, setPopupPhoto] = useState(false);
  const [resetPopupPhoto, setResetPopupPhoto] = useState(false);

  const [selectedTermGrade, setSelectedTermGrade] = useState('');
  const [selectedTermSubject, setSelectedTermSubject] = useState('');
  const [selectedTerm, setSelectedTerm] = useState('');
  const [popUpTitle, setPopUpTitle] = useState('');
  const [popUpContent, setPopUpContent] = useState('');

  const [modalVisible, setModalVisible] = useState(false);
  const [mainPopupModalVisible, setMainPopupModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteMainPopupModalVisible, setDeleteMainPopupModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModaContent] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [selectedPopUp, setSelectedPopUp] = useState('')
  const [loading, setLoading] = useState(false);

  const { state: { subjects }, fetchSubjects } = useContext(SubjectContext);
  const { state: { terms }, fetchTerms } = useContext(TermContext);
  const { state: { popUp }, addPopUp, fetchPopUp, deletePopUp } = useContext(PopUpContext);
  const { state: { popUp: mainPopup }, addPopUp: addMainPopup, fetchPopUp: fetchMainPopup, deletePopUp: deleteMainPopup } = useContext(MainPopupContext);
  const { state: { adminToken }, tryAdminLocalSignin } = useContext(AdminContext);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSubjectsData() {
      await fetchSubjects();
    }

    async function fetchPopUpData() {
      await fetchPopUp();
    }

    async function fetchMainPopUpData() {
      await fetchMainPopup();
    }

    tryAdminLocalSignin(navigate, '/pop-up');
    if (!subjects.length) fetchSubjectsData();
    if (!popUp.length) fetchPopUpData();
    if (!mainPopup.length) fetchMainPopUpData();
  }, []);

  const handlePopUpSubmit = async () => {
    setLoading(true)
    const res = await addPopUp(selectedTerm, popUpTitle, popUpContent);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setModalVisible(true);
      setModalTitle('تمت الإضافة بنجاح');
      setModaContent(`تمت إضافة الوحدة بنجاح`);
      setShowConfirmButton(false);
      navigate(0);
    }
    setLoading(false);
  }

  const handleDeletePopUp = async () => {
    setLoading(true)
    const res = await deletePopUp(selectedPopUp);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setDeleteModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setDeleteModalVisible(true);
      setModalTitle('تم الحذف بنجاح');
      setModaContent(`تم حذف الإعلان بنجاح`);
      setShowConfirmButton(false);
      navigate(0);
    }
    setLoading(false);
  };

  const handleMainPopupSubmit = async () => {
    setLoading(true)
    const res = await addMainPopup(popupPhoto);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setMainPopupModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setMainPopupModalVisible(true);
      setModalTitle('تمت الإضافة بنجاح');
      setModaContent(`تمت إضافة الفصل بنجاح`);
      setShowConfirmButton(false);
      navigate(0)
    }
    setLoading(false);
  };

  const handleDeleteMainPopup = async () => {
    setLoading(true)
    const res = await deleteMainPopup(mainPopup[0]._id);

    if (res.error) {
      setModalTitle("تنبيه!");
      setModaContent('حدث خطأ ما! يرجى المحاولة مرة أخرى');
      setDeleteMainPopupModalVisible(true);
      setShowConfirmButton(false);
    } else {
      setDeleteMainPopupModalVisible(true);
      setModalTitle('تم الحذف بنجاح');
      setModaContent(`تم حذف الإعلان بنجاح`);
      setShowConfirmButton(false);
      navigate(0);
    }
    setLoading(false);
  };

  const validateSubjectImageSize = async event => {
    event.preventDefault();

    Object.values(event.target.files).forEach(imgFile => {
      if (imgFile.size > 1048576) {
        setModalTitle("تنبيه!")
        setModaContent('يجب أن يكون حجم الصورة أقل من 1 ميجا');
        setModalVisible(true);
        setShowConfirmButton(false);
        setResetPopupPhoto(true);
        return;
      }
    });

    setPopupPhoto(event.target.files[0]);
  };

  if (!adminToken) {
    return null;
  }

  return (
    <Container style={{ direction: 'rtl' }}>
      <Fade timeout={1000} in={true}>
        <div>
          <Box style={styles.titleContainer}>
            <Message style={styles.icon} />
            <Typography style={styles.title}>إضافة إعلان على الصفحة الرئيسية</Typography>
          </Box>
          <hr />
          <Grid container>
            <Grid style={styles.gridStyle} item md={4} xs={12}>
              <Button
                //className={isMobile ? classes.uploadButton : ''}
                variant="contained"
                component="label"
                color="primary"
              >
                تحميل الصورة
                <input
                  name="photos"
                  type="file"
                  id="files"
                  onChange={event => validateSubjectImageSize(event)}
                  hidden
                />
              </Button>
              <Typography variant="h6">
                {popupPhoto ? 'تم التحميل' : 'الرجاء تحميل الصورة'}
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={() => {
                  if (!popupPhoto) {
                    return;
                  }
                  setMainPopupModalVisible(true);
                  setModalTitle("تنبيه!");
                  setModaContent(`هل أنت متأكد من إضافة هذا الإعلان ؟`);
                  setShowConfirmButton(true);
                }}
              >
                أرسل
              </Button>
            </Grid>
          </Grid>
          <Box style={styles.titleContainer}>
            <Message style={styles.icon} />
            <Typography style={styles.title}>إعلان الصفحة الرئيسية الموجود</Typography>
          </Box>
          <hr />
          {!mainPopup.length ?
            <Typography style={styles.noPopUpText}>لا يوجد إعلانات مضافة</Typography>
            :
            mainPopup.map((item, index) => {
              return (
                <Box style={{ marginBottom: '2%' }}>
                  <Box>
                    <Button
                      variant="contained"
                      style={styles.button}
                      onClick={() => {
                        setDeleteMainPopupModalVisible(true);
                        setModalTitle("تنبيه!");
                        setModaContent(`هل أنت متأكد من حذف هذا الإعلان ؟`);
                        setShowConfirmButton(true);
                        //setSelectedPopUp(item._id);
                      }}
                    >
                      حذف الإعلان
                    </Button>
                  </Box>
                  <img src={AWS_URL + mainPopup[0].image} style={styles.mainPopupImage} />
                </Box>
              )
            })}
          <Box style={styles.titleContainer}>
            <Message style={styles.icon} />
            <Typography style={styles.title}>إضافة إعلان للفصل</Typography>
          </Box>
          <hr />
          <Grid container>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الجيل</Typography>
              <Select
                placeholder="اختر الجيل"
                variant="outlined"
                style={styles.select}
                value={selectedTermGrade}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedTermGrade(e.target.value);
                }
                }
              >
                {[11, 12].map((grade) => (
                  <MenuItem value={grade}>
                    {grade}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر المادة</Typography>
              <Select
                placeholder="اختر المادة"
                variant="outlined"
                style={styles.select}
                value={selectedTermSubject}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => {
                  setSelectedTermSubject(e.target.value);
                  fetchTerms(e.target.value)
                }
                }
              >
                {subjects.map((subject) => {
                  if (selectedTermGrade !== subject.grade) return;
                  return (
                    <MenuItem value={subject._id}>
                      {subject.subjectName}
                    </MenuItem>
                  )
                }
                )}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography style={styles.label}>اختر الفصل</Typography>
              <Select
                placeholder="اختر الفصل"
                variant="outlined"
                style={styles.select}
                value={selectedTerm}
                sx={{
                  fontFamily: 'tajawal'
                }}
                MenuProps={{
                  disableScrollLock: true
                }}
                onChange={(e) => setSelectedTerm(e.target.value)}
              >
                {terms.map((term) => (
                  <MenuItem value={term._id}>
                    {term.name}
                  </MenuItem>
                )
                )}
              </Select>
            </Grid>
            <Grid style={{ marginTop: '2%' }} item xs={12}>
              <Typography style={styles.label}>عنوان الإعلان</Typography>
              <TextField
                variant="outlined"
                placeholder="عنوان الإعلان"
                style={{ ...styles.textField, width: '50%' }}
                value={popUpTitle}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                onChange={(e) => setPopUpTitle(e.target.value)}
              />
            </Grid>
            <Grid style={{ marginTop: '2%' }} item xs={12}>
              <Typography style={styles.label}>نص الإعلان</Typography>
              <TextField
                variant="outlined"
                placeholder="نص الإعلان"
                style={styles.textField}
                multiline
                value={popUpContent}
                InputProps={{ style: { fontFamily: 'tajawal' } }}
                InputLabelProps={{
                  sx: {
                    fontFamily: 'tajawal'
                  }
                }}
                onChange={(e) => setPopUpContent(e.target.value)}
              />
            </Grid>
            <Grid style={{ marginTop: '2%' }} item xs={12}>
              <Button
                variant="contained"
                style={styles.button}
                onClick={() => {
                  if (!selectedTermSubject || !selectedTerm || !popUpTitle || !popUpContent) {
                    return;
                  }
                  setModalVisible(true);
                  setModalTitle("تنبيه!");
                  setModaContent(`هل أنت متأكد من إضافة هذا الإعلان ؟`);
                  setShowConfirmButton(true);
                }}
              >
                أرسل
              </Button>
            </Grid>
          </Grid>
          <Box style={styles.titleContainer}>
            <Message style={styles.icon} />
            <Typography style={styles.title}>إعلانات الفصول الموجودة</Typography>
          </Box>
          <hr />
          {!popUp.length ?
            <Typography style={styles.noPopUpText}>لا يوجد إعلانات مضافة</Typography>
            :
            popUp.map((item, index) => {
              const isLast = popUp.length - 1 === index;
              return (
                <Box style={{ marginBottom: '2%' }}>
                  <Box>
                    <Typography style={styles.popUpSubject}>إعلان لمادة: {item._term._subject.subjectName} - {item._term.name}</Typography>
                    <Button
                      variant="contained"
                      style={styles.button}
                      onClick={() => {
                        setDeleteModalVisible(true);
                        setModalTitle("تنبيه!");
                        setModaContent(`هل أنت متأكد من حذف هذا الإعلان ؟`);
                        setShowConfirmButton(true);
                        setSelectedPopUp(item._id);
                      }}
                    >
                      حذف الإعلان
                    </Button>
                  </Box>
                  <Typography style={styles.popUpTitle}>عنوان الإعلان: {item.title}</Typography>
                  <Typography style={styles.popUpContent}>نص الإعلان: {item.content}</Typography>
                  {!isLast && <hr />}
                </Box>
              )
            })}
          <CustomModal
            visible={modalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setModalVisible(false)}
            onCancel={() => setModalVisible(false)}
            onConfirm={handlePopUpSubmit}
            loading={loading}
          />
          <CustomModal
            visible={mainPopupModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setMainPopupModalVisible(false)}
            onCancel={() => {
              setMainPopupModalVisible(false);
              if (resetPopupPhoto) setPopupPhoto(null)
            }}
            onConfirm={handleMainPopupSubmit}
            loading={loading}
          />
          <CustomModal
            visible={deleteMainPopupModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setDeleteMainPopupModalVisible(false)}
            onCancel={() => setDeleteMainPopupModalVisible(false)}
            onConfirm={handleDeleteMainPopup}
            loading={loading}
          />
          <CustomModal
            visible={deleteModalVisible}
            title={modalTitle}
            content={modalContent}
            showConfirmButton={showConfirmButton}
            englishConfirmText="Yes"
            arabicConfirmText="نعم"
            englishCloseText="Close"
            arabicCloseText="إغلاق"
            onCloseBackDropClick={() => setDeleteModalVisible(false)}
            onCancel={() => setDeleteModalVisible(false)}
            onConfirm={handleDeletePopUp}
            loading={loading}
          />
        </div>
      </Fade>
    </Container>
  );
};

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2%'
  },
  title: {
    fontSize: '1.3em',
    marginRight: '0.5%'
  },
  icon: {
    color: appColor,
  },
  mainPopupImage: {
    objectFit: 'contain',
    width: '20%',
    borderRadius: 10
  },
  label: {
    fontSize: '1.2em',
    marginBottom: '1%',
    fontWeight: '500'
  },
  textField: {
    //marginTop: '2%',
    width: isMobile ? '70%' : '60%',
    backgroundColor: '#FFFFFF'
  },
  select: {
    marginTop: '1%',
    width: '70%',
    backgroundColor: '#FFFFFF'
  },
  button: {
    backgroundColor: appColor,
    color: '#FFFFFF',
    float: 'left',
    marginTop: isMobile ? '5%' : '1%',
    width: isMobile ? '30vw' : '10vw'
  },
  noPopUpText: {
    fontSize: '1.3em',
    textAlign: 'center'
  },
  popUpSubject: {
    fontSize: '1.3em',
    marginBottom: '1%'
  },
  popUpTitle: {
    marginBottom: '2%',
    fontSize: '1.1em'
  },
  popUpContent: {
    fontSize: '1.1em'
  }
};

export default PopUp;