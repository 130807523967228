import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { windowHeight, windowWidth } from '../common';
import Lottie from 'react-lottie';
import animationData from '../images/error.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  },
};

const ErrorScreen = () => {
  return (
    <Box style={styles.container}>
      <img src={require('../images/logo_with_text.png')} alt="logo" style={styles.logo} />
      <Lottie
        options={defaultOptions}
        height={300}
        width={300}
        isClickToPauseDisabled
      />
      <Typography style={styles.text}>الموقع قيد الصيانة ، سيعود الموقع للعمل في أقرب وقت...</Typography>
    </Box>
  );
};

const styles = {
  container: {
    height: windowHeight,
    width: windowWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2%',
  },
  logo: {
    height: 200,
    width: 200,
    objectFit: 'contain'
  },
  text: {
    fontSize: '1.3em',
    marginTop: '2%',
    textAlign: 'center'
  }
}

export default ErrorScreen;